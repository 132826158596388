import React from 'react';
import './App.css';
import about from './components/about';
import contact from './components/contact';
import policy from './components/policy';
import support from './components/support';
import chart from './components/chart';
import faq from './components/faq';
import login from './components/login';
import register from './components/register';
import forget from './components/forget';
import otp from './components/otp';
import dashboard from './components/dashboard';
import Changepassword from './components/change-password';
import Chat from './components/chat'; 
import ChatDetails from './components/chat-details'; 
import Streetak_Landing from './components/Streetak_landing'; 
import {isLoggedIn} from './utils';
import { ToastContainer, toast, Slide } from 'react-toastify'

import { Route, Switch,Redirect, Link, BrowserRouter, HashRouter } from 'react-router-dom'
import ForgetPassword from './components/forgotPassword';

import StreetTalk from './components/street-talk'; 
import StreetTalkProfile from './components/street-talk-profile'; 
import StreetTalkDetails from './components/street-talk-details'; 
import StreetTalkPublic from './components/street-talk-public';
import TermsCondition from './components/termsCondition';
import CommingSoon from './components/commingSoon';
import StreetTalkSharePost from './components/streetTalk-share-post';
import Privacy from './components/privacy-policy';
import FollowFollowing from './components/followFollowing';
import Notification from './components/notification'
import StreettakLandingPage from './components/streettak_landing_page';
import AboutNew from './components/aboutNew';


toast.configure()

const checkAuth = (userType) => {
  return isLoggedIn(userType);
}

const PublicRoute = ({ component: Component, userType, ...rest }) => (
  <Route {...rest} render={props => {
      return checkAuth("userData") ? <Redirect to={{ pathname: `/dashboard` }} /> :
          <Component {...props} />
  }} />
)

const ProtectedRoute = ({ component: Component, userType, ...rest }) => (
  <Route {...rest} render={props => {
      return (checkAuth(userType)) ?
              <Component {...props} />
          :
          <React.Fragment>
              <Redirect to={{ pathname: `/` }} />
          </React.Fragment>
  }}
  />
)
function App() {
return (
<React.Fragment>
  <ToastContainer transition={Slide} />
  <HashRouter>  
    <Switch>  
      {/* <Route exact path="/" userType = 'userData' component={Streetak_Landing} />  */}
      <Route exact path="/" userType = 'userData' component={StreettakLandingPage} /> 
      <Route exact path="/comming-soon" userType = 'userData' component={CommingSoon} />
      <Route exact path="/about" userType = 'userData' component={AboutNew} />
      {/* <Route exact path="/about" userType = 'userData' component={about} /> */}
      <Route exact path="/terms-and-condition" userType = 'userData' component={TermsCondition} /> 
      <Route exact path="/contact" userType = 'userData' component={contact} /> 
      <Route exact path="/policy" userType = 'userData' component={policy} />  
      <Route exact path="/privacy-policy" userType = 'userData' component={Privacy} /> 
      <Route exact path="/support" userType = 'userData' component={support} /> 
      <Route exact path="/size-chart" userType = 'userData' component={chart} /> 
      <Route exact path="/" userType = 'userData' component={Streetak_Landing} />   
      <ProtectedRoute exact path="/chat" userType = 'userData' component={Chat} />   
      <ProtectedRoute exact path="/chat-details" userType = 'userData' component={ChatDetails} />
      <Route exact path="/faq" userType = 'userData' component={faq} /> 
      <PublicRoute exact path="/login" userType = 'userData' component={login} /> 
      <PublicRoute exact path="/register" userType = 'userData' component={register} /> 
      <PublicRoute exact path="/forget" userType = 'userData' component={forget} /> 
      <PublicRoute exact path="/otp" userType = 'userData' component={otp} /> 
      <PublicRoute exact path="/reset-password" userType = 'userData' component={ForgetPassword} />
      <ProtectedRoute exact path="/dashboard" userType = 'userData' component={dashboard} /> 
      <ProtectedRoute exact path="/change-password" userType = 'userData' component={Changepassword} /> 
      <PublicRoute exact path="/street-talk-public" userType = 'userData' component={StreetTalkPublic} /> 
      <ProtectedRoute exact path="/following-followeres" userType = 'userData' component={FollowFollowing} />  
      <ProtectedRoute exact path="/street-talk" userType = 'userData' component={StreetTalk} />  
      <ProtectedRoute exact path="/street-talk-profile" userType = 'userData' component={StreetTalkProfile} /> 
      <ProtectedRoute exact path="/street-talk-details/:id" userType = 'userData' component={StreetTalkDetails} />       
      <ProtectedRoute exact path="/notification" userType = 'userData' component={Notification} /> 
      <Route exact path="/:id" userType = 'userData' component={StreetTalkSharePost} /> 

    </Switch>     
  </HashRouter>
</React.Fragment>
);
}

export default App;
