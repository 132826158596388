import { userProfileAction } from '../actionTypes'

const initialState = {
  profileData: {},
  loader: false,
  updateLoader: false
}

export default function userProfile(state = initialState, {type, payload}) {
  switch(type) {
    case userProfileAction.GET_USER_PROFILE_INITIATE: {
      return {
        ...state, loader: true, profileData: {}
      }
    }
    case userProfileAction.GET_USER_PROFILE_SUCCESS: {
      delete payload.Data.password
      delete payload.Data.otpData
    window.localStorage.setItem("userData", JSON.stringify(payload.Data))
    window.localStorage.setItem("Followerscount", JSON.stringify(payload.Followerscount))
    window.localStorage.setItem("Followingcount", JSON.stringify(payload.Followingcount))
      return {
        ...state, loader: false, profileData: payload.Data
      }
    }
    case userProfileAction.GET_USER_PROFILE_FAILURE: {
      return {
        ...state, loader: false
      }
    }
    case userProfileAction.UPDATE_USER_PROFILE_INITIATE: {
      return {
        ...state, updateLoader: true
      }
    }
    case userProfileAction.UPDATE_USER_PROFILE_SUCCESS: {
      delete payload.Data.password
      delete payload.Data.otpData
    window.localStorage.setItem("userData", JSON.stringify(payload.Data))
    window.localStorage.setItem("Followerscount", JSON.stringify(payload.Followerscount))
    window.localStorage.setItem("Followingcount", JSON.stringify(payload.Followingcount))
      return {
        ...state, updateLoader: false, profileData: payload.Data
      }
    }
    case userProfileAction.UPDATE_USER_PROFILE_FAILURE: {
      return {
        ...state, updateLoader: false
      }
    }
    case userProfileAction.EMPTY_USER_DATA: {
      return {
        ...state, 
        profileData: {},
        loader: false,
        updateLoader: false
      }
    }
    default: 
      return state
  }
}