import { streetTalkAction } from '../actionTypes'
import {Url} from '../../config/config'
import axios from 'axios'
import {isLoggedIn, multiPartData} from '../../utils'

const token = isLoggedIn('userData')

//-------------create-post-----------------
export const createPostnitiate = () => ({
  type: streetTalkAction.CREATE_POST_INITIATE,
})

export const createPostSuccess = (data) => ({
  type: streetTalkAction.CREATE_POST__SUCCESS,
  payload: data
})

export const createPostFailure = () => ({
  type: streetTalkAction.CREATE_POST__FAILURE
})

export function createPost(payload) {
  const token = isLoggedIn('userData')
  const formData = new FormData()
  Object.keys(payload).map(item => {
    let value = payload[item]
    if (item == "images") {
      for (let i = 0; i < value.length; i++) {
        formData.append('images', value[i]);
      }
    } else if (item == "video") {
      for (let i = 0; i < value.length; i++) {
        formData.append('video', value[i]);
      }
    }
     else {
        formData.append(item, value)
    }
})
  return dispatch => {
     dispatch(createPostnitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/post`, formData,{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(createPostSuccess(data))
                  }
                  else{
                    dispatch(createPostFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(createPostFailure())
                  reject(err);
              })
      );
  }
}
//--------------MULTIPLE-POST-----------------------------------
export const multiplePostInitiate = () => ({
  type: streetTalkAction.MULTIPLE_POST_INITIATE,
})

export const multiplePostSuccess = (data) => ({
  type: streetTalkAction.MULTIPLE_POST_SUCCESS,
  payload: data
})

export const multiplePostFailure = () => ({
  type: streetTalkAction.MULTIPLE_POST_FAILURE
})

export function multiplePost(payload) {
  const token = isLoggedIn('userData')
//   const formData = new FormData()
//   Object.keys(payload).map(item => {
//     let value = payload[item]
//     if (item == "images") {
//       for (let i = 0; i < value.length; i++) {
//         formData.append('images', value[i]);
//       }
//     } else if (item == "video") {
//       for (let i = 0; i < value.length; i++) {
//         formData.append('video', value[i]);
//       }
//     }
//      else {
//         formData.append(item, value)
//     }
// })
  return dispatch => {
     dispatch(multiplePostInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/multiplePost`, payload,{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(multiplePostSuccess(data))
                  }
                  else{
                    dispatch(multiplePostFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(multiplePostFailure())
                  reject(err);
              })
      );
  }
}
//---------------uploade-multiple images video -----------------------
export const multiplePostImageVideoInitiate = () => ({
  type: streetTalkAction.UPLOAD_MULTIPLE_IMAGES_INITIATE,
})

export const multiplePostImageVideoSuccess = (data) => ({
  type: streetTalkAction.UPLOAD_MULTIPLE_IMAGES_SUCCESS,
  payload: data
})

export const multiplePostImageVideoFailure = () => ({
  type: streetTalkAction.UPLOAD_MULTIPLE_IMAGES_FAILURE
})

export function multiplePostImageVideo(payload) {
  const token = isLoggedIn('userData')
  const formData = new FormData()
  Object.keys(payload).map(item => {
    let value = payload[item]
    if (item == "images") {
      for (let i = 0; i < value.length; i++) {
        formData.append('images', value[i]);
      }
    } else if (item == "video") {
      for (let i = 0; i < value.length; i++) {
        formData.append('video', value[i]);
      }
    }
     else {
        formData.append(item, value)
    }
})
  return dispatch => {
     dispatch(multiplePostImageVideoInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/multiplePostImageVideo`, formData,{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(multiplePostImageVideoSuccess(data))
                  }
                  else{
                    dispatch(multiplePostImageVideoFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(multiplePostImageVideoFailure())
                  reject(err);
              })
      );
  }
}
//---------------only-user-post----------------------------
export const userPostnitiate = () => ({
  type: streetTalkAction.USER_POST_INITIATE,
})

export const userPostSuccess = (data) => ({
  type: streetTalkAction.USER_POST__SUCCESS,
  payload: data
})

export const userPostFailure = () => ({
  type: streetTalkAction.USER_POST__FAILURE
})

export function userPost(payload) {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(userPostnitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/userPosts`,"",{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(userPostSuccess(data))
                  }
                  else{
                    dispatch(userPostFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(userPostFailure())
                  reject(err);
              })
      );
  }
}
//-------------------comment-on-post--------------------
export const createCommentInitiate = () => ({
  type: streetTalkAction.CREATE_COMMENT_INITIATE,
})

export const createCommentSuccess = (data) => ({
  type: streetTalkAction.CREATE_COMMENT__SUCCESS,
  payload:data
})

export const createCommentFailure = () => ({
  type: streetTalkAction.CREATE_COMMENT__SUCCESS
})


export function createComment(payload) {
  const updatedData = multiPartData(payload)
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(createCommentInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/createComment`, updatedData,{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(createCommentSuccess(data))
                  }
                  else{
                    dispatch(createCommentFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(createCommentFailure())
                  reject(err);
              })
      );
  }
}

//------------------to-see-commet-on-post-------------
export const postCommentInitiate = () => ({
  type: streetTalkAction.POST_COMMENT_INITIATE,
})

export const postCommentSuccess = (data) => ({
  type: streetTalkAction.POST_COMMENT__SUCCESS,
  payload:data
})

export const postCommentFailure = () => ({
  type: streetTalkAction.POST_COMMENT__FAILURE
})


export function postComment(payload) {
  // const updatedData = multiPartData(payload)
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(postCommentInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/postComments`, payload,{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(postCommentSuccess(data))
                  }
                  else{
                    dispatch(postCommentFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(postCommentFailure())
                  reject(err);
              })
      );
  }
}

//--------------------to-like-blast-unlike-post----------
export const likePostnitiate = () => ({
  type: streetTalkAction.LIKE_POST_INITIATE,
})

export const likePostSuccess = (data) => ({
  type: streetTalkAction.LIKE_POST__SUCCESS,
  payload: data
})

export const likePostFailure = () => ({
  type: streetTalkAction.LIKE_POST__FAILURE
})

export function likePost(payload) {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(likePostnitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/like`, payload,{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(likePostSuccess(data))
                  }
                  else{
                    dispatch(likePostFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(likePostFailure())
                  reject(err);
              })
      );
  }
}

//-------------------TO-DELETE-USER-POST-------
export const deletePostnitiate = () => ({
  type: streetTalkAction.DELETE_POST_INITIATE,
})

export const deletePostSuccess = (data) => ({
  type: streetTalkAction.DELETE_POST__SUCCESS,
  payload: data
})

export const deletePostFailure = () => ({
  type: streetTalkAction.DELETE_POST__FAILURE
})

export function deletePost(payload) {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(deletePostnitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/deletePost`, payload,{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(deletePostSuccess(data))
                  }
                  else{
                    dispatch(deletePostFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(deletePostFailure())
                  reject(err);
              })
      );
  }
}

//------------------delete-user-comment-------------------
export const deleteCommentnitiate = () => ({
  type: streetTalkAction.DELETE_COMMENT_INITIATE,
})

export const deleteCommentSuccess = (data) => ({
  type: streetTalkAction.DELETE_COMMENT_SUCCESS,
  payload: data
})

export const deleteCommentFailure = () => ({
  type: streetTalkAction.DELETE_COMMENT_FAILURE
})

export function deleteComment(payload) {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(deleteCommentnitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/deleteComment`, payload,{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(deleteCommentSuccess(data))
                  }
                  else{
                    dispatch(deleteCommentFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(deleteCommentFailure())
                  reject(err);
              })
      );
  }
}

//----------------PARTICULAR-POST--------------------------------
export const particularPostnitiate = (flag) => ({
  type: streetTalkAction.PARTICULAR_POST_INITIATE,
  payloa: flag
})

export const particularPostSuccess = (data) => ({
  type: streetTalkAction.PARTICULAR_POST_SUCCESS,
  payload: data
})

export const particularPostFailure = () => ({
  type: streetTalkAction.PARTICULAR_POST_FAILURE
})

export function particularPost(payload, flag) {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(particularPostnitiate(flag))
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/particularPost`, payload,{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(particularPostSuccess(data))
                  }
                  else{
                    dispatch(particularPostFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(particularPostFailure())
                  reject(err);
              })
      );
  }
}
//----------------share-post----------------------------------
export const particularPostWithoutAuthnitiate = () => ({
  type: streetTalkAction.SHARE_POST_INITIATE,
})

export const particularPostWithoutAuthSuccess = (data) => ({
  type: streetTalkAction.SHARE_POST_SUCCESS,
  payload: data
})

export const particularPostWithoutAuthFailure = () => ({
  type: streetTalkAction.SHARE_POST_FAILURE
})

export function particularPostWithoutAuth(payload) {
  // const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(particularPostWithoutAuthnitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/particularPostWithoutAuth`, payload,{
            // headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(particularPostWithoutAuthSuccess(data))
                  }
                  else{
                    dispatch(particularPostWithoutAuthFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(particularPostWithoutAuthFailure())
                  reject(err);
              })
      );
  }
}

//-----------------streetTalk-post---------------------------------------
export const streetTalkPostnitiate = (flag) => ({
  type: streetTalkAction.STREETTALK_POST_INITIATE,
  payload:flag
})

export const streetTalkPostSuccess = (data) => ({
  type: streetTalkAction.STREETTALK_POST_SUCCESS,
  payload: data
})

export const streetTalkPostFailure = () => ({
  type: streetTalkAction.STREETTALK_POST_FAILURE
})

export function streetTalkPost(flag,payload) {
  // const token = isLoggedIn('userData')
  const userData = JSON.parse(window.localStorage.getItem('userData'))
  const id = userData && userData._id ? {userID:userData._id, pageNumber: payload.pageNumber} : {pageNumber: payload.pageNumber}
  return dispatch => {
     dispatch(streetTalkPostnitiate(flag))
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/streetTalk`,id )
              .then(response => {
                const data = response.data
                
                  if ((data.response_code && data.response_code == 200) && payload.pageNumber === 1){
                      dispatch(streetTalkPostSuccess(data.Data))
                  }
                  if(data.response_code && data.response_code !== 200){
                    dispatch(streetTalkPostFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(streetTalkPostFailure())
                  reject(err);
              })
      );
  }
}

//---------------------------vendorTalk-post-------------------------
export const vendorTalkPostnitiate = (flag) => ({
  type: streetTalkAction.VENDOR_POST_INITIATE,
  payload:flag
})

export const vendorTalkPostSuccess = (data) => ({
  type: streetTalkAction.VENDOR_POST_SUCCESS,
  payload: data
})

export const vendorTalkPostFailure = () => ({
  type: streetTalkAction.VENDOR_POST_FAILURE
})

export function vendorTalkPost(flag,payload) {
  // const token = isLoggedIn('userData')
  const userData = JSON.parse(window.localStorage.getItem('userData'))
  const id = userData && userData._id ? {userID:userData._id, pageNumber: payload.pageNumber} : {pageNumber: payload.pageNumber}
  return dispatch => {
     dispatch(vendorTalkPostnitiate(flag))
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/venderTalk`, id)
              .then(response => {
                const data = response.data
                  if ((data.response_code && data.response_code == 200) && payload.pageNumber === 1){
                      dispatch(vendorTalkPostSuccess(data.Data))
                  }
                  else{
                    dispatch(vendorTalkPostFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(vendorTalkPostFailure())
                  reject(err);
              })
      );
  }
}

//-----------------------other-user-post------------------------------
export const otherUserPostInitiate = (flag) => ({
  type: streetTalkAction.OTHER_USER_POST_INITIATE,
  payload: flag
})

export const otherUserPostSuccess = (data) => ({
  type: streetTalkAction.OTHER_USER_POST_SUCCESS,
  payload: data
})

export const otherUserPostFailure = () => ({
  type: streetTalkAction.OTHER_USER_POST_FAILURE
})

export function otherUserPost(payload, flag) {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(otherUserPostInitiate(flag))
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/otherUserProfile`, payload,{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(otherUserPostSuccess(data))
                  }
                  else{
                    dispatch(otherUserPostFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(otherUserPostFailure())
                  reject(err);
              })
      );
  }
}

//----------------blast-users-----------------------------------
export const blastUsersInitiate = () => ({
  type: streetTalkAction.BLAST_USER_INITIATE,
})

export const blastUsersSuccess = (data) => ({
  type: streetTalkAction.BLAST_USER_SUCCESS,
  payload: data
})

export const blastUsersFailure = () => ({
  type: streetTalkAction.BLAST_USER_FAILURE
})

export function blastUsers(payload) {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(blastUsersInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/blastUsers`, payload,{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(blastUsersSuccess(data))
                  }
                  else{
                    dispatch(blastUsersFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(blastUsersFailure())
                  reject(err);
              })
      );
  }
}

//----------------------------LIKED-USERS---------------------
export const likedUsersInitiate = () => ({
  type: streetTalkAction.LIKED_USER_INITIATE,
})

export const likedUsersSuccess = (data) => ({
  type: streetTalkAction.LIKED_USER_SUCCESS,
  payload: data
})

export const likedUsersFailure = () => ({
  type: streetTalkAction.LIKED_USER_FAILURE
})

export function likedUsers(payload) {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(likedUsersInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/likedUsers`, payload,{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(likedUsersSuccess(data))
                  }
                  else{
                    dispatch(likedUsersFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(likedUsersFailure())
                  reject(err);
              })
      );
  }
}
//-------------------unliked-users----------------------------------
export const unlikedUsersInitiate = () => ({
  type: streetTalkAction.UNLIKED_USER_INITIATE,
})

export const unlikedUsersSuccess = (data) => ({
  type: streetTalkAction.UNLIKED_USER_SUCCESS,
  payload: data
})

export const unlikedUsersFailure = () => ({
  type: streetTalkAction.UNLIKED_USER_FAILURE
})

export function unlikedUsers(payload) {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(unlikedUsersInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/unlikedUsers`, payload,{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(unlikedUsersSuccess(data))
                  }
                  else{
                    dispatch(unlikedUsersFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(unlikedUsersFailure())
                  reject(err);
              })
      );
  }
}
//----------------vendor-check-------------------------------------
export const vendorCheckInitiate = () => ({
  type: streetTalkAction.VENDOR_CHECK_INITIATE,
})

export const vendorCheckSuccess = (data) => ({
  type: streetTalkAction.VENDOR_CHECK_SUCCESS,
  payload: data
})

export const vendorCheckFailure = () => ({
  type: streetTalkAction.VENDOR_CHECK_FAILURE
})

export function vendorCheck() {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(vendorCheckInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/venderCheck`, '',{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(vendorCheckSuccess(data))
                  }
                  else{
                    dispatch(vendorCheckFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(vendorCheckFailure())
                  reject(err);
              })
      );
  }
}
//--------------------update-post---------------------------------------
export const updatePostnitiate = () => ({
  type: streetTalkAction.UPDATE_POST_INITIATE,
})

export const updatePostSuccess = (data) => ({
  type: streetTalkAction.UPDATE_POST_SUCCESS,
  payload: data
})

export const updatePostFailure = () => ({
  type: streetTalkAction.UPDATE_POST_FAILURE
})

export function updatePost(payload) {
  const token = isLoggedIn('userData')
  // const updatedData = multiPartData(payload)
  const formData = new FormData()
  Object.keys(payload).map(item => {
    let value = payload[item]
    if (item == "images") {
      for (let i = 0; i < value.length; i++) {
        formData.append('images', value[i]);
      }
    } else if (item == "postImage") {
      for (let i = 0; i < value.length; i++) {
        formData.append('postImage', value[i]);
      }
    } else if(item == "oldImage"){
      for (let i = 0; i < value.length; i++) {
        formData.append('oldImage', value[i]);
      }
    }
    else if(item == "oldVideo"){
      for (let i = 0; i < value.length; i++) {
        formData.append('oldVideo', value[i]);
      }
    }
     else if (item == "video") {
      for (let i = 0; i < value.length; i++) {
        formData.append('video', value[i]);
      }
    }
     else {
        formData.append(item, value)
    }
})
  return dispatch => {
     dispatch(updatePostnitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/updatePost`, formData,{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(updatePostSuccess(data))
                  }
                  else{
                    dispatch(updatePostFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(updatePostFailure())
                  reject(err);
              })
      );
  }
}
//-----------------------UPDATE-COMMENT----------------------------
export const updateCommentInitiate = () => ({
  type: streetTalkAction.UPDATE_COMMENT_INITIATE,
})

export const updateCommentSuccess = (data) => ({
  type: streetTalkAction.UPDATE_COMMENT_SUCCESS,
  payload:data
})

export const updateCommentFailure = () => ({
  type: streetTalkAction.UPDATE_COMMENT_FAILURE
})


export function particularUpdateComment(payload) {
  const updatedData = multiPartData(payload)
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(updateCommentInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/updateComment`, updatedData,{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(updateCommentSuccess(data))
                  }
                  else{
                    dispatch(updateCommentFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(updateCommentFailure())
                  reject(err);
              })
      );
  }
}
//--------------REPORT-POST----------------------------------
export const postReportInitiate = () => ({
  type: streetTalkAction.POST_REPORT_INITIATE,
})

export const postReportSuccess = (data) => ({
  type: streetTalkAction.POST_REPORT_SUCCESS,
  payload:data
})

export const postReportFailure = () => ({
  type: streetTalkAction.POST_REPORT_FAILURE
})


export function postReport(payload) {
  // const updatedData = multiPartData(payload)
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(postReportInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/report`, payload,{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(postReportSuccess(data))
                  }
                  else{
                    dispatch(postReportFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(postReportFailure())
                  reject(err);
              })
      );
  }
}

//-----------empty-user-like-blast-unlike-data---------------------------

export const emptyUserLikeData = () => ({
  type: streetTalkAction.EMPTY_USER_LIKED_DATA
})

//------------people-may-know----------------------------------------
export const peopleMayKnowInitiate = () => ({
  type: streetTalkAction.PEOPLE_MAY_KNOW_INITIATE,
})

export const peopleMayKnowSuccess = (data) => ({
  type: streetTalkAction.PEOPLE_MAY_KNOW_SUCCESS,
  payload:data
})

export const peopleMayKnowFailure = () => ({
  type: streetTalkAction.PEOPLE_MAY_KNOW_FAILURE
})


export function peopleMayKnow(payload) {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(peopleMayKnowInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/peopleMayKnow`, '',{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(peopleMayKnowSuccess(data))
                  }
                  else{
                    dispatch(peopleMayKnowFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(peopleMayKnowFailure())
                  reject(err);
              })
      );
  }
}

//------------major-news------------------------------------------------------
export const majorNewsInitiate = () => ({
  type: streetTalkAction.MAJOR_NEWS_INITIATE,
})

export const majorNewsSuccess = (data) => ({
  type: streetTalkAction.MAJOR_NEWS_SUCCESS,
  payload:data
})

export const majorNewsFailure = () => ({
  type: streetTalkAction.MAJOR_NEWS_FAILURE
})


export function majorNews(payload) {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(majorNewsInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/majorNews`, '',{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(majorNewsSuccess(data))
                  }
                  else{
                    dispatch(majorNewsFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(majorNewsFailure())
                  reject(err);
              })
      );
  }
}

//--------------------------heaader-image-cover-photo-------------------
export const updateCoverIamgeInitiate = () => ({
  type: streetTalkAction.UPDATE_COVER_PHOTO_INITIATE,
})

export const updateCoverIamgeSuccess = (data) => ({
  type: streetTalkAction.UPDATE_COVER_PHOTO_SUCCESS,
  payload:data
})

export const updateCoverIamgeFailure = () => ({
  type: streetTalkAction.UPDATE_COVER_PHOTO_FAILURE
})


export function updateCoverIamge(payload) {
  const updatedData = multiPartData(payload)
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(updateCoverIamgeInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/updateHeaderImage`, updatedData,{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(updateCoverIamgeSuccess(data))
                  }
                  else{
                    dispatch(updateCoverIamgeFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(updateCoverIamgeFailure())
                  reject(err);
              })
      );
  }
}
//------------------shared-blast-post------------------------------------
export const sharedBlastPostInitiate = () => ({
  type: streetTalkAction.UPDATE_COVER_PHOTO_INITIATE,
})

export const sharedBlastPostSuccess = (data) => ({
  type: streetTalkAction.UPDATE_COVER_PHOTO_SUCCESS,
  payload:data
})

export const sharedBlastPostFailure = () => ({
  type: streetTalkAction.UPDATE_COVER_PHOTO_FAILURE
})


export function sharedBlastPost(payload) {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(sharedBlastPostInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/sharedBlastPost`, payload,{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(sharedBlastPostSuccess(data))
                  }
                  else{
                    dispatch(sharedBlastPostFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(sharedBlastPostFailure())
                  reject(err);
              })
      );
  }
}
//------------upload-image-video------------------------------------
export const uploadImageVideoInitiate = () => ({
  type: streetTalkAction.UPLOAD_IMAGE_VIDEO_INITIATE,
})

export const uploadImageVideoSuccess = (data) => ({
  type: streetTalkAction.UPLOAD_IMAGE_VIDEO_SUCCESS,
  payload:data
})

export const uploadImageVideoFailure = () => ({
  type: streetTalkAction.UPLOAD_IMAGE_VIDEO_FAILURE
})


export function uploadImageVideo(payload) {
  const updatedData = multiPartData(payload)
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(uploadImageVideoInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/uploadImageVideo`, updatedData,{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(uploadImageVideoSuccess(data))
                  }
                  else{
                    dispatch(uploadImageVideoFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(uploadImageVideoFailure())
                  reject(err);
              })
      );
  }
}
//-------------streettak-news--------------------------------
export const streetTakNewsInitiate = () => ({
  type: streetTalkAction.STREETTAK_NEWS_INITIATE,
})

export const streetTakNewsSuccess = (data) => ({
  type: streetTalkAction.STREETTAK_NEWS_SUCCESS,
  payload:data
})

export const streetTakNewsFailure = () => ({
  type: streetTalkAction.STREETTAK_NEWS_FAILURE
})


export function streetTakNews(payload) {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(streetTakNewsInitiate())
      return new Promise((resolve, reject) =>
          axios.get(`${Url}gbmall/user/news`,{
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(streetTakNewsSuccess(data))
                  }
                  else{
                    dispatch(streetTakNewsFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(streetTakNewsFailure())
                  reject(err);
              })
      );
  }
}
//---------------------MULTIPLE-COMMENT----------------------
export const multipleCommentInitiate = () => ({
  type: streetTalkAction.MULTIPLE_COMMENTS_INITIATE,
})

export const multipleCommentSuccess = (data) => ({
  type: streetTalkAction.MULTIPLE_COMMENTS_SUCCESS,
  payload:data
})

export const multipleCommentFailure = () => ({
  type: streetTalkAction.MULTIPLE_COMMENTS_FAILURE
})


export function multipleComment(payload) {
  const updatedData = multiPartData(payload)
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(multipleCommentInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/multipleComment`, payload,{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(multipleCommentSuccess(data))
                  }
                  else{
                    dispatch(multipleCommentFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(multipleCommentFailure())
                  reject(err);
              })
      );
  }
}
//----------COMMENT-LIKE---------------------------
export const commentLikenitiate = () => ({
  type: streetTalkAction.COMMENT_LIKE_INITIATE,
})

export const commentLikeSuccess = (data) => ({
  type: streetTalkAction.COMMENT_LIKE_SUCCESS,
  payload: data
})

export const commentLikeFailure = () => ({
  type: streetTalkAction.COMMENT_LIKE_FAILURE
})

export function commentLike(payload) {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(commentLikenitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/commentLikes`, payload,{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(commentLikeSuccess(data))
                  }
                  else{
                    dispatch(commentLikeFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(commentLikeFailure())
                  reject(err);
              })
      );
  }
}

//-------all----following---------------------
export const allfollowingnitiate = () => ({
  type: streetTalkAction.ALL_FOLLOWING_INITIATE,
})

export const allfollowingSuccess = (data) => ({
  type: streetTalkAction.ALL_FOLLOWING_SUCCESS,
  payload: data
})

export const allfollowingFailure = () => ({
  type: streetTalkAction.ALL_FOLLOWING_FAILURE
})

export function allfollowing(payload) {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(allfollowingnitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/allfollowing`,'',{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(allfollowingSuccess(data))
                  }
                  else{
                    dispatch(allfollowingFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(allfollowingFailure())
                  reject(err);
              })
      );
  }
}
//--------------------all--followers------------------
export const allfollowersInitiate = () => ({
  type: streetTalkAction.ALL_FOLLOWERES_INITIATE,
})

export const allfollowersSuccess = (data) => ({
  type: streetTalkAction.ALL_FOLLOWERES_SUCCESS,
  payload: data
})

export const allfollowersFailure = () => ({
  type: streetTalkAction.ALL_FOLLOWERES_FAILURE
})

export function allfollowers(payload) {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(allfollowersInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/allfollowers`, '',{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(allfollowersSuccess(data))
                  }
                  else{
                    dispatch(allfollowersFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(allfollowersFailure())
                  reject(err);
              })
      );
  }
}
//-------------notification-list------------------
export const notificationListInitiate = () => ({
  type: streetTalkAction.NOTIFICATION_LIST_INITIATE,
})

export const notificationListSuccess = (data) => ({
  type: streetTalkAction.NOTIFICATION_LIST_SUCCESS,
  payload: data
})

export const notificationListFailure = () => ({
  type: streetTalkAction.NOTIFICATION_LIST_FAILURE
})

export function notificationList(payload) {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(notificationListInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/notificationList`, '',{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(notificationListSuccess(data))
                  }
                  else{
                    dispatch(notificationListFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(notificationListFailure())
                  reject(err);
              })
      );
  }
}
//------------notification-count--------------------------
export const notificationCountInitiate = () => ({
  type: streetTalkAction.NOTIFICATION_COUNT_INITIATE,
})

export const notificationCountSuccess = (data) => ({
  type: streetTalkAction.NOTIFICATION_COUNT_SUCCESS,
  payload: data
})

export const notificationCountFailure = () => ({
  type: streetTalkAction.NOTIFICATION_COUNT_FAILURE
})

export function notificationCount(payload) {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(notificationCountInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/notificationCount`, '',{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(notificationCountSuccess(data))
                  }
                  else{
                    dispatch(notificationCountFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(notificationCountFailure())
                  reject(err);
              })
      );
  }
}
//-------------------notification---seeting---------------------
export const notificationSettingInitiate = () => ({
  type: streetTalkAction.NOTIFICATION_SETTING_INITIATE,
})

export const notificationSettingSuccess = (data) => ({
  type: streetTalkAction.NOTIFICATION_SETTING_SUCCESS,
  payload: data
})

export const notificationSettingFailure = () => ({
  type: streetTalkAction.NOTIFICATION_SETTING_FAILURE
})

export function notificationSetting(payload) {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(notificationSettingInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/notificationSetting`, '',{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(notificationSettingSuccess(data))
                  }
                  else{
                    dispatch(notificationSettingFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(notificationSettingFailure())
                  reject(err);
              })
      );
  }
}
//-------------------------notification-status---------------
export const notificationStatusInitiate = () => ({
  type: streetTalkAction.NOTIFICATION_STATUS_INITIATE,
})

export const notificationStatusSuccess = (data) => ({
  type: streetTalkAction.NOTIFICATION_STATUS_SUCCESS,
  payload: data
})

export const notificationStatusFailure = () => ({
  type: streetTalkAction.NOTIFICATION_STATUS_FAILURE
})

export function notificationStatus(payload) {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(notificationStatusInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/notificationStatus`, '',{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(notificationStatusSuccess(data))
                  }
                  else{
                    dispatch(notificationStatusFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(notificationStatusFailure())
                  reject(err);
              })
      );
  }
}
//------------------REMOVE-FOLLOWERES----------------------------
export const follwersRemoveRequestInitiate = () => ({
  type: streetTalkAction.REMOVE_FOLLOWERES_INITIATE,
})

export const follwersRemoveRequestSuccess = (data) => ({
  type: streetTalkAction.REMOVE_FOLLOWERES_SUCCESS,
  payload: data
})

export const follwersRemoveRequestFailure = () => ({
  type: streetTalkAction.REMOVE_FOLLOWERES_FAILURE
})

export function follwersRemoveRequest(payload) {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(follwersRemoveRequestInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/follwersRemoveRequest`,payload,{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(follwersRemoveRequestSuccess(data))
                  }
                  else{
                    dispatch(follwersRemoveRequestFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(follwersRemoveRequestFailure())
                  reject(err);
              })
      );
  }
}
//------------------unseenNotification-------------------------
export const unSeenNotificationInitiate = () => ({
  type: streetTalkAction.UNSEEN_NOTIFICATION_INITIATE,
})

export const unSeenNotificationSuccess = (data) => ({
  type: streetTalkAction.UNSEEN_NOTIFICATION_SUCCESS,
  payload: data
})

export const unSeenNotificationFailure = () => ({
  type: streetTalkAction.UNSEEN_NOTIFICATION_FAILURE
})

export function unSeenNotification(payload) {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(unSeenNotificationInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/unSeenNotification`,'',{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(unSeenNotificationSuccess(data))
                  }
                  else{
                    dispatch(unSeenNotificationFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(unSeenNotificationFailure())
                  reject(err);
              })
      );
  }
}
//-------------------READ-ALL-NOTIFICATION----------------------------------
export const readAllNotificationInitiate = () => ({
  type: streetTalkAction.READ_ALL_NOTIFICATION_INITIATE,
})

export const readAllNotificationSuccess = (data) => ({
  type: streetTalkAction.READ_ALL_NOTIFICATION_SUCCESS,
  payload: data
})

export const readAllNotificationFailure = () => ({
  type: streetTalkAction.READ_ALL_NOTIFICATION_FAILURE
})

export function readAllNotification(payload) {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(readAllNotificationInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/readAllNotification`,'',{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(readAllNotificationSuccess(data))
                  }
                  else{
                    dispatch(readAllNotificationFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(readAllNotificationFailure())
                  reject(err);
              })
      );
  }
}

//---------------empty-----------emptyImageVideo-----------------
export const emptyImageVideo = () => ({
  type: streetTalkAction.EMPTY_IMAGE_VIDEO_URL,
})