import React from 'react'
import '../App.css'; 
import { Link } from "react-router-dom";  

import Header from './header';       
import Footer from './footer';
import Sidenav from './sidenav'; 

import product1 from "../assets/media/Product-1.jpg";
import product2 from "../assets/media/Product-2.jpg";
import product3 from "../assets/media/Product-3.jpg";
import product4 from "../assets/media/Product-4.jpg";
 

class Chat  extends React.Component {
  	render() { 
	    return <div> 
		   
		   <Header/> 
				 
		    	<div className="DashboardArea"> 
		    		<div className="container">
		    			<div className="row">
		    				<div className="col-sm-3">
		    					<Sidenav/> 
		    				</div>
 
		    				<div className="col-sm-9"> 
		    					<div className="DashboardRight"> 
									<h1 className="Title">Chat</h1>

						    		<div className="MessagesArea">
					                    <div className="MessagesList">
					                        <ul>
					                            <li>
					                                <Link to="/chat-details">
					                                    <figure><img src={product1} /></figure>
					                                    <span className="Count">05</span>
					                                    <h3>Prashant Sharma</h3>
					                                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p>
					                                </Link>
					                            </li>
					                            <li>
					                                <Link to="/chat-details">
					                                    <figure><img src={product2} /></figure>
					                                    <span className="Count">05</span>
					                                    <h3>Prashant Sharma </h3>
					                                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p>
					                                </Link>
					                            </li>
					                        </ul>
					                    </div>
					                </div>
		    					</div> 
		    				</div> 
		    			</div> 
		    		</div>
		       	</div> 

			<Footer/>
		</div>
  	}
}
export default Chat 