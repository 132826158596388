import { loginAction } from '../actionTypes'
import {Url} from '../../config/config'
import axios from 'axios'
import {isLoggedIn} from '../../utils'

const token = isLoggedIn('userData')


export const userLoginInitiate = () => ({
  type: loginAction.USER_LOGIN_INITIATE
})

export const userLoginSuccess = (data) => ({
  type: loginAction.USER_LOGIN_SUCCESS,
  payload: data
})

export const userLoginFailure = (data) => ({
  type: loginAction.USER_LOGIN_FAILURE
})

export function loginUser(payload) {
  return dispatch => {
     dispatch(userLoginInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/login`, payload)
              .then(response => {
                console.log(response)
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(userLoginSuccess(data))
                  }
                  else{
                    dispatch(userLoginFailure(data))
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(userLoginFailure(err))
                  reject(err);
              })
      );
  }
}

export const userLogoutInitiate = (data) => ({
  type: loginAction.USER_LOGOUT_INITIATE
})

export const userLogoutSuccess = (data) => ({
  type: loginAction.USER_LOGOUT_SUCCESS
})

export const userLogoutFailure = (data) => ({
  type: loginAction.USER_LOGOUT_FAILURE
})


export function logoutUser() {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(userLogoutInitiate())
     const token = isLoggedIn('userData')
      return new Promise((resolve, reject) =>
            axios.post(`${Url}gbmall/user/logout`, '',{
              headers: { token: `${token}` }
            })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(userLogoutSuccess(data))
                  }
                  else{
                    dispatch(userLogoutFailure(data))
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(userLogoutFailure(err))
                  reject(err);
              })
      );
  }
}