import React, { useState, Fragment, useEffect, useRef,useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../App.css";
import _, { remove } from 'lodash'
import { Link, useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
// import Carousel from 'react-bootstrap/Carousel';
import Dropdown from "react-bootstrap/Dropdown"
import { Player, BigPlayButton } from 'video-react';
import InputEmoji from "react-input-emoji";
import Picker from 'emoji-picker-react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import InfiniteScroll from "react-infinite-scroll-component";
import logo from "../assets/media/gbLogo.png";
import moment from 'moment'
import Icon1 from "../assets/media/Icon-1.svg";
import uparrow from "../assets/media/uparrow.png";
import Icon2 from "../assets/media/Icon-2.svg";
import Icon4 from "../assets/media/Icon-4.svg";
import Icon5 from "../assets/media/Icon-5.svg";
import Icon6 from "../assets/media/Icon-6.svg";
import Icon10 from "../assets/media/sidenav-10.png";
import Blast from "../assets/media/blast.svg"
import Like from "../assets/media/like.svg"
import Dislike from "../assets/media/dislike.svg"
import Cancel from "../assets/media/cancel.png"
import addNew from "../assets/media/add_new.png"
import { toast } from 'react-toastify';
import LightboxCustom from './lightBox'
import Pagination from "react-js-pagination";
import Preview from  'preview-url-component'
import streetTalkLogo from "../assets/media/streettalk.svg"
import { Carousel } from 'react-responsive-carousel';

import Header from "./header";
import Footer from "./footer";
import CustomLoader from './customLoader';
import { getAllBuddies, sendRequestBuddies, searchAllBuddies, deletePendingBuddies,emptyBuddies } from '../redux/actions/buddiesAction'
import { getUserProfile, updateUserProfile, emptyUserData } from '../redux/actions/userProfileAction'
import {
  createPost,
  streetTalkPost,
  vendorTalkPost,
  createComment,
  postComment,
  likePost,
  deletePost,
  deleteComment,
  particularPost,
  otherUserPost,
  blastUsers,
  likedUsers,
  unlikedUsers,
  emptyUserLikeData,
  vendorCheck,
  particularUpdateComment,
  updatePost,
  postReport,
  majorNews,
  peopleMayKnow,
  streetTalkPostSuccess,
  vendorTalkPostSuccess,
  sharedBlastPost,
  uploadImageVideo,
  multipleComment,
  emptyImageVideo,
  multiplePostImageVideo,
  multiplePost,
  multiplePostImageVideoSuccess,
  streetTakNews,
  commentLike,
  allfollowing,
  notificationCount,
  unSeenNotification,
  readAllNotification
} from '../redux/actions/streetTalkAction'
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
  FacebookIcon,
  TelegramIcon,
  LinkedinIcon,
} from "react-share";
import {onMessageListener} from '../firebase'

const initialState = {
  userId: '',
  caption: '',
  images: [],
  imageReview: [],
  oldImage: [],
  video: [],
  videoReview: [],
  oldVideo: [],
  comment: '',
  selectedId: '',
  userType: '',
  updateData: {},
  type: 'new',
  commentId: '',
  updateComment: '',
  postId: '',
  reported_topic: '',
  remark: '',
  reportPostId: '',
  particularPostId: '',
  isOpen: false,
  lightBoxImg: [],
  createPostModal: false,
  imageViewModal: false,
  showPost: 1,
  isHovering: false,
  hoverData: {},
  matchId: '',
  pageNumber: 1,
  newCaption: '',
  modalType: '',
  imageHash: Date.now(),
  isHoveringModal: false,
  timeOut: null,
  modalImageData: {},
  unfollowModal: false,
  followUserId: '',
  chooseUserModal: false,
  toshow: [],
  commentModal: false,
  commentIndex: 0,
  multipleComents: [],
  emojiFlag: false,
  position:'inherit',
  top:0,
  scrollFlag:false,
  multiplePosts:[],
  menuPosition:'inherit',
  menuTop:0,
  menuFlag:false,
  deleteModal:false,
  deleteID:'',
  deleteType:'',
  shareModal: false,
  shareID:'',
  queries:'',
}
const StreetTalk = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  let scrollRef = useRef(0)
  let menuScolleRef = useRef(0)
  const [iState, updateState] = useState(initialState)
  const userData = useSelector(state => state.userProfile)
  const postdata = useSelector(state => state.streetTalk)
  const [show, setShow] = useState(false);
  const [key, setKey] = useState("home");
  const [userTypeShow, updateUserTypeShow] = useState(false)
  const [showReport, setShowReport] = useState(false);
  const [doneReport, setDoneReport] = useState(false);
  const [postCmt, updatePostCmt] = useState('')
  const [hasNextPage, setHasNextPage] = useState(true)
  const [cmtImages, updateCmtImages] = useState({ commentImages: '', imageFile: '' })
  const [cmtVideo, updateCmtVideo] = useState({ commentVideo: '', videoFile: '' })
  const userDataId = JSON.parse(window.localStorage.getItem("userData"))
  const followersCount = JSON.parse(window.localStorage.getItem("Followerscount"))
  const followingCount = JSON.parse(window.localStorage.getItem("Followingcount"))
  const allBuddiesList = useSelector(state => state.buddies)
  const { allBuddiesData,searchBuddies } = allBuddiesList
  const {
    commentImages,
    imageFile
  } = cmtImages
  const {
    commentVideo,
    videoFile
  } = cmtVideo


  const {
    userId,
    caption,
    images,
    imageReview,
    oldImage,
    videoReview,
    video,
    oldVideo,
    comment,
    selectedId,
    userType,
    updateData,
    type,
    commentId,
    updateComment,
    postId,
    reported_topic,
    remark,
    reportPostId,
    particularPostId,
    isOpen,
    lightBoxImg,
    createPostModal,
    showPost,
    isHovering,
    hoverData,
    matchId,
    pageNumber,
    newCaption,
    modalType,
    isHoveringModal,
    timeOut,
    imageViewModal,
    modalImageData,
    unfollowModal,
    followUserId,
    chooseUserModal,
    toshow,
    commentModal,
    commentIndex,
    multipleComents,
    emojiFlag,
    position,
    top,
    scrollFlag,
    multiplePosts,
    menuPosition,
    menuTop,
    menuFlag,
    deleteModal,
    deleteID,
    deleteType,
    shareModal,
    shareID,
    queries, 
  } = iState
  const {
    profileData,
    loader
  } = userData

  const {
    streetTalkPostData,
    streetTalkLoader,
    vendorTalkPostData,
    vendorTalkLoader,
    postCommentData,
    postCommentLoader,
    blastLoader,
    blastUserData,
    likedLoader,
    likedUserData,
    unlikedLoader,
    unlikedUserData,
    postLoader,
    peopleMayKnowData,
    majorNewsData,
    updatePostLoader,
    imageVideoUrl,
    postImageVideoUrl,
    particularPostData,
    streetTakNewsData,
    notificationCountData,
    unseenNotification
  } = postdata

    useEffect(() => {
    dispatch(getUserProfile())
    dispatch(peopleMayKnow())
    dispatch(majorNews())
    dispatch(streetTakNews())
    dispatch(notificationCount())
    return (() => {
      dispatch(emptyUserLikeData())
    });
  }, [])

  useEffect(() => {
		const interval = setInterval(() => {
      dispatch(unSeenNotification())
			dispatch(notificationCount()) .then((data) => {
        if (data.response_code == 401) {
				localStorage.clear()
          history.push('/')
        }
      })
		}, 15000);
		return () => clearInterval(interval);
	}, []);

  const readNotify =()=> {
    dispatch(readAllNotification()).then((data) => {
      if (data.response_code == 200) {
         dispatch(unSeenNotification())
      }
    })
  }


  const handlePath = (path) => {
    history.push(path)
  }

  const handleChooseUserModal = () => {
    updateState({ ...iState, chooseUserModal: false})
  }
  const fetchMoreData = () => {
    let pageNumber = parseInt(streetTalkPostData.length / 10) + 1
    updateState({ ...iState, pageNumber })

    if (key === 'home' && pageNumber !== 1) {
      dispatch(streetTalkPost(false, { pageNumber: pageNumber }))
        .then((data) => {
          if (data.response_code && data.response_code == 200) {
            let copyData = data
            copyData = [...streetTalkPostData, ...copyData.Data]
            dispatch(streetTalkPostSuccess(copyData))
          }
          if (data.Data.length < 10) {
            setHasNextPage(false);
          } else {
            setHasNextPage(true)
          }
        });
    } else if (key === 'profile' && pageNumber !== 1) {
      dispatch(vendorTalkPost(false, { pageNumber: pageNumber }))
        .then((data) => {
          if (data.response_code && data.response_code == 200) {
            let copyData = data
            copyData = [...vendorTalkPostData, ...copyData.Data]
            dispatch(vendorTalkPostSuccess(copyData))
          }
          if (data.Data.length < 10) {
            setHasNextPage(false);
          } else {
            setHasNextPage(true)
          }
        });
    }
  }

  const hideCreatePostModal = () => {
    updateState({
      ...iState, createPostModal: false, type: 'new', caption: '',
      images: [],
      imageReview: [],
      oldImage: [],
      video: [],
      videoReview: [],
      oldVideo: [],
      imageFile: '',
      videoFile: '',
      postId: '',
      imageFile: '',
      videoFile: '',
      userId: '',
      newCaption: '',
      multiplePosts:[],
      showPost: 1,
      emojiFlag:false
    })
    dispatch(emptyImageVideo())
  }

  const hideimageViewModal = () => {
    updateState({
      ...iState, imageViewModal: false, modalImageData: {},particularPostId:''
    })
  }

  const openImageViewModal = (Data) => {
    dispatch(particularPost({ postId: Data._id }))
    dispatch(postComment({ postId: Data._id }))
    updateState({
      ...iState, imageViewModal: true, modalImageData: Data,particularPostId:Data._id
    })
  }

  const openCreatePostModal = () => {
    dispatch(emptyImageVideo())
    updateState({
      ...iState, createPostModal: true, particularPostId: '',multiplePosts:[], images:[],imageReview:[],video:[],videoReview:[]
    })
  }
  const lightBoxOpen = (img) => {
    updateState({
      ...iState, isOpen: true, lightBoxImg: img
    })
  }
  const openReportModal = (id) => {
    setShowReport(true)
    updateState({
      ...iState, reportPostId: id
    })
  }
  const closeReportPost = () => {
    setShowReport(false)
  }
  const hideDoneReport = () => {
    setDoneReport(false)
  }

  const handleClose = () => {
    setShow(false)
  };

  const updatePostId = (id) => {
    updateState({ ...iState, particularPostId: id })
  }

  const handleShareModal=()=>{
    updateState({...iState,shareModal:false,shareID:''})
  }
  const openShareModal=(id)=>{
    updateState({...iState,shareModal:true,shareID:id})
  }
  const handledeleteModal =(type)=> {
    if(type === 'yes' && deleteType === 'post'){
      deleteUserPost(deleteID)
    }
    if (type === 'yes' && deleteType === 'comment'){
    
    } else{
      updateState({...iState,deleteModal: false,deleteID:''})
    }
  }

  const openDeleteModal =(id, deleteType)=>{
    updateState({...iState,deleteModal: true, deleteID:id, deleteType:deleteType})
  }

  const hidecommentModal = () => {
    dispatch(emptyImageVideo())
    updateState({ ...iState, commentModal: false, comment: '', multipleComents: [], emojiFlag: false })
    updateCmtImages({
      ...cmtImages, commentImages: '', imageFile: ''
    })
    updateCmtVideo({
      ...cmtVideo, commentVideo: '', videoFile: ''
    })
  }

  const handleShow = () => setShow(true);

  const handleChecked = (e, id) => {
    if (!e.target.checked) {
      let updateToShow = [...toshow]
      var index = updateToShow.indexOf(id);

      if (index > -1) {
        updateToShow.splice(index, 1);
        updateState({ ...iState, toshow: updateToShow })
      }

    } else {
      updateState({ ...iState, toshow: [...iState.toshow, id] })
    }
  }
  const submitReportPost = () => {
    const data = { remark, reported_topic, postId: reportPostId }
    dispatch(postReport(data)).then(res => {
      if (res.response_code == 200) {
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        updateState({
          ...iState, reported_topic: "", remark: '', reportPostId: ''
        })
        closeReportPost()
        setDoneReport(true)
        // dispatch(streetTalkPost())
      } else if (res.response_code === 400) {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        updateState({
          ...iState, reported_topic: "", remark: '', reportPostId: ''
        })
        closeReportPost()
      }
    }).catch(err => {
      const message = err && err.response && err.response ? err.response.message : "Something went handleSuit"
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
    })
  }

  const editUpdatePost = (data, share) => {
    if (!_.isEmpty(data)) {
      const updateData = _.cloneDeep(iState)
      updateData.caption = data && data.caption ? data.caption : ""
      updateData.oldImage = data.images && [...data.images] || []
      updateData.oldVideo = data.video && data.video || []
      updateData.imageReview = data.images && [...data.images] || []
      updateData.videoReview = data.video && data.video || []
      updateData.userId = data.userId && data.userId || ''
      updateData.postId = data._id
      updateData.showPost = showPost
      if (share === 'share') {
        updateData.type = share
      } else {
        updateData.type = 'edit'
      }
      updateData.createPostModal = true
      updateState(
        updateData
      )
    }
  }

  const saveUpdatePost = () => {
    const data = { caption: caption, postImage: images, video: video, oldImage: oldImage, oldVideo: oldVideo, postId: postId,showPost:showPost,toshow }
    dispatch(updatePost(data)).then(res => {
      if (res.response_code == 200) {
        setHasNextPage(true)
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        updateState({
          ...iState, caption: '', images: [], video: [], oldImage: [], oldVideo: [], postId: '', type: 'new', imageReview: [], videoReview: [], createPostModal: false, particularPostId: '',showPost: 1,toshow:[]
        })
        updatePostCmt('')
        if (key === 'home') {
          dispatch(streetTalkPost(false, { pageNumber: 1 }))
        } else {
          dispatch(vendorTalkPost(false, { pageNumber: 1 }))
        }
      }
    }).catch(err => {
      const message = err && err.response && err.response ? err.response.message : "Something went handleSuit"
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
    })
  }

  const updatePostComment = (item, id, type) => {

    if (!_.isEmpty(item)) {
      const updateData = _.cloneDeep(iState)
      updateData.updateComment = item.comment
      updateData.commentId = id
      updateData.commentImages = item.images || ''
      updateData.commentVideo = item.video || ''
      updateData.type = type || ''
      updateCmtImages({ ...cmtImages, commentImages: item.images })
      updateCmtVideo({ ...cmtVideo, commentVideo: item.video })
      updateState(
        updateData
      )
    }
  }

  const saveComment = (id) => {
    let data = { commentId: commentId, comment: updateComment, commentImage: imageFile, video: videoFile }
    if (imageFile === '' && commentImages === '') {
      delete data.commentImage
      data = { commentId: commentId, comment: updateComment, video: videoFile, blankImage: 'img' }
    } else if (videoFile === '') {
      delete data.video
      data = { commentId: commentId, commentImage: imageFile, comment: updateComment, blankVideo: 'video' }
    }

    dispatch(particularUpdateComment(data)).then(res => {
      if (res.response_code == 200) {
        setHasNextPage(true)
        updateState({
          ...iState, commentId: "", updateComment: '', commentImages: '', commentVideo: '', imageFile: '', videoFile: '', type: 'new'
        })
        updateCmtImages({
          ...cmtImages, commentImages: '', imageFile: ''
        })
        updateCmtVideo({
          ...cmtVideo, commentVideo: '', videoFile: ''
        })

        updatePostCmt('')
        if (key === 'home') {
          dispatch(streetTalkPost(false, { pageNumber: 1 }))
        } else {
          dispatch(vendorTalkPost(false, { pageNumber: 1 }))
        }
        dispatch(postComment({ postId: id }))
      }
    }).catch(err => {
      const message = err && err.response && err.response ? err.response.message : "Something went handleSuit"
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
    })
  }
  const cancelEditComment = () => {
    updateState({
      ...iState, commentId: "", updateComment: '', type: 'new'
    })
    updatePostCmt('')
    updateCmtImages({
      ...cmtImages, commentImages: '', imageFile: ''
    })
    updateCmtVideo({
      ...cmtVideo, commentVideo: '', videoFile: ''
    })
  }

  useEffect(() => {
    if (key === 'home') {
      dispatch(streetTalkPost(false, { pageNumber: 1 }))
      dispatch(peopleMayKnow())
      dispatch(majorNews())
    } else {
      dispatch(vendorTalkPost(false, { pageNumber: 1 }))
      dispatch(peopleMayKnow())
      dispatch(majorNews())
    }
  }, [])

  const handleTab = (key) => {
    if (key === 'home') {
      setHasNextPage(true)
      setKey('home')
      dispatch(streetTalkPost(false, { pageNumber: 1 }))
      dispatch(peopleMayKnow())
      dispatch(majorNews())
    } else {
      setHasNextPage(true)
      setKey('profile')
      // dispatch(emptyUserLikeData())
      dispatch(vendorTalkPost(false, { pageNumber: 1 }))
      dispatch(peopleMayKnow())
      dispatch(majorNews())
    }
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target
    if (value === '3') {
      updateState({ ...iState, chooseUserModal: true, [name]: value })
      dispatch(getAllBuddies())
    } else {
      updateState({
        ...iState, [name]: value
      })
    }
  }

  // let multipleComents=[]
  const addComment = () => {
    updateState({
      ...iState,
      multipleComents: [...iState.multipleComents, {
        postId: postId,
        comment: comment,
        images: imageVideoUrl && imageVideoUrl.images ? imageVideoUrl.images : '',
        video: imageVideoUrl && imageVideoUrl.video ? imageVideoUrl.video : ''
      }],
      comment: ''
    })
    dispatch(emptyImageVideo())
    updateCmtImages({
      ...cmtImages, commentImages: '', imageFile: ''
    })
    updateCmtVideo({
      ...cmtVideo, commentVideo: '', videoFile: ''
    })
  }

  const addPost =()=>{
    updateState({
      ...iState,
      multiplePosts: [...iState.multiplePosts,{
        caption: caption,
        images: postImageVideoUrl && postImageVideoUrl.images ? postImageVideoUrl.images :[],
        video: postImageVideoUrl && postImageVideoUrl.video ? postImageVideoUrl.video :[],
        showPost: parseInt(showPost),
      }],toshow:toshow,
      caption:'',
      images:[],
      video:[],
      showPost:0,
      // toshow:[],
      userType:'',
      videoReview: [],
      imageReview: [],
    })
    dispatch(emptyImageVideo())
  }

  const replyComment = () => {
    let copyComments = [...multipleComents]
    if (comment !== '' || commentImages !== '' || commentVideo !== '') {
      copyComments.push({
        comment,
        postId: postId,
        images: imageVideoUrl && imageVideoUrl.images ? imageVideoUrl.images : '',
        video: imageVideoUrl && imageVideoUrl.video ? imageVideoUrl.video : ''
      })
    }
    dispatch(multipleComment({ multipleComents: copyComments })).then(res => {
      if (res.response_code == 200) {
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT
        })
        setHasNextPage(true)
        if (key === 'home') {
          dispatch(streetTalkPost(false, { pageNumber: 1 }))
          dispatch(postComment({ postId: postId }))
        } else {
          dispatch(vendorTalkPost(false, { pageNumber: 1 }))
          dispatch(postComment({ postId: postId }))
        }
        dispatch(emptyImageVideo())
        updateState({
          ...iState, commentModal: false, postId: '', commentIndex: 0, comment: '', emojiFlag: false
        })
        updateCmtImages({
          ...cmtImages, commentImages: '', imageFile: ''
        })
        updateCmtVideo({
          ...cmtVideo, commentVideo: '', videoFile: ''
        })
      }
    }).catch(err => {
      const message = err && err.response && err.response.data ? err.response.data.message : "Something went handleSuit"
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
    })

  }
  const submitComment = (e, id) => {
    e.preventDefault()
    const data = { postId: id, comment: postCmt, images: imageFile, video: videoFile }
    dispatch(createComment(data)).then(res => {
      if (res.response_code == 200) {
        setHasNextPage(true)
        updateState({
          ...iState, commentId: ''
        })
        updateCmtImages({
          ...cmtImages, commentImages: '', imageFile: ''
        })
        updateCmtVideo({
          ...cmtVideo, commentVideo: '', videoFile: ''
        })
        updatePostCmt('')
        if (key === 'home') {
          dispatch(streetTalkPost(false, { pageNumber: 1 }))
        } else {
          dispatch(vendorTalkPost(false, { pageNumber: 1 }))
        }
        dispatch(postComment({ postId: id }))
      }
    }).catch(err => {
      const message = err && err.response && err.response ? err.response.message : "Something went handleSuit"
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
    })
  }

  const handleComment = (text, i) => {
    updatePostCmt(text)
    updateState({ ...iState, particularPostId: i })
  }
  const onCommentFileHandler = (e) => {
    e.preventDefault()
    const file = e.target.files[0]
    if (file) {
      if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
        dispatch(uploadImageVideo({ images: file }))
        commentPreviewsFile(file)
      } else if (file.type === 'video/mp4') {
        const reader = new FileReader()
        reader.addEventListener("load", () => {
          dispatch(uploadImageVideo({ video: file }))
          updateCmtVideo({
            ...cmtVideo, commentVideo: reader.result, videoFile: file,
            //  particularPostId: id
          })
        }, false)
        if (file) {
          reader.readAsDataURL(file)
        }
      }
      else {
        toast.error('Only formats are allowed: jpeg, jpg, png files', {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }
  }

  const commentPreviewsFile = (file, id) => {
    const reader = new FileReader()
    reader.addEventListener("load", () => {
      updateCmtImages({
        ...cmtImages, commentImages: reader.result, imageFile: file,
      })
      // updateCmtImages(reader.result)
    }, false)
    if (file) {
      reader.readAsDataURL(file)
    }
  }

  const removeCommentData = (type) => {
    if (type === 'img') {
      dispatch(emptyImageVideo())
      updateCmtImages({
        ...cmtImages, commentImages: '', imageFile: ''
      })
    } else if (type === 'video') {

      dispatch(emptyImageVideo())
      updateCmtVideo({
        ...cmtVideo, commentVideo: '', videoFile: ''
      })
    }
  }

  const onFileHandler = (e) => {
    const file = e.target.files
    for (let i = 0; i < file.length; i++) {
      if (file[i].type === 'image/jpeg' || file[i].type === 'image/jpg' || file[i].type === 'image/png') {
        imageReview.push(URL.createObjectURL(file[i]))
        images.push(file[i])
    
      } else {
        toast.error('Only formats are allowed: jpeg, jpg, png files', {
          position: toast.POSITION.TOP_RIGHT
        })
      }

    }

    updateState({
      ...iState, imageReview,
      images

    })
    dispatch(multiplePostImageVideo({ images: images }))
  }

  const onVideoFileHandler = (e) => {
    const file = e.target.files
    for (let i = 0; i < file.length; i++) {
      if (file[i].type === 'video/mp4') {
        videoReview.push(URL.createObjectURL(file[i]))
        video.push(file[i])
      } else {
        toast.error('Only formats are allowed: mp4 video', {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }
    updateState({
      ...iState, videoReview,
      video
    })
    dispatch(multiplePostImageVideo({ video: video }))
  }

  const handleUserType = (type) => {
    if (type === 'user') {
      updateState({ ...iState, userType: 'user' })
      createUserPost('user')
    } else if (type === 'vendor') {
      updateState({ ...iState, userType: 'vendor' })
      createUserPost('vendor')
    }
    updateUserTypeShow(false)
  }


  const createUserPost = (userType) => {
    let data = {}
    // if (userType === undefined) {
    //   data = { caption: caption, images: images, video: video, showPost: parseInt(showPost), toshow }
    // } else {
    //   data = { caption: caption, images: images, video: video, showPost: parseInt(showPost), userType, toshow }
    // }
    // if (!data.images.length) {
    //   delete data.images
    // } else if (data.video === '') {
    //   delete data.video
    // } else if (data.userType === '' || data.userType === undefined || data.userType === 'undefined') {
    //   delete data.userType
    // }
    // if (toshow.length === 0) {
    //   delete data.toshow
    // }
    let copyPost =[...multiplePosts]
    if(caption !== '' || images.length !== 0 || video.length !== 0){
      copyPost.push({
        caption: caption,
        images: postImageVideoUrl && postImageVideoUrl.images ? postImageVideoUrl.images :[],
        video: postImageVideoUrl && postImageVideoUrl.video ? postImageVideoUrl.video :[],
        showPost: parseInt(showPost)
      })
    }
    dispatch(multiplePost({userType,multiplePosts:copyPost,toshow:toshow})).then(res => {
      if (res.response_code == 200) {
        setHasNextPage(true)
        updateState({
          ...iState, caption: '', images:[], video: [], userType: '', imageReview:[], videoReview: [], createPostModal: false, particularPostId: '', toshow: [], showPost: 1,multiplePosts:[], emojiFlag:false
        })
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        updatePostCmt('')
        if (key === 'home') {
          dispatch(streetTalkPost(false, { pageNumber: 1 }))
        dispatch(emptyImageVideo())
        } else {
          dispatch(vendorTalkPost(false, { pageNumber: 1 }))
        dispatch(emptyImageVideo())
        }
      }
    }).catch(err => {
      const message = err && err.response && err.response ? err.response.message : "Something went handleSuit"
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
    })
    // }
  }

  const submitPost = (e, n) => {
    e.preventDefault()
    if (type === 'share') {
      const data = { newCaption: newCaption, actualUserId: userId, actualPostId: postId, showPost: parseInt(showPost) }
      dispatch(likePost({ postId: postId, likeStatus: n }))
      dispatch(sharedBlastPost(data)).then(res => {
        if (res.response_code == 200) {
          const msg = res.message === "Post created successfully" ? "Post Shared successfully" : ''
          toast.success(msg, {
            position: toast.POSITION.TOP_RIGHT
          })
          updateState({ ...iState, newCaption: '', createPostModal: false, actualUserId: '', actualPostId: '', showPost: 1, caption: '', imageReview: [], videoReview: [], oldImage: [], oldVideo: [] })
          if (key === 'home') {
            dispatch(streetTalkPost(false, { pageNumber: 1 }))
          } else {
            dispatch(vendorTalkPost(false, { pageNumber: 1 }))
          }
        }
      }).catch(err => {
        const message = err && err.response && err.response.data ? err.response.data.message : "Something went handleSuit"
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT
        });
      })
    } else {
      dispatch(vendorCheck()).then(res => {
        if (res.response_code == 200) {
          updateUserTypeShow(true)
          updateState({ ...iState, createPostModal: false })
        } else if (res.response_code == 400) {
          createUserPost()
        }
      }).catch(err => {
        const message = err && err.response && err.response ? err.response.message : "Something went handleSuit"
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT
        });
      })
    }
  }

  const deleteImage = (i) => {
    if (type === 'new') {
      let imageUrl= [...postImageVideoUrl.images]
      imageUrl.splice(i,1)
      dispatch(multiplePostImageVideoSuccess({Data:{images:imageUrl}}))
      images.splice(i, 1)
      imageReview.splice(i, 1)
      updateState({ ...iState, images, imageReview })
    }
    else if (type === 'edit') {
      let index = oldImage.length ? i - (imageReview.length - 1) : i
      imageReview.splice(i, 1)
      images.splice(index, 1)
      // } 
      if (oldImage.length) {
        oldImage.splice(i, 1)
      }
      updateState({ ...iState, oldImage, imageReview, images })

    }

  }
  const deleteVideo = (i, type) => {
    if (type === 'new') {
      let videoUrl= [...postImageVideoUrl.video]
      videoUrl.splice(i,1)
      dispatch(multiplePostImageVideoSuccess({Data:{video:videoUrl}}))
      video.splice(i, 1)
      videoReview.splice(i, 1)
      updateState({ ...iState, videoReview, video })
    }
    else if (type === 'edit') {
      if (oldVideo.length - 1 < i) {
        let index = oldVideo.length ? i - (videoReview.length - 1) : i
        videoReview.splice(i, 1)
        video.splice(index, 1)
      } else if (oldVideo.length) {
        oldVideo.splice(i, 1)
      }
      updateState({ ...iState, oldVideo, videoReview, video })

    }

  }

  const handleTypeShow = () => {
    updateUserTypeShow(false)
  }

  const showComment = (id) => {
    updateState({ ...iState, selectedId: id })
    const data = { postId: id }
    dispatch(postComment(data)).then(res => {
      if (res.response_code == 200) {
        scrollIntoActiveDocView(id)
      }
    }).catch(err => {
      const message = err && err.response && err.response ? err.response.message : "Something went handleSuit"
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
    })
  }

  const deleteUserComment = (id, postid) => {
    dispatch(deleteComment({ commentId: id, postId: postid })).then(res => {
      if (res.response_code == 200) {
        setHasNextPage(true)
        if (key === 'home') {
          dispatch(streetTalkPost(false, { pageNumber: 1 }))
        } else {
          dispatch(vendorTalkPost(false, { pageNumber: 1 }))
        }
        dispatch(postComment({ postId: postid }))
      }
    }).catch(err => {
      const message = err && err.response && err.response ? err.response.message : "Something went handleSuit"
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
    })
  }

  const postLke = (id, n) => {
    const data = { postId: id, likeStatus: n }
    dispatch(likePost(data)).then(res => {
      if (res.response_code == 200) {
        setHasNextPage(true)
        if (key === 'home') {
          dispatch(streetTalkPost(false, { pageNumber: 1 }))
          if(particularPostId !== ''){
          dispatch(particularPost({ postId: particularPostId }))
          }
        } else {
          dispatch(vendorTalkPost(false, { pageNumber: 1 }))
          if(particularPostId !== ''){
            dispatch(particularPost({ postId: particularPostId }))
            }
        }
      }
    }).catch(err => {
      const message = err && err.response && err.response ? err.response.message : "Something went handleSuit"
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
    })
  }

  const particularPostDetail = (id, userId) => {
    dispatch(particularPost({ postId: id }))
    window.localStorage.setItem("postId", JSON.stringify(id))
    history.push(`/street-talk-details/${id}`)
  }

  const userPostDetail = (userId) => {
    dispatch(otherUserPost({ userId: userId }))
    window.localStorage.setItem("userId", JSON.stringify(userId))
  }

  const deleteUserPost = (id) => {
    dispatch(deletePost({ postId: id })).then(res => {
      if (res.response_code === 200) {
        setHasNextPage(true)
        if (key === 'home') {
          dispatch(streetTalkPost(false, { pageNumber: 1 }))
        } else {
          dispatch(vendorTalkPost(false, { pageNumber: 1 }))
        }
        updateState({...iState,deleteModal: false,deleteID:''})
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }).catch(err => {
      const message = err && err.response && err.response ? err.response.message : "Something went wrong"
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
    })
  }
  const postLikedUsers = (id, type) => {
    if (type === 'blast') {
      dispatch(blastUsers({ postId: id }))
      updateState({ ...iState, modalType: 'blast' })
      handleShow()
    } else if (type === 'like') {
      dispatch(likedUsers({ postId: id }))
      updateState({ ...iState, modalType: 'like' })
      handleShow()
    } else if (type === 'unlike') {
      dispatch(unlikedUsers({ postId: id }))
      updateState({ ...iState, modalType: 'unlike' })
      handleShow()
    }
  }

  const handleRequest = (e, id, type) => {
    e.preventDefault()
    if (type === 'follow') {
      dispatch(sendRequestBuddies({ reciverId: id })).then(res => {
        if (res.response_code == 200) {
          toast.success(res.message, {
            position: toast.POSITION.TOP_RIGHT
          });
          setHasNextPage(true)
          if (key === 'home') {
            dispatch(streetTalkPost(false, { pageNumber: 1 }))
          } else {
            dispatch(vendorTalkPost(false, { pageNumber: 1 }))
          }
          dispatch(peopleMayKnow())
        }
      }).catch(err => {
        const message = err && err.response && err.response.data ? err.response.data.message : "Something went wrong."
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT
        });
      })
    } else {
      updateState({ ...iState, unfollowModal: true, followUserId: id })
    }
  }

  const handleunfollowModal = (type) => {
    if (type === 'cancel') {
      updateState({ ...iState, unfollowModal: false, followUserId: '' })
    } else if (type === 'yes') {

      dispatch(deletePendingBuddies({ reciverId: followUserId })).then(res => {
        if (res.response_code == 200) {
          updateState({ ...iState, unfollowModal: false, followUserId: '' })
          setHasNextPage(true)
          if (key === 'home') {
            dispatch(streetTalkPost(false, { pageNumber: 1 }))
          } else {
            dispatch(vendorTalkPost(false, { pageNumber: 1 }))
          }
        }
      }).catch(err => {
        const message = err && err.response && err.response.data ? err.response.data.message : "Something went handleSuit"
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT
        });
      })
    }
  }


  const handleMouseHover = (data, id) => {
    clearTimeout(timeOut)
    updateState({
      ...iState, isHovering: true, hoverData: data, matchId: id, timeOut: null
    })
  }
  const handleHover = (data, id) => {
    clearTimeout(timeOut)
    updateState({
      ...iState, isHoveringModal: true, hoverData: data, matchId: id, timeOut: null
    })
  }
  const handleMouseLeave = () => {
    const timeOut = setTimeout(() => {
      updateState({
        ...iState, isHovering: false, hoverData: {}, matchId: ''
      })
    }, 2000);
    updateState({
      ...iState, timeOut
    })
  }

  const handleLeave = () => {
    const timeOut = setTimeout(() => {
      updateState({
        ...iState, hoverData: {}, matchId: '', isHoveringModal: false
      })
    }, 2000);
    updateState({
      ...iState, timeOut
    })
  }
  const scrollIntoActiveDocView = (activeId) => {
    var element = document.getElementById(`cmt${activeId}`)
    element && element.scrollIntoView({ behavior: "smooth", block: "center" })
  }

  const handleEmojiCaption = (e, emojiObject) => {
    let emojiComment = `${caption}${emojiObject.emoji}`
    updateState({ ...iState, caption: emojiComment })
  }
  const handleEmojiChange = (e, emojiObject) => {
    let emojiComment = `${comment}${emojiObject.emoji}`
    updateState({ ...iState, comment: emojiComment })
  }
  const handleEmojiPopup = () => {
    if (emojiFlag) {
      updateState({ ...iState, emojiFlag: false })
    } else {
      updateState({ ...iState, emojiFlag: true })
    }
  }

  const handlePreviewUrl =(url)=>{
    const win = window.open(url, "_blank");
    win.focus();
  }

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (scrollRef.current <= 1590 && scrollFlag) {
        updateState({...iState,scrollFlag:false,})
      }
      if (scrollRef.current >= 1590 && !scrollFlag) {
        updateState({...iState,scrollFlag:true,})
      }
      scrollRef.current = currentScrollY;

    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollFlag]);

  const handleCommentLike =(id,pId,n)=> {
    dispatch(commentLike({commentId:id,likeStatus:n})).then(res => {
      if (res.response_code == 200) { 
        dispatch(postComment({postId: pId})).then(res => {
        if (res.response_code == 200) {
          scrollIntoActiveDocView(id)
        }
      }).catch(err => {
        const message = err && err.response && err.response ? err.response.message : "Something went handleSuit"
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT
        });
      })
      }
    }).catch(err => {
      const message = err && err.response && err.response.data ? err.response.data.message : "Something went handleSuit"
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
    })
  }

  const handleSearchUser =(e,type)=> {
    if(type === 'search') {
    dispatch(searchAllBuddies({queries}))
    } else {
      updateState({
        ...iState, queries:''
      })
    dispatch(emptyBuddies())
    }
  }

  return (
    <Fragment>
      <div>
        <Header />
        <div className="TalkArea">
          {unseenNotification.length > 0 && 
          <div className="TalkAreajkhsd ">
            <span className="jsdjad" onClick={()=>readNotify()}><img src={uparrow} /></span>
            <ul>
              {unseenNotification.length > 0 ? unseenNotification.map((item,i)=>(
                <li onClick={() => particularPostDetail(item.postId)}><span><img src={item.usersDetail[0].profilePic && item.usersDetail[0].profilePic} /></span></li>
              )) : ''}
            </ul>
            <h3>{unseenNotification.length > 0 ? unseenNotification[0].type : ''}</h3>
            
          </div>}

          <div className="Commantabs">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => handleTab(k)}
            >
              <Tab eventKey="home" title="StreetTalk">
                <div className="container StickyProfile">
                  <div className="row">
                    <div className="col-sm-3 PostionSticky" >
                      <div className="TalkLeft">
                         <h4><img src={streetTalkLogo} /></h4>
                          <div className ="followerInner">
                          <div className="followerDet">
                                  <div className="follwerLeft">
                                  {(profileData && profileData.firstName) && profileData.profilePic ? <img src={profileData.profilePic} /> : profileData.firstName ? `${profileData.firstName.charAt(0).toUpperCase()}${profileData.lastName.charAt(0).toUpperCase()}` : ''}
                                    </div>
                                    <div className ="follwerRight">
                                      <h2>{profileData && profileData.firstName && profileData.firstName ? `${profileData.firstName} ${profileData.lastName}` : ''}</h2>
                                      <p>{profileData && profileData.uniqueId && profileData.uniqueId}</p>
                                      <h6 onClick={()=> history.push('/following-followeres')}>{followersCount} Following, {followingCount} Followers</h6>
                                    </div>
                                </div>
                            </div>
                        <ul>
                          <div className='streettakMenu'>
                        <i className="fa fa-home" aria-hidden="true"></i>
                          <li onClick={() => handlePath('/street-talk')}>
                            <a>Home</a>
                          </li>
                          </div>
                          <div className='streettakMenu'>
                          <i className="fa fa-user" aria-hidden="true"></i>
                          <Link to="/street-talk-profile" onClick={() => userPostDetail(profileData._id)}>
                          <li> 
                            <a>Profile</a>
                          </li>
                          </Link>
                          </div>
                          <div className='streettakMenu'>
                          <i className="fas fa-cog"></i>
                          <li onClick={() => handlePath('/change-password')}>
                            <a>Settings</a>
                          </li>
                          </div>
                          <div className='streettakMenu notiDot'>
                          {notificationCountData && notificationCountData[0] && notificationCountData[0].postCount &&  <span className="countMain">{notificationCountData[0].postCount}</span>}
                          <i className="fas fa-bell"></i>
                          <li onClick={() => handlePath('/notification')}>
                            <a>Notification</a>
                          </li>
                          </div>
                          {/* <div className='streettakMenu'>
                          <i className="fas fa-comment-alt"></i>
                          <li onClick={() => handlePath('/chat')}>
                            <a>Message</a>
                          </li>
                         
                          </div> */}
                        </ul>
                        <div className="makeapost">
                            <a  onClick={postLoader ? "" : openCreatePostModal}>Make a Post</a> 

                          </div>
                      </div>
                      {majorNewsData && majorNewsData.length > 0 &&
                          <div className="NewsBox">
                            <h4>StreetTak Major News</h4>
                            <article onClick={() => particularPostDetail(majorNewsData[0]._id)}>
                              <aside>
                                {majorNewsData[0].images[0] ? <img src={majorNewsData[0].images[0]} /> :<h6>{majorNewsData[0].caption}</h6>}
                              </aside>
                              <figure>
                                {majorNewsData[0].usersDetail[0].profilePic ? <img src={majorNewsData[0].usersDetail[0] && majorNewsData[0].usersDetail[0].profilePic} /> :
                                  `${majorNewsData[0].usersDetail[0].firstName.charAt(0).toUpperCase()}${majorNewsData[0].usersDetail[0].lastName.charAt(0).toUpperCase()}`}
                              </figure>
                              <figcaption>
                                <h5>{`${majorNewsData[0].usersDetail[0].firstName} ${majorNewsData[0].usersDetail[0].lastName}`}</h5>
                                {/* <p>@GameHuntress</p> */}
                              </figcaption>
                            </article>
                          </div>}
                    </div>

                    <div className="col-sm-6 padding_none">
                      <div className="PostArea">
                        <h1>Latest Post</h1>

                        <div className="PostUpload" onClick={postLoader ? "" : openCreatePostModal}>
                          <figure>
                            {(profileData && profileData.firstName) && profileData.profilePic ? <img src={profileData.profilePic} /> : profileData.firstName ? `${profileData.firstName.charAt(0).toUpperCase()}${profileData.lastName.charAt(0).toUpperCase()}` : ''}
                          </figure>
                          <input
                            // type="text"
                            placeholder="What is Happening Now ?"
                            // onChange={handleInputChange}
                            disabled
                            autoComplete="off"
                          />
                        </div>
                        <InfiniteScroll
                          dataLength={streetTalkPostData.length}
                          next={fetchMoreData}
                          hasMore={hasNextPage}
                        >
                          {(streetTalkPostData && streetTalkPostData.length > 0) ? streetTalkPostData.map((data, i) => (
                            <div className="PostBox" key={i} id={`cmt${data._id}`}>
                              <div className="PostIcon"
                                onMouseEnter={() => handleMouseHover(data, data._id)}
                                onMouseLeave={handleMouseLeave}>
                                {data.usersDetail[0].profilePic ? <img src={data.usersDetail[0].profilePic} /> : `${data.usersDetail[0].firstName.charAt(0).toUpperCase()}${data.usersDetail[0].lastName.charAt(0).toUpperCase()}`}
                              </div>
                              <div className="PostRight">

                                <span></span>
                                <Dropdown alignRight className="FilterCardDropdown PostDots" >
                                  <Dropdown.Toggle id="dropdown-basic">
                                    <i className="fas fa-ellipsis-h"></i>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {profileData._id === data.userId ?
                                      <React.Fragment>
                                        <Dropdown.Item className="FilterCardDropdownItem"
                                          onClick={() => editUpdatePost(data)}
                                        >
                                          <a>Edit Post</a>
                                        </Dropdown.Item>
                                        <Dropdown.Item className="FilterCardDropdownItem" 
                                           onClick={()=>openDeleteModal(data._id, 'post')}
                                        // onClick={() => deleteUserPost(data._id)}
                                        >
                                          <a >Delete Post</a>
                                        </Dropdown.Item>
                                   
                                      </React.Fragment>
                                      :  <Dropdown.Item className="FilterCardDropdownItem" onClick={() => openReportModal(data._id)}>
                                      <a data-toggle="modal" data-target="#ReportModal">
                                        Report Post
                                  </a>
                                    </Dropdown.Item>}
                                  </Dropdown.Menu>
                                </Dropdown>
                                <div className="PostContent">
                                  <div className="padinglft">
                                    <h4>
                                      <div
                                        onMouseEnter={() => handleMouseHover(data, data._id)}
                                        onMouseLeave={handleMouseLeave}
                                      >
                                        <Link to="/street-talk-profile" onClick={() => userPostDetail(data.userId)}>{data.usersDetail[0].firstName && `${data.usersDetail[0].firstName} ${data.usersDetail[0].lastName}`}</Link></div>
                                      <span className="Time">{`${moment(data.createdAt).fromNow(true)} ago`}</span>
                                      <span className="postDate">{moment(data.createdAt).format('MM-DD-YYYY')}</span>
                                      <span>{data.shared ? <span>
                                        <img src={Blast} />
                                        <span className="blastBy"> {` Blasted By ${data.usersDetail[0].firstName}  ${data.usersDetail[0].lastName}`}</span>
                                        </span> : ''}
                                      </span>
                                    </h4>
                                    {
                                    (isHovering || isHoveringModal) && (matchId === data._id) && Object.keys(hoverData).length > 0 &&
                                      // <Link to="/street-talk-profile" onClick={() => userPostDetail(data.userId)}>
                                      <div className="hoverModal"
                                        onMouseEnter={() => handleHover(data, data._id)}
                                        onMouseLeave={handleLeave}
                                      >
                                        <figure>
                                          {hoverData && hoverData.usersDetail[0] && hoverData.usersDetail[0].profilePic ? <img src={hoverData.usersDetail[0].profilePic} /> : `${hoverData.usersDetail[0].firstName.charAt(0).toUpperCase()}${hoverData.usersDetail[0].lastName.charAt(0).toUpperCase()}`}
                                        </figure>
                                        {(userDataId._id !== hoverData.userId) && <span>
                                          {hoverData.accepted ? <h5 onClick={(e) => handleRequest(e, data.userId, 'following')}>Following</h5> : <h5 onClick={(e) => handleRequest(e, data.userId, 'follow')}>Follow</h5>}
                                        </span>}
                                        {(userDataId._id !== hoverData.userId) ? <h4>{`${hoverData.usersDetail[0].firstName} ${hoverData.usersDetail[0].lastName}`}</h4> :
                                          <h4 className="pt-4">{`${hoverData.usersDetail[0].firstName} ${hoverData.usersDetail[0].lastName}`}</h4>}
                                        <Link to="/street-talk-profile" ><p onClick={() => userPostDetail(hoverData.usersDetail[0]._id)}>{hoverData.usersDetail[0].uniqueId}</p></Link>
                                        <p>{hoverData.usersDetail[0].bio}</p>
                                        <p>{`${hoverData.followingcount} Following, ${hoverData.followerscount} Followers`}</p>
                                      </div>
                                      // </Link>
                                    }
                                    <p>{data.newCaption}</p>
                                  </div>

                                </div>
                                <div className={data.shared ? 'borderShared' : ''}>
                                  {data.shared && data.shared &&
                                    <div>
                                      <figure className="sharedIcon">
                                        {data.actualUserDetail[0] && data.actualUserDetail[0].profilePic ? <img src={data.actualUserDetail[0].profilePic} /> : `${data.actualUserDetail[0].firstName.charAt(0).toUpperCase()}${data.actualUserDetail[0].lastName.charAt(0).toUpperCase()}`}
                                      </figure>
                                      <Link to="/street-talk-profile"><h6 className="mt-2 padinglft" style={{ color: '#565656' }} onClick={() => userPostDetail(data.actualUserDetail[0]._id)}>{`${data.actualUserDetail[0].firstName} ${data.actualUserDetail[0].lastName}`}</h6></Link>
                                    </div>}

                                  {data.caption && data.caption && data.caption.split(/[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi)
                                    .map((part, index) => index % 2 === 0 ? <p className="padinglft fghtfgh">{part}</p> :
                                      <p className="padinglft linkMargin" onClick={()=> handlePreviewUrl(data.caption)}>
                                        <Preview
                                          url={data.caption}
                                          loadingText="loading..."
                                           notFound="URL Not Found!"
                                        />
                                      </p>
                                    )}

                                  <div className="PostImages padingleft">
                                    {data.video && data.hasOwnProperty("video") && data.video.map((video, i) => {
                                      return (
                                        <React.Fragment >
                                          {i <= 2 && <span className={data.video.length > 1 ? "Video" : ''}>
                                            <Link>
                                              <Player autoPlay muted className={data.video.length == 1 ? 'singleVideo' : ''}>
                                                <source className={data.video.length == 1 ? 'singleVideo' : ''} src={video} />
                                                <BigPlayButton position="center" />
                                              </Player>
                                            </Link>
                                          </span>}
                                          {data.video.length === 4 && i === 3 && <span className="Video">
                                            <Link to="/street-talk-details" onClick={() => particularPostDetail(data._id, data.userId)}>
                                              <Player autoPlay muted onClick={() => particularPostDetail(data._id, data.userId)} className={data.video.length == 1 ? 'singleVideo' : ''}>
                                                <source className={data.video.length == 1 ? 'singleVideo' : ''} src={video} />
                                                <BigPlayButton position="center" />
                                              </Player>
                                            </Link>
                                          </span>}

                                        </React.Fragment>
                                      )
                                    })}
                                    {data.video && data.video.length > 4 && <span className="Video" style={{ cursor: 'pointer' }}>
                                      <Link to="/street-talk-details" onClick={() => particularPostDetail(data._id, data.userId)}>
                                        <Player autoPlay muted onClick={() => particularPostDetail(data._id, data.userId)} className={data.video.length == 1 ? 'singleVideo' : ''}>
                                          <source className={data.video.length == 1 ? 'singleVideo' : ''} src={data.video[data.video.length - 1]} />
                                          <BigPlayButton position="center" />
                                        </Player>
                                      </Link>
                                      <span className="Count">+{data.video.length - 4}</span></span>}
                                    {/* </div> */}

                                    {/* <Link to="/street-talk-details" onClick={()=>particularPostDetail(data._id, data.userId)}> */}
                                    {data.images && data.images.map((img, i) => {
                                      return (
                                        <React.Fragment>
                                          {i <= 2 && <span onClick={() => openImageViewModal(data)} className={data.images.length > 1 ? "Images" : ''}><img className={data.images.length == 1 ? 'singleImg' : ''} src={img} /></span>}
                                          {data.images.length === 4 && i === 3 && <span onClick={() => openImageViewModal(data)} className="Images"><img className={data.images.length == 1 ? 'singleImg' : ''} src={img} /></span>}

                                        </React.Fragment>
                                      )
                                    })}
                                    {data.images && data.images.length > 4 && <span onClick={() => openImageViewModal(data)} className="Images" style={{ cursor: 'pointer' }}>
                                      <img className={data.images.length == 1 ? 'singleVideo' : ''} src={data.images[data.images.length - 1]} />
                                      <span className="Count">+{data.images.length - 4}</span></span>}
                                  </div>
                                </div>

                                <div className="LikeLine">
                                  <ul>
                                    <li>
                                      <a title={data.blastStatus ? 'Blasted' : 'Blast'}>
                                        {data.blastStatus ? <img src={Blast} onClick={() => editUpdatePost(data, 'share')} /> :
                                          <img src={Icon1} onClick={() => editUpdatePost(data, 'share')} />}
                                        <span onClick={() => postLikedUsers(data._id, 'blast')}>{data.blastCount}</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a title='Comments'>
                                        <img src={Icon2} onClick={() => showComment(data._id)} />
                                        <span>{data.numberOfComments}</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a title='Like'>
                                        {data.likedStatus ? <img src={Like} onClick={() => postLke(data._id, 3)} /> :
                                          <img src={Icon4} onClick={() => postLke(data._id, 1)} />}
                                        <span onClick={() => postLikedUsers(data._id, 'like')}>{data.likeCount}</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a title='Dislike'>
                                        {data.unlikedStatus ? <img src={Dislike} onClick={() => postLke(data._id, 3)} /> :
                                          <img src={Icon6} onClick={() => postLke(data._id, 2)} />}
                                        <span onClick={() => postLikedUsers(data._id, 'unlike')}>{data.unlikeCount}</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a title='Share'onClick={()=>openShareModal(data._id)}>
                                      
                                        <img src={Icon5} />
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <div className="postComment" >
                                  {(postCommentData && postCommentData.length > 0) ? postCommentData.map((item, key) => {
                                    return (
                                      <React.Fragment>
                                        {item.postId === data._id &&
                                          <div className="PostCommentBox" >
                                            <ul>
                                              <li>
                                                <div className="Comment">
                                                  <figure>
                                                    {item.userDetail[0].profilePic ? <img src={item.userDetail[0].profilePic} /> : `${item.userDetail[0].firstName.charAt(0).toUpperCase()}${item.userDetail[0].lastName.charAt(0).toUpperCase()}`}
                                                  </figure>
                                                  <figcaption>
                                                    <h5>{`${item.userDetail[0].firstName} ${item.userDetail[0].lastName}`}</h5>
                                                    {commentId === item._id ?
                                                      <span>
                                                        <input
                                                          type='file'
                                                          id='Image'
                                                          onChange={(e) => onCommentFileHandler(e)}
                                                          onClick={e => e.target.value = null}
                                                          style={{ display: 'none' }}
                                                        />
                                                        <label htmlFor='Image'>
                                                          <i className="fas fa-camera mr-2"></i>
                                                        </label>
                                                        <input
                                                          type="text"
                                                          name="updateComment"
                                                          value={updateComment}
                                                          onChange={handleInputChange}
                                                        /> </span> : <span> <p>{item.comment}</p></span>}
                                                    {commentImages && commentId === item._id ?
                                                      <span><i className="pl-2 far fa-times-circle" onClick={() => removeCommentData('img')}></i>

                                                        <img style={{ height: '100px' }} src={commentImages} />
                                                      </span>
                                                      : item.images && commentId !== item._id ?
                                                        <img style={{ height: '100px' }} src={item.images && item.images} />
                                                        : ''
                                                    }
                                                    {commentVideo && commentId === item._id ?
                                                      <span> <i className="pl-2 far fa-times-circle" onClick={() => removeCommentData('video')}></i>
                                                        <Player fluid={false} height={100} width={200}>
                                                          <source src={commentVideo} />
                                                          <BigPlayButton position="center" />
                                                        </Player></span>
                                                      : item.video && commentId !== item._id ?
                                                        <span>
                                                          <Player fluid={false} height={100} width={200}>
                                                            <source src={item.video && item.video} />
                                                            <BigPlayButton position="center" />
                                                          </Player></span>
                                                        : ''}
                                                  </figcaption>
                                                  <div style={{ padding: "10px 0 0 5px " }}>

                                                    {profileData._id === item.userId &&
                                                      <Dropdown alignRight className="FilterCardDropdown PostDots" style={{ position: 'relative', top: '0px' }}>
                                                        {type !== 'edit' ?<Dropdown.Toggle id="dropdown-commrt">
                                                          <i className="fas fa-ellipsis-h"></i>
                                                        </Dropdown.Toggle> :''}
                                                        <Dropdown.Menu>
                                                          <React.Fragment>
                                                            <Dropdown.Item className="FilterCardDropdownItem"
                                                              onClick={() => updatePostComment(item, item._id, 'edit')}
                                                            ><i className="fas fa-edit postMenu" > </i>
                                                              <a>Edit comment</a>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item className="FilterCardDropdownItem" onClick={() => deleteUserComment(item._id, item.postId)}>
                                                              <i className="far fa-trash-alt postMenu"> </i>
                                                              <a >Delete comment</a>
                                                            </Dropdown.Item>
                                                          </React.Fragment>
                                                        </Dropdown.Menu>
                                                      </Dropdown>
                                                    }
                                                    {commentId === item._id ? <span className='d-flex'>{((updateComment !== '') || (commentVideo !== '' || commentImages !== '')) && <i className="far fa-check-circle" onClick={() => saveComment(item.postId)}></i>}<i className="pl-2 far fa-times-circle" onClick={cancelEditComment}></i></span> : ''}
                                                  </div>
                                                </div>
                                                <div className="commentLikeIcon">                                                                                                 
                                                <span className='timeSpan'>{`${moment(item.createdAt).fromNow(true)} ago`}</span>
                                                {item.likedStatus ? <img src={Like} onClick={()=>handleCommentLike(item._id,item.postId,3)} />:<img src={Icon4} onClick={()=>handleCommentLike(item._id,item.postId,1)} />}
                                                <p>{item.likeCount}</p>
                                                 {item.unlikedStatus ? <img src={Dislike} onClick={()=>handleCommentLike(item._id,item.postId,3)} /> :<img src={Icon6} onClick={()=>handleCommentLike(item._id,item.postId,2)} />}
                                                 <p>{item.unlikeCount}</p>
                                                 </div>
                                              </li>
                                            </ul>
                                          </div>}
                                      </React.Fragment>
                                    )
                                  }) : ''
                                  }
                                </div>

                                <div className="CommentPost" onClick={() => updateState({ ...iState, commentModal: true, commentIndex: i, postId: data._id })}>
                                  <figure>
                                    {(profileData && profileData.firstName) && profileData.profilePic ? <img src={profileData.profilePic} /> : profileData.firstName ? `${profileData.firstName.charAt(0).toUpperCase()}${profileData.lastName.charAt(0).toUpperCase()}` : ''}
                                  </figure>
			 								          		<input type="text" disabled placeholder="Leave your thoughts here...." />
                                </div>
                              </div>
                            </div>
                          )) :
                            <CustomLoader loading={streetTalkLoader} />
                          }
                        </InfiniteScroll>

                        {isOpen && (
                          <LightboxCustom
                            images={lightBoxImg}
                            lightBoxOpen={() => updateState({
                              ...iState, isOpen: false
                            })}
                          />
                        )}
                      </div>
                    </div>

                    <div className="col-sm-3 BottomSticky" id={scrollFlag ? 'peopleKnowBox' :''} >
                      <div className="TalkRight" > 
                        <div className="Search" >
                          <form>
                            <span>
                              <i className="fa fa-search" style={{cursor:'pointer'}} onClick={(e)=>handleSearchUser(e,'search')}></i>
                            </span>
                            <input type="search" name="queries" value={queries} onChange={(e)=>handleInputChange(e)}  placeholder="Search" />
                            <span><i className="fa fa-times" style={{cursor:'pointer'}} onClick={(e)=>handleSearchUser(e, 'remove')}></i></span>
                          </form>
                        </div>
                        {searchBuddies && searchBuddies.length > 0 &&
                        <div className="PeopleBox" >
                          <h4>Searched User</h4>

                          {searchBuddies && searchBuddies.length > 0 && searchBuddies.map((item, i) => (
                            <ul>
                              <li>
                              <Link to="/street-talk-profile" onClick={() => userPostDetail(item._id)}>
                                <figure>
                                  {item.profilePic ? <img src={item.profilePic} /> : `${item.firstName.charAt(0).toUpperCase()}${item.lastName.charAt(0).toUpperCase()}`}
                                </figure>
                                </Link>
                                <figcaption>
                                <Link to="/street-talk-profile" onClick={() => userPostDetail(item._id)}>
                                  <h5 className="searchUser">{`${item.firstName} ${item.lastName}`}</h5>
                                  </Link>
                                  <h6>{item.uniqueId}</h6>
                                </figcaption>
                              </li>
                            </ul>
                          ))}
                        </div>}
                     
                        {majorNewsData && majorNewsData.length > 0 &&
                          <div className="NewsBox">
                            <h4>StreetTak Major News</h4>
                            <article onClick={() => particularPostDetail(majorNewsData[0]._id)}>
                              <aside>
                                
                              {majorNewsData[0].images[0] ? <img src={majorNewsData[0].images[0]} /> :<h6>{majorNewsData[0].caption}</h6>}
                              </aside>
                              <figure>
                                {majorNewsData[0].usersDetail[0].profilePic ? <img src={majorNewsData[0].usersDetail[0] && majorNewsData[0].usersDetail[0].profilePic} /> :
                                  `${majorNewsData[0].usersDetail[0].firstName.charAt(0).toUpperCase()}${majorNewsData[0].usersDetail[0].lastName.charAt(0).toUpperCase()}`}
                              </figure>
                              <figcaption>
                                <h5>{`${majorNewsData[0].usersDetail[0].firstName} ${majorNewsData[0].usersDetail[0].lastName}`}</h5>
                                {/* <p>@GameHuntress</p> */}
                              </figcaption>
                            </article>
                          </div>}
                            <div className="NewsBox NewsFeed">
                            <h4>News</h4>
                            {streetTakNewsData && streetTakNewsData.Data && streetTakNewsData.Data.length > 0 ?  streetTakNewsData.Data.map((item,i)=>(
                            <article onClick={()=>handlePreviewUrl(item.url)}>
                              <aside>
                                <img src={item.urlToImage} />
                              </aside>
                              <figcaption>
                                <h5>{item.title}</h5>
                              </figcaption>
                            </article>
                          )):''}
                          </div>
                          

                        <div className="PeopleBox" >
                          <h4>People You Might Know</h4>

                          {peopleMayKnowData && peopleMayKnowData.length > 0 && peopleMayKnowData.map((item, i) => (
                            <ul>
                              <li>
                                <figure>
                                  {item.profilePic ? <img src={item.profilePic} /> : `${item.firstName.charAt(0).toUpperCase()}${item.lastName.charAt(0).toUpperCase()}`}
                                </figure>
                                <figcaption>
                                  <h5>{`${item.firstName} ${item.lastName}`}</h5>
                                  <h6>{item.uniqueId}</h6>
                                </figcaption>
                                <a className="add-friend">
                                  <i className="fa fa-plus" onClick={(e) => handleRequest(e, item._id, 'follow')}></i>
                                </a>
                              </li>
                            </ul>
                          ))}
                        </div>
                     
                      </div>
                    </div>
                  
                    <div className="clear"></div>
                  
                  </div>
                </div>
              </Tab>
              <Tab eventKey="profile" title="Vendors"  >
                <div className="container StickyProfile">
                  <div className="row">
                    <div className="col-sm-3 PostionSticky">
                      <div className="TalkLeft">
                      <h4><img src={streetTalkLogo} /></h4>
                      <div className ="followerInner">
                          <div className="followerDet">
                                  <div className="follwerLeft">
                                  {(profileData && profileData.firstName) && profileData.profilePic ? <img src={profileData.profilePic} /> : profileData.firstName ? `${profileData.firstName.charAt(0).toUpperCase()}${profileData.lastName.charAt(0).toUpperCase()}` : ''}
                                    </div>
                                    <div className ="follwerRight">
                                      <h2>{profileData && profileData.firstName && profileData.firstName ? `${profileData.firstName} ${profileData.lastName}` : ''}</h2>
                                      <p>{profileData && profileData.uniqueId && profileData.uniqueId}</p>
                                      <h6 onClick={()=> history.push('/following-followeres')}>{followersCount} Following, {followingCount} Followers</h6>
                                    </div>
                                </div>
                            </div>
                      <ul>
                          <div className='streettakMenu'>
                        <i className="fa fa-home" aria-hidden="true"></i>
                          <li onClick={() => handlePath('/street-talk')}>
                            <a>Home</a>
                          </li>
                          </div>
                          <div className='streettakMenu'>
                          <i className="fa fa-user" aria-hidden="true"></i>
                          <Link to="/street-talk-profile" onClick={() => userPostDetail(profileData._id)}>
                          <li> 
                            <a>Profile</a>
                          </li>
                          </Link>
                          </div>
                          <div className='streettakMenu'>
                          <i className="fas fa-cog"></i>
                          <li onClick={() => handlePath('/change-password')}>
                            <a>Settings</a>
                          </li>
                          </div>
                          <div className='streettakMenu notiDot'>
                          {notificationCountData && notificationCountData[0] && notificationCountData[0].postCount &&  <span className="countMain">{notificationCountData[0].postCount}</span>}
                          <i className="fas fa-bell"></i>
                          <li onClick={() => handlePath('/notification')}>
                            <a>Notification</a>
                          </li>
                          </div>
                          {/* <div className='streettakMenu'>
                          <i className="fas fa-comment-alt"></i>
                          <li onClick={() => handlePath('/chat')}>
                            <a>Message</a>
                          </li>
                          </div> */}
                        </ul>                        
                        <div className="makeapost">
                            <a   onClick={postLoader ? "" : openCreatePostModal}>Make a Post</a> 

                          </div>
                      </div>
                      {majorNewsData && majorNewsData.length > 0 &&
                          <div className="NewsBox">
                            <h4>StreetTak Major News</h4>
                            <article onClick={() => particularPostDetail(majorNewsData[0]._id)}>
                              <aside>
                                {majorNewsData[0].images[0] ? <img src={majorNewsData[0].images[0]} /> :<h6>{majorNewsData[0].caption}</h6>}
                              </aside>
                              <figure>
                                {majorNewsData[0].usersDetail[0].profilePic ? <img src={majorNewsData[0].usersDetail[0] && majorNewsData[0].usersDetail[0].profilePic} /> :
                                  `${majorNewsData[0].usersDetail[0].firstName.charAt(0).toUpperCase()}${majorNewsData[0].usersDetail[0].lastName.charAt(0).toUpperCase()}`}
                              </figure>
                              <figcaption>
                                <h5>{`${majorNewsData[0].usersDetail[0].firstName} ${majorNewsData[0].usersDetail[0].lastName}`}</h5>
                                {/* <p>@GameHuntress</p> */}
                              </figcaption>
                            </article>
                          </div>}
                    </div>
                   
                    <div className="col-sm-6 padding_none">
                      <div className="PostArea">
                        <h1>Latest Post</h1>

                        <div className="PostUpload" onClick={postLoader ? "" : openCreatePostModal}>
                          <figure>
                            {(profileData && profileData.firstName) && profileData.profilePic ? <img src={profileData.profilePic} /> : profileData.firstName ? `${profileData.firstName.charAt(0).toUpperCase()}${profileData.lastName.charAt(0).toUpperCase()}` : ''}
                          </figure>
                          <input
                            type="text"
                            placeholder="What is Happening Now ?"
                            disabled
                            onChange={handleInputChange}
                            autoComplete="off"
                          />
                        </div>
                        <InfiniteScroll
                          dataLength={vendorTalkPostData.length}
                          next={fetchMoreData}
                          hasMore={hasNextPage}
                        >
                          {(vendorTalkPostData && vendorTalkPostData.length > 0) ? vendorTalkPostData.map((data, i) => (
                            <div className="PostBox" id={`cmt${data._id}`}>
                              <div className="PostIcon"
                                onMouseEnter={() => handleMouseHover(data, data._id)}
                                onMouseLeave={handleMouseLeave}
                              >
                                {data.usersDetail[0].profilePic ? <img src={data.usersDetail[0].profilePic} /> : `${data.usersDetail[0].firstName.charAt(0).toUpperCase()}${data.usersDetail[0].lastName.charAt(0).toUpperCase()}`}
                              </div>
                              <div className="PostRight">
                                <React.Fragment>
                                  <span></span>
                                  <Dropdown alignRight className="FilterCardDropdown PostDots" >
                                    <Dropdown.Toggle id="dropdown-basic">
                                      <i className="fas fa-ellipsis-h"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {profileData._id === data.userId ?
                                        <React.Fragment>
                                          <Dropdown.Item className="FilterCardDropdownItem" onClick={() => editUpdatePost(data)}>
                                            <a>Edit Post</a>
                                          </Dropdown.Item>
                                          <Dropdown.Item className="FilterCardDropdownItem"
                                          onClick={()=>openDeleteModal(data._id, 'post')}
                                          // onClick={() => deleteUserPost(data._id)}
                                          >
                                            <a>Delete Post</a>
                                          </Dropdown.Item>
                                      
                                        </React.Fragment>
                                        : <Dropdown.Item className="FilterCardDropdownItem" onClick={() => openReportModal(data._id)}>
                                        <a data-toggle="modal" data-target="#ReportModal">
                                          Report Post
                                    </a>
                                      </Dropdown.Item>}
                                        
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </React.Fragment>
                                <div className="PostContent">
                                  <div className="padinglft">
                                    <h4>
                                      <div
                                        onMouseEnter={() => handleMouseHover(data, data._id)}
                                        onMouseLeave={handleMouseLeave}
                                      >
                                        <Link to="/street-talk-profile" onClick={() => userPostDetail(data.userId)}>{data.usersDetail[0].firstName && `${data.usersDetail[0].firstName} ${data.usersDetail[0].lastName}`}</Link></div>
                                      <span className="Time">{`${moment(data.createdAt).fromNow(true)} ago`}</span><span className="postDate">{moment(data.createdAt).format('MM-DD-YYYY')}</span>
                                      <span>{data.shared ? <span>
                                        <img src={Blast} />
                                        <span className="blastBy"> {` Blasted By ${data.usersDetail[0].firstName}  ${data.usersDetail[0].lastName}`}</span>
                                        </span> : ''}
                                      </span>
                                    </h4>
                                    {(isHovering || isHoveringModal) && (matchId === data._id) &&
                                      <Link to="/login"
                                        onMouseEnter={() => handleHover(data, data._id)}
                                        onMouseLeave={handleLeave}
                                      >
                                        <div onMouseEnter={() => handleMouseHover(data, data._id)} className="hoverModal" >
                                          <figure>
                                            {hoverData && hoverData.usersDetail[0] && hoverData.usersDetail[0].profilePic ? <img src={hoverData.usersDetail[0].profilePic} /> : `${hoverData.usersDetail[0].firstName.charAt(0).toUpperCase()}${hoverData.usersDetail[0].lastName.charAt(0).toUpperCase()}`}
                                          </figure>
                                          {(userDataId._id !== hoverData.userId) && <span>
                                            {hoverData.accepted ? <h5 onClick={(e) => handleRequest(e, data.userId, 'following')}>Following</h5> : <h5 onClick={(e) => handleRequest(e, data.userId, 'follow')}>Follow</h5>}
                                          </span>}
                                          {(userDataId._id !== hoverData.userId) ? <h4>{`${hoverData.usersDetail[0].firstName} ${hoverData.usersDetail[0].lastName}`}</h4> :
                                            <h4 className="pt-4">{`${hoverData.usersDetail[0].firstName} ${hoverData.usersDetail[0].lastName}`}</h4>}
                                         <Link to="/street-talk-profile" ><p onClick={() => userPostDetail(hoverData.usersDetail[0]._id)}>{hoverData.usersDetail[0].uniqueId}</p></Link>
                                          <p>{hoverData.usersDetail[0].bio}</p>
                                          <p>{`${hoverData.followingcount} Following, ${hoverData.followerscount} Followers`}</p>
                                        </div>
                                      </Link>}
                                    <p>{data.newCaption}</p>
                                  </div>
                                </div>
                                <div className={data.shared ? 'borderShared' : ''}>
                                  {data.shared &&
                                    <div >
                                      <div className="sharedIcon">
                                        {data.actualUserDetail[0].profilePic ? <img src={data.actualUserDetail[0].profilePic} /> : `${data.actualUserDetail[0].firstName.charAt(0).toUpperCase()}${data.actualUserDetail[0].lastName.charAt(0).toUpperCase()}`}
                                      </div>
                                      <Link to="/street-talk-profile"><h6 className="padinglft mt-2" style={{ color: '#565656' }} onClick={() => userPostDetail(data.actualUserDetail[0]._id)}>{`${data.actualUserDetail[0].firstName} ${data.actualUserDetail[0].lastName}`}</h6></Link>
                                    </div>}
                                  {/* <p className="padinglft">{data.caption} </p> */}
                                  {data.caption &&  data.caption.split(/[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi)
                                    .map((part, index) => index % 2 === 0 ? <p className="padinglft fghtfgh">{part}</p> :
                                      <p className="padinglft linkMargin" onClick={()=> handlePreviewUrl(data.caption)}>
                                       <Preview
                                          url={data.caption}
                                          loadingText="loading..."
                                           notFound="URL Not Found!"
                                        />
                                      </p>)}
                                  <div className="PostImages padingleft">
                                    {data.video && data.hasOwnProperty("video") && data.video.map((video, i) => {
                                      return (
                                        <React.Fragment>
                                          {i <= 2 && <span className={data.video.length > 1 ? "Video" : ''}>
                                            <Link>
                                              <Player autoPlay muted className={data.video.length == 1 ? 'singleVideo' : ''}>
                                                <source className={data.video.length == 1 ? 'singleVideo' : ''} src={video} />
                                                <BigPlayButton position="center" />
                                              </Player>
                                            </Link>
                                          </span>}
                                          {data.video.length === 4 && i === 3 && <span className="Video">
                                            <Link to="/street-talk-details" onClick={() => particularPostDetail(data._id, data.userId)}>
                                              <Player autoPlay muted className={data.video.length == 1 ? 'singleVideo' : ''}>
                                                <source className={data.video.length == 1 ? 'singleVideo' : ''} src={video} />
                                                <BigPlayButton position="center" />
                                              </Player>
                                            </Link>
                                          </span>}

                                        </React.Fragment>
                                      )
                                    })}
                                    {data.video && data.video.length > 4 && <span className="Video" style={{ cursor: 'pointer' }}>
                                      <Link to="/street-talk-details" onClick={() => particularPostDetail(data._id, data.userId)}>
                                        <Player autoPlay muted  className={data.video.length == 1 ? 'singleVideo' : ''}>
                                          <source className={data.video.length == 1 ? 'singleVideo' : ''} src={data.video[data.video.length - 1]} />
                                          <BigPlayButton position="center" />
                                        </Player>
                                      </Link>
                                      <span className="Count">+{data.video.length - 4}</span></span>}

                                    {/* <Link to="/street-talk-details" onClick={()=>particularPostDetail(data._id, data.userId)}> */}
                                    {data.images && data.images.map((img, i) => {
                                      return (
                                        <React.Fragment>

                                          {i <= 2 && <span onClick={() => openImageViewModal(data)} className={data.images.length > 1 ? "Images" : ''}><img className={data.images.length == 1 ? 'singleImg' : ''} src={img} /></span>}
                                          {data.images.length === 4 && i === 3 && <span onClick={() => openImageViewModal(data)} className="Images"><img className={data.images.length == 1 ? 'singleImg' : ''} src={img} /></span>}

                                        </React.Fragment>
                                      )
                                    })}
                                    {data.images && data.images.length > 4 && <span onClick={() => openImageViewModal(data)} className="Images" style={{ cursor: 'pointer' }}>
                                      <img className={data.images.length == 1 ? 'singleImg' : ''} src={data.images[data.images.length - 1]} />
                                      <span className="Count">+{data.images.length - 4}</span></span>}
                                    {/* </Link> */}
                                  </div>

                                  {/* <div className="PostImages">
                              <Link to="/street-talk-details" onClick={()=>particularPostDetail(data._id, data.userId)}>
                                <img src={data.images} />
                              </Link>
                            </div> */}
                                </div>
                                <div className="LikeLine">
                                  <ul>
                                    <li>
                                      <a title={data.blastStatus ? 'Blasted' : 'Blast'}>
                                        {data.blastStatus ? <img src={Blast} onClick={() => editUpdatePost(data, 'share')} /> :
                                          <img src={Icon1} onClick={() => editUpdatePost(data, 'share')} />}
                                        <span onClick={() => postLikedUsers(data._id, 'blast')}>{data.blastCount}</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a title='Comments'>
                                        <img src={Icon2} onClick={() => showComment(data._id)} />
                                        <span>{data.numberOfComments}</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a title='Like'>
                                        {data.likedStatus ? <img src={Like} onClick={() => postLke(data._id, 3)} /> :
                                          <img src={Icon4} onClick={() => postLke(data._id, 1)} />}
                                        <span onClick={() => postLikedUsers(data._id, 'like')}>{data.likeCount}</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a title='Dislike'>
                                        {data.unlikedStatus ? <img src={Dislike} onClick={() => postLke(data._id, 3)} /> :
                                          <img src={Icon6} onClick={() => postLke(data._id, 2)} />}
                                        <span onClick={() => postLikedUsers(data._id, 'unlike')}>{data.unlikeCount}</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a title='Share' onClick={()=>openShareModal(data._id)}>
                                        <img src={Icon5} />
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <div className="postComment">
                                  {(postCommentData && postCommentData.length > 0) ? postCommentData.map((item, key) => {
                                    return (
                                      <React.Fragment>
                                        {item.postId === data._id &&
                                          <div className="PostCommentBox">
                                            <ul>
                                              <li>
                                                <div className="Comment">
                                                  <figure>
                                                    {item.userDetail[0].profilePic ? <img src={item.userDetail[0].profilePic} /> : `${item.userDetail[0].firstName.charAt(0).toUpperCase()}${item.userDetail[0].lastName.charAt(0).toUpperCase()}`}
                                                  </figure>
                                                  <figcaption>
                                                    <h5>{`${item.userDetail[0].firstName} ${item.userDetail[0].lastName}`}</h5>
                                                    {commentId === item._id ?
                                                      <span>
                                                        <input
                                                          type='file'
                                                          id='Image'
                                                          onChange={(e) => onCommentFileHandler(e, data._id)}
                                                          onClick={e => e.target.value = null}
                                                          style={{ display: 'none' }}
                                                        />
                                                        <label htmlFor='Image'>
                                                          <i className="fas fa-camera mr-2"></i>
                                                        </label>
                                                        <input
                                                          type="text"
                                                          name="updateComment"
                                                          value={updateComment}
                                                          onChange={handleInputChange}
                                                        /> </span> : <span> <p>{item.comment}</p></span>}
                                                    {commentImages && commentId === item._id ?
                                                      <span><i className="pl-2 far fa-times-circle" onClick={() => removeCommentData('img')}></i>

                                                        <img style={{ height: '100px' }} src={commentImages} />
                                                      </span>
                                                      : item.images && commentId !== item._id ?
                                                        <img style={{ height: '100px' }} src={item.images && item.images} />
                                                        : ''
                                                    }
                                                    {commentVideo && commentId === item._id ?
                                                      <span> <i className="pl-2 far fa-times-circle" onClick={() => removeCommentData('video')}></i>
                                                        <Player fluid={false} height={100} width={200}>
                                                          <source src={commentVideo} />
                                                          <BigPlayButton position="center" />
                                                        </Player></span>
                                                      : item.video && commentId !== item._id ?
                                                        <span>
                                                          <Player fluid={false} height={100} width={200}>
                                                            <source src={item.video && item.video} />
                                                            <BigPlayButton position="center" />
                                                          </Player></span>
                                                        : ''}
                                                  </figcaption>
                                                  <div style={{ padding: "10px 0 0 5px " }}>

                                                    {profileData._id === item.userId &&
                                                      <Dropdown alignRight className="FilterCardDropdown PostDots" style={{ position: 'relative', top: '0px' }}>
                                                        {type !== 'edit' ?<Dropdown.Toggle id="dropdown-commrt">
                                                          <i className="fas fa-ellipsis-h"></i>
                                                        </Dropdown.Toggle> :''}
                                                        <Dropdown.Menu>
                                                          <React.Fragment>
                                                            <Dropdown.Item className="FilterCardDropdownItem"
                                                              onClick={() => updatePostComment(item, item._id, 'edit')}
                                                            ><i className="fas fa-edit postMenu"> </i>
                                                              <a>Edit comment</a>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item className="FilterCardDropdownItem" onClick={() => deleteUserComment(item._id, item.postId)}>
                                                              <i className="far fa-trash-alt postMenu"> </i>
                                                              <a >Delete comment</a>
                                                            </Dropdown.Item>
                                                          </React.Fragment>
                                                        </Dropdown.Menu>
                                                      </Dropdown>
                                                    }
                                                    {commentId === item._id ? <span className='d-flex'>{((updateComment !== '') || (commentVideo !== '' || commentImages !== '')) && <i className="far fa-check-circle" onClick={() => saveComment(item.postId)}></i>}<i className="pl-2 far fa-times-circle" onClick={cancelEditComment}></i></span> : ''}
                                                  </div>
                                                </div>
                                                <div className="commentLikeIcon">                                                                                                 
                                                <span className='timeSpan'>{`${moment(item.createdAt).fromNow(true)} ago`}</span>
                                                {item.likedStatus ? <img src={Like} onClick={()=>handleCommentLike(item._id,item.postId,3)} />:<img src={Icon4} onClick={()=>handleCommentLike(item._id,item.postId,1)} />}
                                                <p>{item.likeCount}</p>
                                                 {item.unlikedStatus ? <img src={Dislike} onClick={()=>handleCommentLike(item._id,item.postId,3)} /> :<img src={Icon6} onClick={()=>handleCommentLike(item._id,item.postId,2)} />}
                                                 <p>{item.unlikeCount}</p>
                                                 </div>
                                              </li>
                                            </ul>
                                          </div>}
                                      </React.Fragment>
                                    )
                                  }) : ''
                                    // <React.Fragment>
                                    // {data._id === selectedId && <CustomLoader loading={postCommentLoader} />}
                                    // </React.Fragment>
                                  }
                                </div>
                                <div className="CommentPost" onClick={() => updateState({ ...iState, commentModal: true, commentIndex: i, postId: data._id })}>
                                  <figure>
                                    {(profileData && profileData.firstName) && profileData.profilePic ? <img src={profileData.profilePic} /> : profileData.firstName ? `${profileData.firstName.charAt(0).toUpperCase()}${profileData.lastName.charAt(0).toUpperCase()}` : ''}
                                  </figure>
                                  <input type="text" disabled placeholder="Leave your thoughts here...." />
                                </div>
                              </div>
                            </div>
                          )) :
                            <CustomLoader loading={vendorTalkLoader} />
                          }
                        </InfiniteScroll>
                      </div>
                    </div>

                    <div className="col-sm-3 BottomSticky" id={scrollFlag? 'peopleKnowBox' :''}>
                      <div className="TalkRight">
                        <div className="Search">
                        <form>
                            <span>
                              <i className="fa fa-search" style={{cursor:'pointer'}} onClick={(e)=>handleSearchUser(e,'search')}></i>
                            </span>
                            <input type="search" name="queries" value={queries} onChange={(e)=>handleInputChange(e)}  placeholder="Search" />
                            <span><i className="fa fa-times" style={{cursor:'pointer'}} onClick={(e)=>handleSearchUser(e, 'remove')}></i></span>
                          </form>
                        </div>
                        {searchBuddies && searchBuddies.length > 0 &&
                        <div className="PeopleBox" >
                          <h4>Searched User</h4>

                          {searchBuddies && searchBuddies.length > 0 && searchBuddies.map((item, i) => (
                            <ul>
                             <li>
                              <Link to="/street-talk-profile" onClick={() => userPostDetail(item._id)}>
                                <figure>
                                  {item.profilePic ? <img src={item.profilePic} /> : `${item.firstName.charAt(0).toUpperCase()}${item.lastName.charAt(0).toUpperCase()}`}
                                </figure>
                                </Link>
                                
                                <figcaption>
                                <Link to="/street-talk-profile" onClick={() => userPostDetail(item._id)}>
                                  <h5 className="searchUser">{`${item.firstName} ${item.lastName}`}</h5>
                                  </Link>
                                  <h6>{item.uniqueId}</h6>
                                </figcaption>
                              </li>
                            </ul>
                          ))}
                        </div>}

                        {majorNewsData && majorNewsData.length > 0 &&
                          <div className="NewsBox">
                            <h4>StreetTak Major News</h4>
                            <article onClick={() => particularPostDetail(majorNewsData[0]._id)}>
                              <aside>
                                {majorNewsData[0].images[0] ? <img src={majorNewsData[0].images[0]} /> :<h6>{majorNewsData[0].caption}</h6>}
                              </aside>
                              <figure>
                                {majorNewsData[0].usersDetail[0].profilePic ? <img src={majorNewsData[0].usersDetail[0] && majorNewsData[0].usersDetail[0].profilePic} /> :
                                  `${majorNewsData[0].usersDetail[0].firstName.charAt(0).toUpperCase()}${majorNewsData[0].usersDetail[0].lastName.charAt(0).toUpperCase()}`}
                              </figure>
                              <figcaption>
                                <h5>{`${majorNewsData[0].usersDetail[0].firstName} ${majorNewsData[0].usersDetail[0].lastName}`}</h5>
                                {/* <p>@GameHuntress</p> */}
                              </figcaption>
                            </article>
                          </div>}
                          <div className="NewsBox NewsFeed">
                            <h4>News</h4>
                            {streetTakNewsData && streetTakNewsData.Data && streetTakNewsData.Data.length > 0 ?  streetTakNewsData.Data.map((item,i)=>(
                            <article onClick={()=>handlePreviewUrl(item.url)}>
                              <aside>
                                <img src={item.urlToImage} />
                              </aside>
                              <figcaption>
                                <h5>{item.title}</h5>
                              </figcaption>
                            </article>
                          )):''}
                          </div>

                        <div className="PeopleBox">
                          <h4>People You Might Know</h4>

                          {peopleMayKnowData && peopleMayKnowData.length > 0 && peopleMayKnowData.map((item, i) => (
                            <ul>
                              <li>
                                <figure>
                                  {item.profilePic ? <img src={item.profilePic} /> : `${item.firstName.charAt(0).toUpperCase()}${item.lastName.charAt(0).toUpperCase()}`}
                                </figure>
                                <figcaption>
                                  <h5>{`${item.firstName} ${item.lastName}`}</h5>
                                  <h6>{item.uniqueId}</h6>
                                </figcaption>
                                <a className="add-friend">
                                  <i className="fa fa-plus" onClick={(e) => handleRequest(e, item._id, 'follow')}></i>
                                </a>
                              </li>
                            </ul>
                          ))}
                        </div>
                     
                       
                      </div>
                    </div>
                      
                    <div className="clear"></div>
                    
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
        <div className="ModalBox">
          <Modal id="chooseUserModal" show={chooseUserModal} onHide={handleChooseUserModal}>
            <div className="ReactionBox">
              <div className="ReactionHead">
                <h4>Select User</h4>
                <button className='doneChecked' onClick={handleChooseUserModal}>Done</button>
              </div>
              <div className="ReactionBody">
                <ul>
                  {(allBuddiesData && allBuddiesData.Data && allBuddiesData.Data.length > 0) ? allBuddiesData.Data.map((item, i) => (
                    <li>
                      <figure>
                        {item.usersDetail[0].profilePic ? <img src={item.usersDetail[0].profilePic} /> : `${item.usersDetail[0].firstName.charAt(0).toUpperCase()}${item.usersDetail[0].lastName.charAt(0).toUpperCase()}`}
                        {/* <span>
                          <img src={Icon1} />
                        </span> */}
                      </figure>
                      <figcaption>
                        <div className="checkBox">
                          <h5>{`${item.usersDetail[0].firstName} ${item.usersDetail[0].lastName}`}</h5>
                          <input type="checkbox" name={`${item.usersDetail[0]._id} selectall`} id={i} value={item.usersDetail[0]._id} checked={toshow.length > 0 && toshow.filter((id) => id === item.usersDetail[0]._id).length ? true : false} onChange={(e) => handleChecked(e, item.usersDetail[0]._id)} className="regular-checkbox" />
                        </div>
                        {/* <h6>Noida</h6> */}
                      </figcaption>
                    </li>
                  )) : ''}
                </ul>
              </div>
            </div>
          </Modal>
        </div>

        <div className="ModalBox">
          <Modal id="ReactionModal" show={show} onHide={handleClose}>
            <div className="ReactionBox">
              <div className="ReactionHead">
                <h4>{modalType === 'blast' ? 'Blast Users' : modalType === 'like' ? 'Liked Users' : modalType === 'unlike' ? 'Unliked Users' : ''}</h4>
              </div>
              <div className="ReactionBody">
                <ul>
                  {(blastUserData && blastUserData[0] && blastUserData[0].userDetail.length > 0) ? blastUserData[0].userDetail.map((item, i) => (
                    <li>
                      <figure>
                        {item.profilePic ? <img src={item.profilePic} /> : `${item.firstName.charAt(0).toUpperCase()}${item.lastName.charAt(0).toUpperCase()}`}

                        <span>
                          <img src={Icon1} />
                        </span>
                      </figure>
                      <figcaption>
                        <h5>{`${item.firstName} ${item.lastName}`}</h5>
                        <h6>Noida</h6>
                      </figcaption>
                    </li>
                  ))
                    :
                    <React.Fragment>
                      {blastLoader ? <CustomLoader loading={blastLoader} /> : ''}
                    </React.Fragment>
                  }
                  {(likedUserData && likedUserData[0] && likedUserData[0].userDetail.length > 0) ? likedUserData[0].userDetail.map((item, i) => (
                    <li>
                      <figure>
                        {item.profilePic ? <img src={item.profilePic} /> : `${item.firstName.charAt(0).toUpperCase()}${item.lastName.charAt(0).toUpperCase()}`}
                        <span>
                          <img src={Icon4} />
                        </span>
                      </figure>
                      <figcaption>
                        <h5>{`${item.firstName} ${item.lastName}`}</h5>
                        {/* <h6>Noida</h6> */}
                      </figcaption>
                    </li>
                  ))
                    :
                    <React.Fragment>
                      {likedLoader ? <CustomLoader loading={likedLoader} /> : ''}
                    </React.Fragment>
                  }
                  {(unlikedUserData && unlikedUserData[0] && unlikedUserData[0].userDetail.length > 0) ? unlikedUserData[0].userDetail.map((item, i) => (
                    <li>
                      <figure>
                        {item.profilePic ? <img src={item.profilePic} /> : `${item.firstName.charAt(0).toUpperCase()}${item.lastName.charAt(0).toUpperCase()}`}
                        <span>
                          <img src={Icon6} />
                        </span>
                      </figure>
                      <figcaption>
                        <h5>{`${item.firstName} ${item.lastName}`}</h5>
                        {/* <h6>Noida</h6> */}
                      </figcaption>
                    </li>
                  ))
                    :
                    <React.Fragment>
                      {unlikedLoader ? <CustomLoader loading={unlikedLoader} /> : ''}
                    </React.Fragment>
                  }
                </ul>
              </div>
            </div>
          </Modal>

          <Modal id="ReportModal" show={showReport} onHide={closeReportPost} >
            <div className="ReportBox">
              <h3>Report</h3>
              <h4>Tell us why are you reporting this post?</h4>

              <div className="Report">
                <ul>
                  <li>
                    <input type="radio" name="reported_topic" value="Scam" onChange={handleInputChange} />
                    <span>Scam</span>
                  </li>
                  <li>
                    <input type="radio" name="reported_topic" value="Terrosim" onChange={handleInputChange} />
                    <span>Terrosim</span>
                  </li>
                  <li>
                    <input type="radio" name="reported_topic" value="Abusive" onChange={handleInputChange} />
                    <span>Abusive</span>
                  </li>
                  <li>
                    <input type="radio" name="reported_topic" value="Adult Content" onChange={handleInputChange} />
                    <span>Adult Content</span>
                  </li>
                  <li>
                    <input type="radio" name="reported_topic" value="Something else" onChange={handleInputChange} />
                    <span>Something else</span>
                  </li>
                  <li>
                    <input type="radio" name="reported_topic" value="Others" onChange={handleInputChange} />
                    <span>Others</span>
                  </li>
                </ul>

                <textarea
                  rows="5" placeholder="Add remarks" name='remark' value={remark} onChange={handleInputChange} />


                <aside>
                  <button data-dismiss="modal" onClick={closeReportPost} >Cancel</button>
                  <button data-dismiss="modal" onClick={submitReportPost}>Submit</button>
                </aside>
              </div>

            </div>
          </Modal>
        </div>
        <div className="ModalBox">
          <Modal id="userTypeModal" show={userTypeShow} onHide={handleTypeShow}>
            <div className="ReactionBox">
              <div className="ReactionHead">
                <h4>You Want To Post As User Or Vendor</h4>
              </div>
              <div className="ReactionBody">
                <div>
                  <button onClick={() => handleUserType('user')}>User</button>
                  <button onClick={() => handleUserType('vendor')}>Vendor</button>
                </div>
              </div>
            </div>
          </Modal>
        </div>

        <div className="ModalBox">
          <Modal id="unFollowModal" show={unfollowModal} onHide={handleunfollowModal}>
            <div className="ReactionBox">
              <div className="ReactionHead">
                <h4>Are you sure you Want to Unfollow?</h4>
              </div>
              <div className="ReactionBody">
                <div>
                  <button onClick={() => handleunfollowModal('cancel')}>Cancel</button>
                  <button onClick={() => handleunfollowModal('yes')}>Yes</button>
                </div>
              </div>
            </div>
          </Modal>
        </div>

        <div className="ModalBox">
          <Modal id="doneReportPost" show={doneReport} onHide={hideDoneReport}>
            <div className="ReactionBox">
              <div className="ReactionHead">
                <h3>Thanks</h3>
              </div>
              <div className="ReactionBody" style={{ padding: '23px' }}>
                {/* <div> */}
                <h6>We appreciate you letting us know. Your feedback help StreetTak a better community</h6>
                <button style={{ marginLeft: '160px' }} onClick={hideDoneReport}>Done</button>
                {/* </div> */}
              </div>
            </div>
          </Modal>
          {type == 'share' ?
            <Modal id="createPost" show={createPostModal} onHide={hideCreatePostModal}>
              <div className="ModalPost">
                <a className="Close" data-dismiss="modal" onClick={hideCreatePostModal}>&times;</a>
                <h2>Share Post</h2>
                <div className="ModalPostHead">
                  <figure>

                    {/* { profileData && profileData.profilePic ? <img src={profileData.profilePic} /> : `${profileData.firstName.charAt(0)}${profileData.lastName.charAt(0)}`} */}

                    {(profileData && profileData.firstName) && profileData.profilePic ? <img src={profileData.profilePic} /> : profileData.firstName ? `${profileData.firstName.charAt(0).toUpperCase()}${profileData.lastName.charAt(0).toUpperCase()}` : ''}
                  </figure>
                  <h3>{`${profileData.firstName} ${profileData.lastName}`}</h3>
                  <select name="showPost" value={showPost} onChange={handleInputChange}>
                    <option value={1}>Public</option>
                    <option value={2}>Private</option>
                  </select>
                </div>
                <textarea
                  rows="3"
                  className="form-control"
                  name="newCaption"
                  value={newCaption}
                  onChange={handleInputChange}
                  autoComplete="off"
                  placeholder="What is Happening Now">
                </textarea>
                <p>{caption}</p>
                <figcaption>
                  <ul>
                    <li>
                      <div className="FeedImages">
                        {imageReview && imageReview.length > 0 && imageReview.map((img, i) => {
                          return (
                            <React.Fragment>
                              {i <= 2 && <span className="Images"> <img src={img} /></span>}
                              {imageReview.length === 4 && i === 3 && <span className="Images"><img src={img} /></span>}

                            </React.Fragment>
                          )
                        })}
                        {imageReview && imageReview.length > 4 && <span className="Images" style={{ cursor: 'pointer' }}>
                          <img src={imageReview[imageReview.length - 1]} />
                          <span className="Count">+{imageReview.length - 4}</span></span>}

                        {videoReview && videoReview.length > 0 && videoReview.map((video, i) => {
                          return (
                            <React.Fragment>
                              {i <= 2 && <span className="Images">

                                <Player>
                                  <source className='video' src={video} />
                                </Player></span>}
                              {videoReview.length === 4 && i === 3 && <span className="Images">

                                <Player>
                                  <source className='video' src={video} />
                                </Player></span>}

                            </React.Fragment>
                          )
                        })}
                        {videoReview && videoReview.length > 4 && <span className="Images" style={{ cursor: 'pointer' }}>

                          <Player autoPlay muted>
                            <source className='video' src={videoReview[videoReview.length - 1]} />
                          </Player>
                          <span className="Count">+{videoReview.length - 4}</span></span>}
                      </div>
                    </li>
                  </ul>
                </figcaption>
                <button onClick={(e) => submitPost(e, 0)}>Share Post</button>
              </div>
            </Modal>
            :
            <Modal id="createPost" show={createPostModal} onHide={hideCreatePostModal}>
              <div className="ModalPost">
                <a className="Close" data-dismiss="modal" onClick={hideCreatePostModal}>&times;</a>
                <h2>{type === 'edit' ? 'Edit Post': 'Create Post'}</h2>
                <div className="ModalPostHead">
                  <figure>

                    {/* { profileData && profileData.profilePic ? <img src={profileData.profilePic} /> : `${profileData.firstName.charAt(0)}${profileData.lastName.charAt(0)}`} */}

                    {(profileData && profileData.firstName) && profileData.profilePic ? <img src={profileData.profilePic} /> : profileData.firstName ? `${profileData.firstName.charAt(0).toUpperCase()}${profileData.lastName.charAt(0).toUpperCase()}` : ''}
                  </figure>
                  <h3>{`${profileData.firstName} ${profileData.lastName}`}</h3>
                  <select name="showPost" value={showPost} onChange={handleInputChange}>
                    <option value={1}>Public</option>
                    <option value={2}>Private</option>
                    <option value={3}>Choose</option>
                  </select>
                </div>
                {multiplePosts && multiplePosts.length > 0 ? multiplePosts.map((item,i)=>(
                <div className="ModalPostHead">
                  <figure>
                    {(profileData && profileData.firstName) && profileData.profilePic ? <img src={profileData.profilePic} /> : profileData.firstName ? `${profileData.firstName.charAt(0).toUpperCase()}${profileData.lastName.charAt(0).toUpperCase()}` : ''}
                  </figure>
                  {/* <h3>{`${profileData.firstName} ${profileData.lastName}`}</h3> */}
                 
                     <h6>{item.caption}</h6>
                        <div className="FeedImages">
                        {item.images && item.images.length > 0 && item.images.map((img, i) => {
                          return (
                            <React.Fragment>
                              {i <= 2 && <span className="Images">
                                {/* <i className="far fa-times-circle deleteImg" onClick={() => deleteImage(i)}></i>  */}
                                <img src={img} /></span>}
                              {item.images.length === 4 && i === 3 && <span className="Images">
                                {/* <i className="far fa-times-circle deleteImg" onClick={() => deleteImage(i)}></i> */}
                                <img src={img} /></span>}

                            </React.Fragment>
                          )
                        })}
                        {item.images && item.images.length > 4 && <span className="Images" style={{ cursor: 'pointer' }}>
                          <img src={item.images[item.images.length - 1]} />
                          <span className="Count">+{item.images.length - 4}</span></span>}

                        {item.video && item.video.length > 0 && item.video.map((video, i) => {
                          return (
                            <React.Fragment>
                              {i <= 2 && <span className="Images">
                                {/* <i className="far fa-times-circle deleteVideo" onClick={() => deleteVideo(i, 'new')}></i> */}
                                <Player autoPlay muted>
                                  <source className='video' src={video} />
                                </Player></span>}
                              {item.video.length === 4 && i === 3 && <span className="Images">
                                {/* <i className="far fa-times-circle deleteVideo" onClick={() => deleteVideo(i, 'new')}></i> */}
                                <Player autoPlay muted>
                                  <source className='video' src={video} />
                                </Player></span>}

                            </React.Fragment>
                          )
                        })}
                        {item.video && item.video.length > 4 && <span className="Images" style={{ cursor: 'pointer' }}>

                          <Player autoPlay muted>
                            <source className='video' src={item.video[item.video.length - 1]} />
                          </Player>
                          <span className="Count">+{item.video.length - 4}</span></span>}
                      </div>
                  
                </div>
                )):''}

                <textarea
                  rows="3"
                  className="form-control"
                  name="caption"
                  value={caption}
                  onChange={handleInputChange}
                  autoComplete="off"
                  placeholder="What is Happening Now">
                </textarea>
                <figcaption>

                      <div>
                      {emojiFlag ? <Picker onEmojiClick={handleEmojiCaption} /> : ''}
                      <div className="FeedImages">
                        {imageReview && imageReview.length > 0 && imageReview.map((img, i) => {
                          return (
                            <React.Fragment>
                              {i <= 2 && <span className="Images"><i className="far fa-times-circle deleteImg" onClick={() => deleteImage(i)}></i> <img src={img} /></span>}
                              {imageReview.length === 4 && i === 3 && <span className="Images"><i className="far fa-times-circle deleteImg" onClick={() => deleteImage(i)}></i><img src={img} /></span>}

                            </React.Fragment>
                          )
                        })}
                        {imageReview && imageReview.length > 4 && <span className="Images" style={{ cursor: 'pointer' }}>
                          <img src={imageReview[imageReview.length - 1]} />
                          <span className="Count">+{imageReview.length - 4}</span></span>}

                        {videoReview && videoReview.length > 0 && videoReview.map((video, i) => {
                          return (
                            <React.Fragment>
                              {i <= 2 && <span className="Images">
                                <i className="far fa-times-circle deleteVideo" onClick={() => deleteVideo(i, 'new')}></i>
                                <Player autoPlay muted>
                                  <source className='video' src={video} />
                                </Player></span>}
                              {videoReview.length === 4 && i === 3 && <span className="Images">
                                <i className="far fa-times-circle deleteVideo" onClick={() => deleteVideo(i, 'new')}></i>
                                <Player autoPlay muted>
                                  <source className='video' src={video} />
                                </Player></span>}

                            </React.Fragment>
                          )
                        })}
                        {videoReview && videoReview.length > 4 && <span className="Images" style={{ cursor: 'pointer' }}>

                          <Player autoPlay muted>
                            <source className='video' src={videoReview[videoReview.length - 1]} />
                          </Player>
                          <span className="Count">+{videoReview.length - 4}</span></span>}
                      </div>
                      </div>


                  <ul>
                    <li>
                      <input
                        type='file'
                        id='profileImage'
                        onChange={onFileHandler}
                        onClick={e => e.target.value = null}
                        style={{ display: 'none' }}
                        multiple
                      />
                      <input
                        type='file'
                        id='video'
                        onChange={onVideoFileHandler}
                        onClick={e => e.target.value = null}
                        style={{ display: 'none' }}
                        multiple
                      />
                      

                      {video && video.length == 0 && oldVideo.length == 0 &&
                        <label htmlFor='profileImage'>
                          <i className="fa fa-picture-o"></i>
                        </label>}
                    </li>
                    <li>
                      <div className="FeedImages">
                      </div>

                      {images && images.length == 0 && oldImage.length == 0 &&
                        <label htmlFor='video'>
                          <i className="fa fa-video-camera"></i>
                        </label>}
                    </li>
                    <li><i className="fas fa-grin " style={{ color: '#F8914E',fontSize:'20px',padding:'5px' }} onClick={() => handleEmojiPopup()}></i></li>
                    <li>
                      {(caption !== '' || images.length !== 0 || video.length !== 0) && type !== 'edit'  ? <img style={{ width: '30px',height:'28px' ,marginLeft:'270px', cursor:'pointer'}} src={addNew} onClick={() => addPost()} /> :''}
                  </li>
                  </ul>
                </figcaption>
                {type === 'new' ? <button disabled={caption === '' && images.length === 0 && video.length === 0 && multiplePosts.length === 0} onClick={(e) => submitPost(e)}>Post</button>
                  :
                  <button disabled={(caption === '' && images.length == 0 && video.length == 0 && oldImage.length == 0 && oldVideo.length == 0) || updatePostLoader} onClick={saveUpdatePost}>Save</button>}

              </div>
            </Modal>
          }
          <Modal id="MultipleModal" show={imageViewModal} onHide={hideimageViewModal}>
            {particularPostData && particularPostData[0] && Object.keys(particularPostData).length > 0 ?
              <div className="MultipleBox">
                <div className="MultipleLeft">
                  <a className="Close" data-dismiss="modal" onClick={hideimageViewModal}>&times;</a>

                  <Carousel>
                    {particularPostData[0] && particularPostData[0].images && particularPostData[0].images.length > 0 && particularPostData[0].images.map((img, i) => (
                      <div>
                        <figure><img src={img} /></figure>
                      </div>
                    ))}
                  </Carousel>
                  <div className="LikeLine">
                    <ul>
                      <li>
                        <a title={particularPostData[0].blastStatus  ? 'Blasted' : 'Blast'}>
                          {particularPostData[0].blastStatus ? <img src={Blast} /> :
                            <img src={Icon1} />}
                          <span onClick={() => postLikedUsers(particularPostData[0]._id, 'blast')}>{particularPostData[0].blastCount}</span>
                        </a>
                      </li>
                      <li>
                        <a title='Comments'>
                          <img src={Icon2} onClick={() => updateState({ ...iState, commentModal: true, commentIndex: 0, postId: particularPostData[0]._id })} />
                          <span>{particularPostData[0].numberOfComments}</span>
                        </a>
                      </li>
                      <li>
                        <a title='Like'>
                          {particularPostData[0].likedStatus  ? <img src={Like} onClick={() => postLke(particularPostData[0]._id, 3)} /> :
                            <img src={Icon4} onClick={() => postLke(particularPostData[0]._id, 1)} />}
                          <span onClick={() => postLikedUsers(particularPostData[0]._id, 'like')}>{particularPostData[0].likeCount}</span>
                        </a>
                      </li>
                      <li>
                        <a title='Dislike'>
                          {particularPostData[0].unlikedStatus  ? <img src={Dislike} onClick={() => postLke(particularPostData[0]._id, 3)} /> :
                            <img src={Icon6} onClick={() => postLke(particularPostData[0]._id, 2)} />}
                          <span onClick={() => postLikedUsers(particularPostData[0]._id, 'unlike')}>{particularPostData[0].unlikeCount}</span>
                        </a>
                      </li>
                      <li>
                        <a title='Share'>
                          <img src={Icon5} />
                        </a>
                      </li>
                    </ul>
                  </div>


                </div>

                <div className="MultipleRight">
                  <div className="PostBox">
                    <div className="PostIcon">
                      {particularPostData[0].usersDetail[0].profilePic ? <img src={particularPostData[0].usersDetail[0].profilePic} /> : `${particularPostData[0].usersDetail[0].firstName.charAt(0).toUpperCase()}${particularPostData[0].usersDetail[0].lastName.charAt(0).toUpperCase()}`}
                    </div>
                    <div className="PostRight">
                      <div className="PostContent">
                        <h4><a>{`${particularPostData[0].usersDetail[0].firstName} ${particularPostData[0].usersDetail[0].lastName}`}</a><span className="Time">{`${moment(modalImageData.createdAt).fromNow(true)} ago`}</span></h4>
                        <p style={{ height: '85px' }}>{particularPostData[0].caption ? particularPostData[0].caption : ''}</p>
                      </div>
                    </div>
                  </div>

                  <div className="LikeLine">
                    <ul>
                      <li>
                        <a title={particularPostData[0].blastStatus  ? 'Blasted' : 'Blast'}>
                          {particularPostData[0].blastStatus  ? <img src={Blast} /> :
                            <img src={Icon1} />}
                          <span onClick={() => postLikedUsers(particularPostData[0]._id, 'blast')}>{particularPostData[0].blastCount}</span>
                        </a>
                      </li>
                      <li>
                        <a title='Comments'>
                          <img src={Icon2} onClick={() => updateState({ ...iState, commentModal: true, commentIndex: 0, postId: particularPostData[0]._id })} />
                          <span>{particularPostData[0].numberOfComments}</span>
                        </a>
                      </li>
                      <li>
                        <a title='Like'>
                          {particularPostData[0].likedStatus  ? <img src={Like} onClick={() => postLke(particularPostData[0]._id, 3)} /> :
                            <img src={Icon4} onClick={() => postLke(particularPostData[0]._id, 1)} />}
                          <span onClick={() => postLikedUsers(particularPostData[0]._id, 'like')}>{particularPostData[0].likeCount}</span>
                        </a>
                      </li>
                      <li>
                        <a title='Dislike'>
                          {particularPostData[0].unlikedStatus ? <img src={Dislike} onClick={() => postLke(particularPostData[0]._id, 3)} /> :
                            <img src={Icon6} onClick={() => postLke(particularPostData[0]._id, 2)} />}
                          <span onClick={() => postLikedUsers(particularPostData[0]._id, 'unlike')}>{particularPostData[0].unlikeCount}</span>
                        </a>
                      </li>
                      <li>
                        <a title='Share'>
                          <img src={Icon5} />
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="MultipleComment">
                    <ul>
                      {(postCommentData && postCommentData.length > 0) ? postCommentData.map((item, key) => {
                        return (
                          <React.Fragment>
                            <li>
                              <figure>
                                {item.userDetail[0].profilePic ? <img src={item.userDetail[0].profilePic} /> : `${item.userDetail[0].firstName.charAt(0).toUpperCase()}${item.userDetail[0].lastName.charAt(0).toUpperCase()}`}
                              </figure>
                              <figcaption>
                                <h4>{`${item.userDetail[0].firstName} ${item.userDetail[0].lastName}`}</h4>
                                <p>{item.comment}</p>
                              </figcaption>
                            </li>
                          </React.Fragment>
                        )
                      }) : ''}
                    </ul>
                  </div>
                </div>
              </div>
             :'' }
          </Modal>


          <Modal className="modal" id="ReplyModal" show={commentModal} onHide={hidecommentModal}>
            <div className="modal-content">
              <div className="ReplyComment">
                <h6>Comments</h6>
                <a onClick={hidecommentModal} className="Close" data-dismiss="modal">&times;</a>
                <h2>&nbsp;</h2>

                <div className="ReplyCommentHead">
                  {multipleComents && multipleComents.length > 0 ? multipleComents.map((item, i) => (
                    <ul>
                      <li>
                        <figure>
                          {(profileData && profileData.firstName) && profileData.profilePic ? <img src={profileData.profilePic} /> : profileData.firstName ? `${profileData.firstName.charAt(0).toUpperCase()}${profileData.lastName.charAt(0).toUpperCase()}` : ''}
                        </figure>
                        <figcaption>
                          <h3>{profileData && profileData.firstName ? `${profileData.firstName} ${profileData.lastName}` : ''}</h3>
                          {item.comment ? <h5>{item.comment}</h5> : ''}
                          {item.images ? <img style={{ height: '100px' }} src={item.images} /> :
                            item.video ? <Player autoPlay muted fluid={false} height={100} width={200}>
                              <source src={item.video} />
                              <BigPlayButton position="center" />
                            </Player> : ''
                          }
                        </figcaption>
                      </li>
                    </ul>
                  ))
                    : ''}

                </div>

                <div className="ReplyCommentBody">
                  <figure>
                    {(profileData && profileData.firstName) && profileData.profilePic ? <img src={profileData.profilePic} /> : profileData.firstName ? `${profileData.firstName.charAt(0).toUpperCase()}${profileData.lastName.charAt(0).toUpperCase()}` : ''}
                  </figure>
                    
                  {(Object.keys(commentImages).length > 0 || Object.keys(commentImages).length > 0) ?

                    <span>
                      {<textarea rows="5" className="form-control" name="comment" value={comment} onChange={handleInputChange} placeholder="Add a comment"></textarea>}
                      <div>
                        {commentImages && (particularPostId === commentIndex) && type !== 'edit' &&
                          <div><i className="pl-2 far fa-times-circle" onClick={() => removeCommentData('img')}></i>
                            <img style={{ height: '100px' }} src={commentImages} />
                          </div>}
                        {commentVideo && (particularPostId === commentIndex) && type !== 'edit' &&
                          <div> <i className="pl-2 far fa-times-circle" onClick={() => removeCommentData('video')}></i>
                            <Player autoPlay muted fluid={false} height={100} width={200}>
                              <source src={commentVideo} />
                              <BigPlayButton position="center" />
                            </Player></div>}
                      </div>
                    </span>
                    :
                    <div className="EmojiBox">
                      <textarea rows="5" className="form-control" name="comment" value={comment} onChange={handleInputChange} placeholder="Add a comment"></textarea>
                      {emojiFlag ? <Picker onEmojiClick={handleEmojiChange} /> : ''}
                    </div>
                  }
                </div>
                <div className="ReplyCommentFooter">
                  <input
                    type='file'
                    id='multipleImage1'
                    onChange={(e) => onCommentFileHandler(e)}
                    onClick={e => e.target.value = null}
                    style={{ display: 'none' }}
                  />
                  <ul>
                    <li>
                      <a><label htmlFor='multipleImage1'><i className="fa fa-picture-o" onClick={() => updatePostId(commentIndex)}></i></label></a>
                    </li>
                    <li>
                      <a><label htmlFor='multipleImage1'><i className="fa fa-video-camera" onClick={() => updatePostId(commentIndex)}></i></label></a>
                    </li>
                    <li>
                    <i className="fas fa-grin" style={{ color: '#F8914E',fontSize:'22px',padding:'4px 0' }} onClick={() => handleEmojiPopup()}></i>
                    </li>
                  </ul>
                   {comment !== '' || commentImages !== '' || commentVideo !== '' ? <img style={{ width: '30px' }} src={addNew} onClick={() => addComment()} /> : ''}
                  <button disabled={comment === '' && commentImages === '' && commentVideo === '' && multipleComents.length === 0} onClick={replyComment}>Reply</button>
                </div>
              </div>
            </div>
          </Modal>
          <Modal id="deleteModal" show={deleteModal} onHide={handledeleteModal}>
            <div className="ReactionBox">
              <div className="ReactionHead">
                <h4>Are you sure you want to Delete?(It cannot be undone and it will be gone from your profile)</h4>
              </div>
              <div className="ReactionBody">
                <div>
                  <button onClick={() => handledeleteModal('cancel')}>Cancel</button>
                  <button onClick={() => handledeleteModal('yes')}>Yes</button>
                </div>
              </div>
            </div>
          </Modal>
          <Modal id="shareModal" show={shareModal} onHide={handleShareModal}>
            <div className="ReactionBox">
              <div className="ReactionHead">
                <h4>Share</h4>
                <img className ="imgShare" src={Cancel} onClick={handleShareModal}/>
              </div>
              <div className="ReactionBody ReactionBody2">
              <ul>
                <li>
                  <WhatsappShareButton url={`https://www.streettak.com/#/${shareID}`} >
                  <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                  <p>WhatsApp</p>
                </li>
                <li>
                <TwitterShareButton url={`https://www.streettak.com/#/${shareID}`}  >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <p>Twitter</p>
                </li>
                <li>
                <FacebookShareButton url={`https://www.streettak.com/#/${shareID}`} >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <p>Facebook</p>
                </li>
                <li>
                <EmailShareButton url={`https://www.streettak.com/#/${shareID}`} >
                  <EmailIcon size={32} round />
                </EmailShareButton>
                <p>Email</p>
                </li>
                <li>
                <LinkedinShareButton url={`https://www.streettak.com/#/street-talk-details/${shareID}`} >
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
                <p>LinkedIn</p>
                </li>
                <li>
                <TelegramShareButton url={`https://www.streettak.com/#/street-talk-details/${shareID}`} >
                  <TelegramIcon size={32} round />
                </TelegramShareButton>
                <p>Telegram</p>
                </li>
              </ul>
              </div>
            </div>
          </Modal>

        </div>
        <Footer />
      </div>
    </Fragment>
  );
}

export default StreetTalk