import { buddiesAction } from '../actionTypes'
import {Url} from '../../config/config'
import axios from 'axios'
import {isLoggedIn, multiPartData} from '../../utils'

const token = isLoggedIn('userData')

export const getAllBudiesInitiate = () => ({
  type: buddiesAction.GET_ALL_BUDDIES_INITIATE
})

export const getAllBudiesSuccess = (data) => ({
  type: buddiesAction.GET_ALL_BUDDIES_SUCCESS,
  payload: data
})

export const getAllBudiesFailure = (data) => ({
  type: buddiesAction.GET_ALL_BUDDIES_FAILURE
})

export function getAllBuddies() {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(getAllBudiesInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/allBuddies`, '',{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(getAllBudiesSuccess(data))
                  }
                  else{
                    dispatch(getAllBudiesFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(getAllBudiesFailure())
                  reject(err);
              })
      );
  }
}

export const searchBudiesInitiate = () => ({
  type: buddiesAction.SEARCH_BUDDIES_INITIATE
})

export const searchBudiesSuccess = (data) => ({
  type: buddiesAction.SEARCH_BUDDIES_SUCCESS,
  payload: data
})

export const searchBudiesFailure = (data) => ({
  type: buddiesAction.SEARCH_BUDDIES_FAILURE
})

export function searchAllBuddies(payload) {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(searchBudiesInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/searchAllUsers`, payload,{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(searchBudiesSuccess(data))
                  }
                  else{
                    dispatch(searchBudiesFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(searchBudiesFailure())
                  reject(err);
              })
      );
  }
}

export const sendRequestBudiesInitiate = () => ({
  type: buddiesAction.SEND_REQUEST_BUDDIES_INITIATE
})

export const sendRequestBudiesSuccess = () => ({
  type: buddiesAction.SEND_REQUEST_BUDDIES_SUCCESS
})

export const sendRequestBudiesFailure = () => ({
  type: buddiesAction.SEND_REQUEST_BUDDIES_FAILURE
})

export function sendRequestBuddies(payload) {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(sendRequestBudiesInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/requestBuddies`, payload,{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(sendRequestBudiesSuccess(data))
                  }
                  else{
                    dispatch(sendRequestBudiesFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(sendRequestBudiesFailure())
                  reject(err);
              })
      );
  }
}

export const pendingBudiesInitiate = () => ({
  type: buddiesAction.PENDING_BUDDIES_INITIATE
})

export const pendingBudiesSuccess = (data) => ({
  type: buddiesAction.PENDING_BUDDIES_SUCCESS,
  payload: data
})

export const pendingBudiesFailure = () => ({
  type: buddiesAction.PENDING_BUDDIES_FAILURE
})

export function pendingListBuddies() {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(pendingBudiesInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/pendingBuddies`, '',{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(pendingBudiesSuccess(data))
                  }
                  else{
                    dispatch(pendingBudiesFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(pendingBudiesFailure())
                  reject(err);
              })
      );
  }
}

export const acceptPendingBudiesInitiate = () => ({
  type: buddiesAction.ACCEPT_PENDING_BUDDIES_INITIATE
})

export const acceptPendingBudiesSuccess = (data) => ({
  type: buddiesAction.ACCEPT_PENDING_BUDDIES_SUCCESS
})

export const acceptPendingBudiesFailure = () => ({
  type: buddiesAction.ACCEPT_PENDING_BUDDIES_FAILURE
})

export function acceptPendingBuddies(payload) {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(acceptPendingBudiesInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/acceptBuddies`, payload,{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(acceptPendingBudiesSuccess(data))
                  }
                  else{
                    dispatch(acceptPendingBudiesFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(acceptPendingBudiesFailure())
                  reject(err);
              })
      );
  }
}

export const deletePendingBudiesInitiate = () => ({
  type: buddiesAction.DELETE_PENDING_BUDDIES_INITIATE
})

export const deletePendingBudiesSuccess = (data) => ({
  type: buddiesAction.DELETE_PENDING_BUDDIES_SUCCESS
})

export const deletePendingBudiesFailure = () => ({
  type: buddiesAction.DELETE_PENDING_BUDDIES_FAILURE
})

export function deletePendingBuddies(payload) {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(deletePendingBudiesInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/deleteBuddiesRequest`, payload,{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(deletePendingBudiesSuccess(data))
                  }
                  else{
                    dispatch(deletePendingBudiesFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(deletePendingBudiesFailure())
                  reject(err);
              })
      );
  }
}

export const emptyBuddies = () => ({
  type: buddiesAction.EMPTY_BUDDIES_LIST
})


