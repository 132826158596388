import React, { useEffect } from 'react'
import '../App.css';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from "react-router-dom";

import Icon1 from "../assets/media/sidenav-1.png";
import Icon9 from "../assets/media/sidenav-9.png";
import Icon10 from "../assets/media/sidenav-10.png";
import Icon11 from "../assets/media/sidenav-11.png";
import language from "../assets/media/language.svg";
import flag from "../assets/media/flag.svg";
import logo from "../assets/media/gbLogo.png";
import heart from "../assets/media/heart.svg";
import cart from "../assets/media/cart.svg";
import shopping from "../assets/media/shopping.svg";
import streetTalk from "../assets/media/streettalk.svg"
import { useSelector } from 'react-redux';
import { isLoggedIn } from '../utils'
import { toast } from 'react-toastify';

import { logOut } from '../utils';
import { logoutUser } from '../redux/actions/loginAction'

const Header = () => {
	const history = useHistory();
	const dispatch = useDispatch()
	const profileData = JSON.parse(window.localStorage.getItem("userData"))
	const cartLength = JSON.parse(window.localStorage.getItem("cartLength"))
	const wishListLength = JSON.parse(window.localStorage.getItem("wishListLength"))

	const handleLogout = (e) => {
		e.preventDefault()
		dispatch(logoutUser()).then(res => {
			if (res.response_code == 200) {
				toast.success(res.message, {
					position: toast.POSITION.TOP_RIGHT
				});
				logOut("userData")
				logOut("signupData")
				logOut("userData")
				localStorage.clear()
				history.push("/")
			}
		}).catch(err => {
			const message = err && err.response && err.response.data ? err.response.data.message : "Something went handleSuit"
			toast.error(message, {
				position: toast.POSITION.TOP_RIGHT
			});
		})
	}

		const handleChangePassword = () => {
		history.push('/change-password')
	}
	const handleProfile = () => {
		history.push('/dashboard')
	}

	let path= window.location.href.split('/')

	const loginStatus = isLoggedIn('userData')
	return (
		<header>
			<div className="Downheader">
				<div className="container">
					<div className="Logo">
						{/* {	(loginStatus  && path.includes("street-talk-profile")) ?
									<Link to="/">
								<img src={streetTalk} className='' style={{ width: "115px" }} />
							</Link> : 
							loginStatus ? */}
							
								<Link to="/">
								<img src={streetTalk} className='' style={{ width: "115px" }} />
							</Link> 
							{/* } */}
					
					</div>
					<div className="Navi">
						<ul>
							{loginStatus ?

								<li>
									<div className="Avater">
										<a>
											<figure>
												{profileData && profileData.profilePic !== null && profileData.profilePic ? <img src={profileData.profilePic} /> :
													<div> {profileData && profileData.firstName && profileData.firstName && `${profileData.firstName.charAt(0)}${profileData.lastName.charAt(0).toUpperCase()}`} </div>}
											</figure>
											{profileData && profileData.firstName && profileData.firstName ? <span>{`Hi, ${profileData.firstName}`} </span>: ''}
										</a>
										<ul>
											<li><a onClick={handleProfile}><span className="Icon"><img src={Icon1} /></span> My Profile</a></li>
											<li><a onClick={handleChangePassword}><span className="Icon"><img src={Icon10} /></span> Change Password</a></li>
											<li><a onClick={handleLogout}><span className="Icon"><img src={Icon11} /></span> Logout</a></li>
										</ul>
									</div>
								</li> : ''}

							{!loginStatus ?
								<React.Fragment>
									<li className="Login">
										<Link to="/login">Login</Link>
									</li>
									<li className="Member">
										<Link to="/register">Become a Member</Link>
									</li>
								</React.Fragment> : ''
							}
						</ul>
					</div>
				</div>
			</div>
		</header>
	)
}
export default Header