import React from 'react'
import '../App.css'; 
import { Link } from "react-router-dom";  
import Collapse from 'react-bootstrap/Collapse'

import Banner from "../assets/media/faq.jpg";

import Header from './header';
import Footer from './footer';
                  
class faq extends React.Component {
  	render() { 
	    return <div>  
	    	
	    		<div className="Header">
		    		<Header/>
		    	</div> 

		        <div className="BannerArea">
		        	<img src={Banner} />
		        	<div className="BannerText">
			            <div className="container">
			                <h4><span>Frequently Asked</span> Questions </h4>
			            </div>
			        </div>
		        </div>
			     
 
			    <section>
			        <div className="FaqArea">
			            <div className="container">
			                <div id="accordion">
			                    
			                    <div className="card">
			                        <div className="card-header" data-toggle="collapse" href="#collapseOne" aria-expanded="true">
			                            <h4>Lorem ipsum dolor sit amet, consetetur sadipscing elitr ?</h4>
			                        </div>
			                        <div id="collapseOne" className="collapse show" data-parent="#accordion">
			                            <div className="card-body">
			                                <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis.</p>
			                            </div>
			                        </div>
			                    </div>                        

			                    <div className="card">
			                        <div className="card-header collapsed" data-toggle="collapse" href="#collapseTwo">
			                            <h4>Lorem ipsum dolor sit amet, consetetur sadipscing elitr ?</h4>
			                        </div>
			                        <div id="collapseTwo" className="collapse" data-parent="#accordion">
			                            <div className="card-body">
			                                <p>I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.</p>
			                            </div>
			                        </div>
			                    </div>

			                    <div className="card">
			                        <div className="card-header collapsed" data-toggle="collapse" href="#collapseThree">
			                            <h4>Lorem ipsum dolor sit amet, consetetur sadipscing elitr ?</h4>
			                        </div>
			                        <div id="collapseThree" className="collapse" data-parent="#accordion">
			                            <div className="card-body">
			                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci</p>
			                            </div>
			                        </div>
			                    </div>

			                    <div className="card">
			                        <div className="card-header collapsed" data-toggle="collapse" href="#collapseFour">
			                            <h4>Lorem ipsum dolor sit amet, consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetur sadipscing elitr Lorem ipsum dolor sit amet, consetetur sadipscing elitr</h4>
			                        </div>
			                        <div id="collapseFour" className="collapse" data-parent="#accordion">
			                            <div className="card-body">
			                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
			                            </div>
			                        </div>
			                    </div>

			                    <div className="card">
			                        <div className="card-header collapsed" data-toggle="collapse" href="#collapseFive">
			                            <h4>Lorem ipsum dolor sit amet, consetetur sadipscing elitr ?</h4>
			                        </div>
			                        <div id="collapseFive" className="collapse" data-parent="#accordion">
			                            <div className="card-body">
			                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary</p>
			                            </div>                
			                        </div>
			                    </div>

			                </div>

			            </div>
			        </div>
			    </section>

			<Footer/>
		</div>
  	}
}
export default faq