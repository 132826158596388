import React from 'react'
import Header from './header'
import Footer from './footer'
import GbComming from '../assets/media/coming.png'   
const CommingSoon =()=>{
  return(
<div>
			<div>
				<Header />
			</div>

      <div className="CommingArea">
        <img src={GbComming} />
      </div>

      <Footer />
    </div>
  )
}

export default CommingSoon
