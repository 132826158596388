import React from 'react'
import '../App.css'; 
import { Link } from "react-router-dom"; 

import Banner from "../assets/media/Size.jpg";  

import Header from './header';
import Footer from './footer';

class chart extends React.Component {
  	render() { 
	    return <div> 
	    	  <div className="Header">
                    <Header/>
                </div> 
				
				 
		        <div className="BannerArea">
		        	<img src={Banner} />
		        	<div className="BannerText">
			            <div className="container">
			                <h4><span>Size  </span> Guide</h4>
			            </div>
			        </div>
		        </div>
			    

			    <section>
                    <div className="SizeArea">
                        <div className="container">
                             
                            <div className="row">
                                <div className="col-sm-10 offset-sm-1">
                                    <div className="SizeBox">
                                        <h3>SIZE GUIDE :</h3>     
                                        <p>Please note this is for reference only. All measurements in this chart are body measurements in inches and not garment measurements.</p>

                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Size</th>
                                                    <th>UK</th>
                                                    <th>US</th>
                                                    <th>EU</th>
                                                    <th>Bust</th>
                                                    <th>Waist</th>
                                                    <th>Hips</th>
                                                </tr> 
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>XS</td>
                                                    <td>6</td>
                                                    <td>2</td>
                                                    <td>34</td>
                                                    <td>84</td>
                                                    <td>64</td>
                                                    <td>89</td>
                                                </tr>
                                                <tr>
                                                    <td>S</td>
                                                    <td>6</td>
                                                    <td>2</td>
                                                    <td>34</td>
                                                    <td>84</td>
                                                    <td>64</td>
                                                    <td>89</td>
                                                </tr>
                                                <tr>
                                                    <td>M</td>
                                                    <td>6</td>
                                                    <td>2</td>
                                                    <td>34</td>
                                                    <td>84</td>
                                                    <td>64</td>
                                                    <td>89</td>
                                                </tr>
                                                <tr>
                                                    <td>L</td>
                                                    <td>6</td>
                                                    <td>2</td>
                                                    <td>34</td>
                                                    <td>84</td>
                                                    <td>64</td>
                                                    <td>89</td>
                                                </tr>
                                                <tr>
                                                    <td>XL</td>
                                                    <td>6</td>
                                                    <td>2</td>
                                                    <td>34</td>
                                                    <td>84</td>
                                                    <td>64</td>
                                                    <td>89</td>
                                                </tr>
                                                <tr>
                                                    <td>2XL</td>
                                                    <td>6</td>
                                                    <td>2</td>
                                                    <td>34</td>
                                                    <td>84</td>
                                                    <td>64</td>
                                                    <td>89</td>
                                                </tr>
                                                <tr>
                                                    <td>3XL</td>
                                                    <td>6</td>
                                                    <td>2</td>
                                                    <td>34</td>
                                                    <td>84</td>
                                                    <td>64</td>
                                                    <td>89</td>
                                                </tr>
                                                <tr>
                                                    <td>4XL</td>
                                                    <td>6</td>
                                                    <td>2</td>
                                                    <td>34</td>
                                                    <td>84</td>
                                                    <td>64</td>
                                                    <td>89</td>
                                                </tr> 
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

			<Footer/>
		</div>
  	}
}
export default chart