import React,{useEffect,useState,useRef} from 'react'
import '../App.css'; 
import { Link, useHistory } from "react-router-dom"; 
import { isLoggedIn } from '../utils'
import StreetTakLogo from '../assets/media/StreetTakLogo.png'
import Biglogo from '../assets/media/Big-logo.png'
import ArrowIcon from '../assets/media/Arrow-Icon_1Arrow- Icon.png'
import streettakScreenShor from '../assets/media/streettak-screenshot.png'
import streettakUserprofile from '../assets/media/streettak-userprofile.png'
import streettakConnect from '../assets/media/streettak-connect.png'
import streeettakNews from '../assets/media/streeettak-news.png'
import streettakMarketplace from '../assets/media/streettak-marketplace.png'
import streettakFeatures from '../assets/media/streettak-features.png'
import streettakFeaturenews from '../assets/media/streettak-featurenews.png'
import streettakShare from '../assets/media/streettak-share.png'
import streettakEngage from '../assets/media/streettak-engage.png'
import streettakCommunity from '../assets/media/streettak-community.png'
import streettakBuy from '../assets/media/streettak-buy.png'
import StreetTakProfile from '../assets/media/StreetTak-profile.png'
import StreetTakProfile2 from '../assets/media/StreetTak-profile2.png'
          

const initialState ={
  scrollFlag:false,
  navMenuFlag: false
}

const AboutNew =()=> {
  const history = useHistory()
  const myRef = useRef(null);
  let scrollRef = useRef(0)
  const myFeatureRef = useRef(null);
  const loginStatus = isLoggedIn('userData')
  const [iState, updateState] = useState(initialState)
  const {scrollFlag,navMenuFlag} = iState

  const executeScroll = () => {myRef.current.scrollIntoView()
    updateState({
      ...iState,navMenuFlag:false
    })}
  const executeFeatureScroll = () => {myFeatureRef.current.scrollIntoView()
    updateState({
      ...iState,navMenuFlag:false
    })
}
  const handleStreetalk = () => {
    if (loginStatus) {
      history.push('/street-talk')
    } else {
      history.push('/street-talk-public')
    }
  }

  const handleFlag =()=> {
    if(!navMenuFlag) {
      updateState({
        ...iState,navMenuFlag:true
      })
    } else {
      updateState({
        ...iState,navMenuFlag:false
      })
    }
  }
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (scrollRef.current <= 125 && scrollFlag) {
        updateState({...iState,scrollFlag:false,})
      }
      if (scrollRef.current >= 125 && !scrollFlag) {
        updateState({...iState,scrollFlag:true,})
      }
      scrollRef.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollFlag]);
	    return (
      <div> 
		    
        <div data-animation="default" className={ scrollFlag ? "navbar w-nav headernew active":"navbar w-nav headernew"} data-easing2="ease-in-quad" data-easing="ease-in-quad" data-collapse="medium" data-w-id="860509fe-e33d-2178-c932-660930665508" role="banner" data-duration="400" data-doc-height="1">
        <div className="nav-block">
          <a href="javascript:void(0)" aria-current="page" className="brand w-nav-brand w--current">
            <img src={StreetTakLogo} onClick={() => history.push('/')} width="222" alt="" className="brand" />
          </a>
          <nav role="navigation" className={navMenuFlag ? "nav-menu w-nav-menu menuItem" :"nav-menu w-nav-menu"}>
            <a href="javascript:void(0)" onClick={() => history.push('/')} className="nav-link w-nav-link">How it works</a>
            <a href="javascript:void(0)" onClick={() => history.push('/')} className="nav-link w-nav-link">Features</a>
            <a href="javascript:void(0)" onClick={() => history.push('/about')} className="nav-link w-nav-link">About</a>
            <a href="javascript:void(0)"  className="nav-link w-nav-link"><img src={Biglogo} width="60" alt=""  /></a>
            {!loginStatus ? <a href="javascript:void(0)" onClick={() => history.push('/register')} className="nav-link w-nav-link">Become a Member</a> : ''}
          </nav>
          {!loginStatus ?
            <div className="link-arrow nav">
              <a href="javascript:void(0)" onClick={() => history.push('/login')} className="link-button ">Login</a>
              <img src={ArrowIcon} loading="lazy" alt="StreetTak learn more arrow" className="arrow" />
            </div> : ''}
          {/* {loginStatus ? */}
            <div className="menu-button w-nav-button" onClick={handleFlag}>
              <div className="menu-icon w-icon-nav-menu"></div>
            </div>
            {/* : ''} */}
        </div>
      </div>

      <div class="header-section inner-page wf-section">
    <div class="hero-block inner">
      <div class="hero-content inner">
        <h1 class="hero-title">About StreetTak</h1>
      </div>
    </div>
  </div>
  <div class="section wf-section">
    <div class="block-content">
      <div class="terms-ritch-text-block w-richtext">
        <h3>Our Vision</h3>
        <p><strong>To provide a safe online platform where users can express themselves freely in a feed and make use of the power of their followers to form a collective bargaining power to purchase products in GroupBuy. </strong></p>
        <p>The focus of StreetTak is to provide a platform where all people can participate in a public conversation and speak freely and express their freedom of speech in safety without the fear of being “deplatformed, censured or bullied” and we support initiatives that defend and respect all voices by promoting free expression and defending civil liberties and not to encourage any type of violence. </p>
        <p><br /></p>
        <h3>Our Mission</h3>
        <p>StreetTak is dedicated to enhancing the user’s experience by offering plenty of opportunities for users to engage with each other in an interesting and safe online social feed and e-commerce environment to converse civilly and discuss solutions to the world pressing problems and defend their values, passions, accomplishments, and ideas.</p>
        <p>We support equality and universal acceptance for all people and aim to provide a platform to participate in a public conversation in a safe environment where the distribution of misleading information is minimized, especially when its intent is to cause offline harm.</p>
        <p>‍</p>
        <p>‍</p>
        <h3>We Believe</h3>
        <p>In equality and universal acceptance for all people and that people are entitled to security, privacy, and freedom of expression.</p>
        <p>‍</p>
      </div>
    </div>
  
  
  
  
  </div>
      <div className="footer wf-section">
        <div className="footer-block">
          <div data-w-id="e8d13286-4f22-98bd-8338-9e174ec1fd5c" className="w-layout-grid footer-grid">
            <div id="w-node-e8d13286-4f22-98bd-8338-9e174ec1fd5d-4ec1fd5a" className="left-block-footer">
              <h3 className="footer-title">StreetTak</h3>
            </div>
            <div id="w-node-e8d13286-4f22-98bd-8338-9e174ec1fd61-4ec1fd5a" className="block-footer">
              <h5 className="footer-title">Company</h5>
              <div className="footer-nav-link-line">
                <div className="footer-hover-line newline"></div>
              </div>
              <div className="footer-nav-link-line">
                <a href="javascript:void(0)" onClick={() => history.push('/')} className="footer-link">How It Works</a>
                <div className="footer-hover-line newline"></div>
              </div>
              <div className="footer-nav-link-line">
                <a href="javascript:void(0)" onClick={() => history.push('/')} className="footer-link">Features</a>
                <div className="footer-hover-line newline"></div>
              </div>
              <div className="footer-nav-link-line">
                <a href="javascript:void(0)" onClick={() => history.push('/about')} className="footer-link">About</a>
                <div className="footer-hover-line newline"></div>
              </div>
              <div className="footer-nav-link-line">
                <a href="javascript:void(0)" onClick={() => history.push('/terms-and-condition')} className="footer-link">Terms & Conditions</a>
                <div className="footer-hover-line newline"></div>
              </div>
              {!loginStatus ?
                <div className="footer-nav-link-line">
                  <a href="javascript:void(0)" onClick={() => history.push('/register')} className="footer-link">Become a Member</a>
                  <div className="footer-hover-line newline"></div>
                </div> : ''}
            </div>
            <div id="w-node-e8d13286-4f22-98bd-8338-9e174ec1fd76-4ec1fd5a" className="block-footer">
              <h5 className="footer-title">Download App</h5>
              <div className="footer-nav-link-line">
                <a href="javascript:void(0)" className="footer-link">Android</a>
                <div className="footer-hover-line newline"></div>
              </div>
              <div className="footer-nav-link-line">
                <a href="javascript:void(0)" className="footer-link">iPhone</a>
                <div className="footer-hover-line newline"></div>
              </div>
              <div className="footer-nav-link-line">
                <div className="footer-hover-line newline"></div>
              </div>
            </div>
            <div id="w-node-_0e10e707-3e4a-7fa2-a49b-c9060eefa64a-4ec1fd5a" className="block-footer">
              <h5 className="footer-title">Contact</h5>
              <div className="footer-nav-link-line noline">
                <a href="javascript:void(0)" className="footer-link">StreetTak &amp; GroupBuy Companies<br />Suite 1002<br />1115 9 St SW<br />Calgary, AB<br />T2R 1C4<br />Canada</a>
              </div>
              <div className="footer-nav-link-line noline">
                <a href="javascript:void(0)" className="footer-link">(403) 852-2989<br />info@streettak.com</a>
              </div>
            </div>
          </div>
          <div className="footer-divider"></div>
          <div data-w-id="e8d13286-4f22-98bd-8338-9e174ec1fd86" className="bottom-footer">
            <div className="text-block">© 2021 StreetTak. All rights reserved</div>
          </div>
          <div data-w-id="e8d13286-4f22-98bd-8338-9e174ec1fd86" className="bottom-footer">
            <div className="text-block">© 2021 StreetTak. All rights reserved</div>
          </div>
          
        </div>
      </div>
		</div>
      )
}
export default AboutNew