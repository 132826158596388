import { streetTalkAction } from '../actionTypes'

const initialState = {
  postLoader: false,
  commenLoader: false,
  userPostData:{},
  userPostLoader: false,
  postCommentData:[],
  postCommentLoader:  false,
  particularPostData: {},
  particularPostLoader: false,
  streetTalkPostData: [],
  streetTalkLoader: false,
  vendorTalkPostData: [],
  vendorTalkLoader: false,
  otherUserPostData: {},
  otherUserPostLoader: false,
  blastLoader: false,
  blastUserData:[],
  likedLoader: false,
  likedUserData: [],
  unlikedLoader: false,
  unlikedUserData:[],
  vendorCheckLoader: false,
  updatePostLoader: false,
  updateCommentLoader: false,
  peopleMayKnowData: [],
  majorNewsData:[],
  coverImage:"",
  imageVideoUrl:'',
  postImageVideoUrl:[],
  streetTakNewsData:[],
  allFollowingData:[],
  allFollowersData:[],
  notificationListData:[],
  notificationCountData:[],
  notificationStatus:'',
  unseenNotification:[]
}

export default function streetTalk(state = initialState, {type, payload}) {
  switch(type) {
   
    case streetTalkAction.CREATE_POST_INITIATE: {
      return {
        ...state, postLoader: true
      }
    }
    case streetTalkAction.CREATE_POST__SUCCESS: {
      return {
        ...state, postLoader: false
      }
    }
    case streetTalkAction.CREATE_POST__FAILURE: {
      return {
        ...state, postLoader: true
      }
    }
    case streetTalkAction.CREATE_COMMENT_INITIATE: {
      return {
        ...state, commenLoader: true
      }
    }
    case streetTalkAction.CREATE_COMMENT__SUCCESS: {
      return {
        ...state, commenLoader: false
      }
    } 
    case streetTalkAction.CREATE_COMMENT__FAILURE: {
      return {
        ...state, commenLoader: false
      }
    }
    case streetTalkAction.USER_POST_INITIATE: {
      return {
        ...state, userPostLoader: true, userPostData: []
      }
    }
    case streetTalkAction.USER_POST__SUCCESS: {
      return {
        ...state, userPostLoader: false, userPostData: payload
      }
    } 
    case streetTalkAction.USER_POST__FAILURE: {
      return {
        ...state, userPostLoader: false
      }
    }
    case streetTalkAction.POST_COMMENT_INITIATE: {
      return {
        ...state, postCommentLoader: true
      }
    }
    case streetTalkAction.POST_COMMENT__SUCCESS: {
      return {
        ...state, postCommentLoader: false, postCommentData: payload.Data
      }
    } 
    case streetTalkAction.POST_COMMENT__FAILURE: {
      return {
        ...state, postCommentLoader: false,postCommentData:[]
      }
    }
    case streetTalkAction.PARTICULAR_POST_INITIATE: {
      return {
        ...state, particularPostLoader: payload
      }
    }
    case streetTalkAction.PARTICULAR_POST_SUCCESS: {
      return {
        ...state, particularPostLoader: false, particularPostData: payload.Data
      }
    } 
    case streetTalkAction.PARTICULAR_POST_FAILURE: {
      return {
        ...state, particularPostLoader: false
      }
    }
    case streetTalkAction.STREETTALK_POST_INITIATE: {
      return {
        ...state
      }
    }
    case streetTalkAction.STREETTALK_POST_SUCCESS: {
      return {
        ...state, streetTalkLoader: false, streetTalkPostData:  payload
      }
    } 
    case streetTalkAction.STREETTALK_POST_FAILURE: {
      return {
        ...state, streetTalkLoader: false
      }
    }
    case streetTalkAction.VENDOR_POST_INITIATE: {
      return {
        ...state, vendorTalkLoader: payload
      }
    }
    case streetTalkAction.VENDOR_POST_SUCCESS: {
      return {
        ...state, vendorTalkLoader: false, vendorTalkPostData: payload
      }
    } 
    case streetTalkAction.VENDOR_POST_FAILURE: {
      return {
        ...state, vendorTalkLoader: false
      }
    }
    case streetTalkAction.OTHER_USER_POST_INITIATE: {
      return {
        ...state, otherUserPostLoader: true,otherUserPostData:{}
      }
    }
    case streetTalkAction.OTHER_USER_POST_SUCCESS: {
      return {
        ...state, otherUserPostLoader: false, otherUserPostData: payload
      }
    } 
    case streetTalkAction.OTHER_USER_POST_FAILURE: {
      return {
        ...state, otherUserPostLoader: false,otherUserPostData:{}
      }
    }
    case streetTalkAction.BLAST_USER_INITIATE: {
      return {
        ...state, blastLoader: true, blastUserData:[],likedUserData:[],unlikedUserData:[]
      }
    }
    case streetTalkAction.BLAST_USER_SUCCESS: {
      return {
        ...state, blastLoader: false, blastUserData: payload.Data
      }
    } 
    case streetTalkAction.BLAST_USER_FAILURE: {
      return {
        ...state, blastLoader: false
      }
    }
    case streetTalkAction.LIKED_USER_INITIATE: {
      return {
        ...state, likedLoader: true, likedUserData:[],blastUserData:[],unlikedUserData:[]
      }
    }
    case streetTalkAction.LIKED_USER_SUCCESS: {
      return {
        ...state, likedLoader: false, likedUserData: payload.Data
      }
    } 
    case streetTalkAction.LIKED_USER_FAILURE: {
      return {
        ...state, likedLoader: false
      }
    }

    case streetTalkAction.UNLIKED_USER_INITIATE: {
      return {
        ...state, unlikedLoader: true, unlikedUserData:[], blastUserData:[], likedUserData:[]
      }
    }
    case streetTalkAction.UNLIKED_USER_SUCCESS: {
      return {
        ...state, unlikedLoader: false, unlikedUserData: payload.Data
      }
    } 
    case streetTalkAction.UNLIKED_USER_FAILURE: {
      return {
        ...state, unlikedLoader: false
      }
    }
    case streetTalkAction.EMPTY_USER_LIKED_DATA: {
      return {
        ...state, 
        unlikedLoader: false,
        unlikedUserData:[],
        likedLoader: false,
        likedUserData: [],
        blastLoader: false,
        blastUserData: [],
        streetTalkPostData:[],
        vendorTalkPostData:[],
        postCommentData:[],
        otherUserPostData:{}
        // peopleMayKnowData:[],
        // majorNewsData:[]
      }
    }
    case streetTalkAction.VENDOR_CHECK_INITIATE: {
      return {
        ...state, vendorCheckLoader: true, 
      }
    }
    case streetTalkAction.VENDOR_CHECK_SUCCESS: {
      return {
        ...state, vendorCheckLoader: false, 
      }
    } 
    case streetTalkAction.VENDOR_CHECK_FAILURE: {
      return {
        ...state, vendorCheckLoader: false
      }
    }
    case streetTalkAction.UPDATE_POST_INITIATE: {
      return {
        ...state, updatePostLoader: true, 
      }
    }
    case streetTalkAction.UPDATE_POST_SUCCESS: {
      return {
        ...state, updatePostLoader: false, 
      }
    } 
    case streetTalkAction.UPDATE_POST_FAILURE: {
      return {
        ...state, updatePostLoader: false
      }
    }
    case streetTalkAction.UPDATE_COMMENT_INITIATE: {
      return {
        ...state, updateCommentLoader: true, 
      }
    }
    case streetTalkAction.UPDATE_COMMENT_SUCCESS: {
      return {
        ...state, updateCommentLoader: false, 
      }
    } 
    case streetTalkAction.UPDATE_COMMENT_FAILURE: {
      return {
        ...state, updateCommentLoader: false
      }
    }
    case streetTalkAction.PEOPLE_MAY_KNOW_INITIATE: {
      return {
        ...state, peopleMayKnowData: [], 
      }
    }
    case streetTalkAction.PEOPLE_MAY_KNOW_SUCCESS: {
      return {
        ...state, peopleMayKnowData: payload.Data, 
      }
    } 
    case streetTalkAction.PEOPLE_MAY_KNOW_FAILURE: {
      return {
        ...state, peopleMayKnowData: []
      }
    }
    case streetTalkAction.MAJOR_NEWS_INITIATE: {
      return {
        ...state, majorNewsData: [], 
      }
    }
    case streetTalkAction.MAJOR_NEWS_SUCCESS: {
      return {
        ...state, majorNewsData: payload.Data, 
      }
    } 
    case streetTalkAction.MAJOR_NEWS_FAILURE: {
      return {
        ...state, majorNewsData: []
      }
    }
    case streetTalkAction.UPDATE_COVER_PHOTO_INITIATE: {
      return {
        ...state, coverImage: [], 
      }
    }
    case streetTalkAction.UPDATE_COVER_PHOTO_SUCCESS: {
      return {
        ...state, coverImage: payload.Data, 
      }
    } 
    case streetTalkAction.UPDATE_COVER_PHOTO_FAILURE: {
      return {
        ...state, coverImage: []
      }
    }
    case streetTalkAction.UPLOAD_IMAGE_VIDEO_INITIATE: {
      return {
        ...state, imageVideoUrl: '', 
      }
    }
    case streetTalkAction.UPLOAD_IMAGE_VIDEO_SUCCESS: {
      return {
        ...state, imageVideoUrl: payload.Data, 
      }
    } 
    case streetTalkAction.UPLOAD_IMAGE_VIDEO_FAILURE: {
      return {
        ...state, imageVideoUrl:''
      }
    }
    case streetTalkAction.EMPTY_IMAGE_VIDEO_URL: {
      return {
        ...state, imageVideoUrl:'',postImageVideoUrl:[]
      }
    }
    case streetTalkAction.UPLOAD_MULTIPLE_IMAGES_INITIATE: {
      return {
        ...state, postImageVideoUrl: [], 
      }
    }
    case streetTalkAction.UPLOAD_MULTIPLE_IMAGES_SUCCESS: {
      return {
        ...state, postImageVideoUrl: payload.Data, 
      }
    } 
    case streetTalkAction.UPLOAD_MULTIPLE_IMAGES_FAILURE: {
      return {
        ...state, postImageVideoUrl:[]
      }
    }
    case streetTalkAction.SHARE_POST_INITIATE: {
      return {
        ...state, particularPostData: {}, 
      }
    }
    case streetTalkAction.SHARE_POST_SUCCESS: {
      return {
        ...state, particularPostData: payload, 
      }
    } 
    case streetTalkAction.SHARE_POST_FAILURE: {
      return {
        ...state, particularPostData:{}
      }
    }

    case streetTalkAction.STREETTAK_NEWS_INITIATE: {
      return {
        ...state, streetTakNewsData: [], 
      }
    }
    case streetTalkAction.STREETTAK_NEWS_SUCCESS: {
      return {
        ...state, streetTakNewsData: payload, 
      }
    } 
    case streetTalkAction.STREETTAK_NEWS_FAILURE: {
      return {
        ...state, streetTakNewsData:[]
      }
    }
    case streetTalkAction.ALL_FOLLOWING_INITIATE: {
      return {
        ...state 
      }
    }
    case streetTalkAction.ALL_FOLLOWING_SUCCESS: {
      return {
        ...state, allFollowingData: payload.Data, 
      }
    } 
    case streetTalkAction.ALL_FOLLOWING_FAILURE: {
      return {
        ...state, allFollowingData:[]
      }
    }
    case streetTalkAction.ALL_FOLLOWERES_INITIATE: {
      return {
        ...state 
      }
    }
    case streetTalkAction.ALL_FOLLOWERES_SUCCESS: {
      return {
        ...state, allFollowersData: payload.Data, 
      }
    } 
    case streetTalkAction.ALL_FOLLOWERES_FAILURE: {
      return {
        ...state, allFollowersData:[]
      }
    }

    case streetTalkAction.NOTIFICATION_LIST_INITIATE: {
      return {
        ...state, notificationListData: [], 
      }
    }
    case streetTalkAction.NOTIFICATION_LIST_SUCCESS: {
      return {
        ...state, notificationListData: payload.notificationsList, 
      }
    } 
    case streetTalkAction.NOTIFICATION_LIST_FAILURE: {
      return {
        ...state, notificationListData:[]
      }
    }
    case streetTalkAction.NOTIFICATION_COUNT_INITIATE: {
      return {
        ...state,
      }
    }
    case streetTalkAction.NOTIFICATION_COUNT_SUCCESS: {
      return {
        ...state, notificationCountData: payload.Data, 
      }
    } 
    case streetTalkAction.NOTIFICATION_COUNT_FAILURE: {
      return {
        ...state, notificationCountData:[]
      }
    }
    case streetTalkAction.NOTIFICATION_STATUS_INITIATE: {
      return {
        ...state, notificationStatus: '', 
      }
    }
    case streetTalkAction.NOTIFICATION_STATUS_SUCCESS: {
      return {
        ...state, notificationStatus: payload.Data, 
      }
    } 
    case streetTalkAction.NOTIFICATION_STATUS_FAILURE: {
      return {
        ...state, notificationStatus:''
      }
    }
    case streetTalkAction.UNSEEN_NOTIFICATION_INITIATE: {
      return {
        ...state, unseenNotification: [], 
      }
    }
    case streetTalkAction.UNSEEN_NOTIFICATION_SUCCESS: {
      return {
        ...state, unseenNotification: payload.notificationsList, 
      }
    } 
    case streetTalkAction.UNSEEN_NOTIFICATION_FAILURE: {
      return {
        ...state, unseenNotification:[]
      }
    }
    default: 
      return state
  }
}