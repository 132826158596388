import React, { useState, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../App.css";
import _ from 'lodash'
import { Link, useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Dropdown from "react-bootstrap/Dropdown"
import { toast } from 'react-toastify';
import CustomLoader from './customLoader';
import { Player, BigPlayButton } from 'video-react';
import InputEmoji from "react-input-emoji";
import moment from 'moment'
import LightboxCustom from './lightBox'
import Preview from  'preview-url-component'
import logo from "../assets/media/gbLogo.png";
import Carousel from 'react-bootstrap/Carousel';
import Picker from 'emoji-picker-react';
import addNew from "../assets/media/add_new.png"
import streetTalkLogo from "../assets/media/streettalk.svg"

import User from "../assets/media/defaultProfileImage.jpeg";
import Icon1 from "../assets/media/Icon-1.svg";
import Icon2 from "../assets/media/Icon-2.svg";
import Icon4 from "../assets/media/Icon-4.svg";
import Icon5 from "../assets/media/Icon-5.svg";
import Icon6 from "../assets/media/Icon-6.svg";
import Blast from "../assets/media/blast.svg"
import Like from "../assets/media/like.svg"
import Dislike from "../assets/media/dislike.svg"

import Header from "./header";
import Footer from "./footer";
import { sendRequestBuddies } from '../redux/actions/buddiesAction'
import {
  createPost,
  streetTalkPost,
  vendorTalkPost,
  createComment,
  postComment,
  likePost,
  deletePost,
  deleteComment,
  particularPost,
  otherUserPost,
  blastUsers,
  likedUsers,
  unlikedUsers,
  emptyUserLikeData,
  particularUpdateComment,
  postReport,
  majorNews,
  peopleMayKnow,
  multipleComment,
  emptyImageVideo,
  uploadImageVideo,
  particularPostWithoutAuth
} from '../redux/actions/streetTalkAction'

const initialState = {
  comment: '',
  selectedId: '',
  commentId: '',
  updateComment: '',
  reported_topic: '',
  remark: '',
  commentImages: '',
  commentVideo: '',
  imageFile: '',
  videoFile: '',
  particularPostId: '',
  isOpen: false,
  lightBoxImg: [],
  imageViewModal: false,
  modalImageData: {},
  commentModal: false,
  commentIndex: 0,
  multipleComents: [],
  emojiFlag: false,
  postId:''
}
const StreetTalkSharePost = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [iState, updateState] = useState(initialState)  
  const [show, setShow] = useState(false);
  const [key, setKey] = useState("home");
  const [showReport, setShowReport] = useState(false);
  const [doneReport, setDoneReport] = useState(false);
  const [postCmt, updatePostCmt] = useState('')
  const postData = useSelector(state => state.streetTalk)
  const profileData = JSON.parse(window.localStorage.getItem("userData"))
  const userPostId = JSON.parse(window.localStorage.getItem("postId"))
  const followersCount = JSON.parse(window.localStorage.getItem("Followerscount"))
  const followingCount = JSON.parse(window.localStorage.getItem("Followingcount"))

  const {
    particularPostLoader,
    particularPostData,
    postCommentData,
    postCommentLoader,
    otherUserPostData,
    blastLoader,
    blastUserData,
    likedLoader,
    likedUserData,
    unlikedLoader,
    unlikedUserData,
    peopleMayKnowData,
    majorNewsData,
    imageVideoUrl
  } = postData
  const {
    comment,
    selectedId,
    commentId,
    updateComment,
    reported_topic,
    remark,
    commentImages,
    commentVideo,
    imageFile,
    videoFile,
    particularPostId,
    isOpen,
    lightBoxImg,
    imageViewModal,
    modalImageData,
    commentModal,
    commentIndex,
    multipleComents,
    emojiFlag,
    postId
  } = iState

  const handlePath = (path) => {
    history.push(path)
  }
  const hideimageViewModal = () => {
    updateState({
      ...iState, imageViewModal: false, modalImageData: {}
    })
  }

  const openImageViewModal = (Data) => {
    dispatch(postComment({ postId: Data._id }))
    updateState({
      ...iState, imageViewModal: true, modalImageData: Data
    })
  }


  const updatePostId = (id) => {
    updateState({ ...iState, particularPostId: id })
  }

  const handleShow = () => setShow(true);

  const handleInputChange = (e) => {
    const { name, value } = e.target
    updateState({
      ...iState, [name]: value
    })
  }


	let path= window.location.href.split('/')
  console.log('path',path)
  useEffect(() => {
    dispatch(particularPostWithoutAuth({ postId: path[4] }))
    window.scrollTo({
      top: 0,
      left: 100,
      behavior: 'smooth'
    });
  }, [])

  const updatePostComment = (item, id) => {

    if (!_.isEmpty(item)) {
      const updateData = _.cloneDeep(iState)
      updateData.updateComment = item.comment
      updateData.commentId = id
      updateData.commentImages = item.images || ''
      updateData.commentVideo = item.video || ''

      updateState(
        updateData
      )
    }
  }
  const onCommentFileHandler = (e) => {
    e.preventDefault()
    const file = e.target.files[0]
    if (file) {
      if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
        dispatch(uploadImageVideo({ images: file }))
        commentPreviewsFile(file)
      } else if (file.type === 'video/mp4') {
        const reader = new FileReader()
        reader.addEventListener("load", () => {
          dispatch(uploadImageVideo({ video: file }))
          updateState({
            ...iState, commentVideo: reader.result, videoFile: file
          })
        }, false)
        if (file) {
          reader.readAsDataURL(file)
        }
      }
      else {
        toast.error('Only formats are allowed: jpeg, jpg, png files', {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }
  }

  const commentPreviewsFile = (file) => {
    const reader = new FileReader()
    reader.addEventListener("load", () => {
      updateState({
        ...iState, commentImages: reader.result, imageFile: file
      })
    }, false)
    if (file) {
      reader.readAsDataURL(file)
    }
  }

  const saveComment = (id) => {
    const data = { commentId: commentId, comment: updateComment, commentImage: imageFile, video: videoFile }
    if (data.commentImage === '') {
      delete data.commentImage
    }
    if (data.video === '') {
      delete data.video
    }
    dispatch(particularUpdateComment(data)).then(res => {
      if (res.response_code == 200) {
        updateState({
          ...iState, commentId: "", updateComment: '', commentImages: '', commentVideo: '', imageFile: '', videoFile: ''
        })
        updatePostCmt('')
        dispatch(particularPost({ postId: userPostId }))
        dispatch(postComment({ postId: id }))
      }
    }).catch(err => {
      const message = err && err.response && err.response ? err.response.message : "Something went handleSuit"
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
    })
  }


  const cancelEditComment = () => {
    updateState({
      ...iState, commentId: "", updateComment: ''
    })
  }

  const removeCommentData = (type) => {
    if (type === 'img') {
      updateState({
        ...iState, commentImages: ''
      })
    } else if (type === 'video') {
      updateState({
        ...iState, commentVideo: ''
      })
    }
  }

  const addComment = () => {
    updateState({
      ...iState,
      multipleComents: [...iState.multipleComents, {
        postId: postId,
        comment: comment,
        images: imageVideoUrl && imageVideoUrl.images ? imageVideoUrl.images : '',
        video: imageVideoUrl && imageVideoUrl.video ? imageVideoUrl.video : ''
      }],
      comment: '',
      commentImages: '',
      imageFile: '',
      commentVideo: '',
      videoFile: ''
    })
    dispatch(emptyImageVideo())
  }

  const replyComment = () => {
    let copyComments = [...multipleComents]
    if (comment !== '' || commentImages !== '' || commentVideo !== '') {
      copyComments.push({
        comment,
        postId: postId,
        images: imageVideoUrl && imageVideoUrl.images ? imageVideoUrl.images : '',
        video: imageVideoUrl && imageVideoUrl.video ? imageVideoUrl.video : ''
      })
    }
    dispatch(multipleComment({ multipleComents: copyComments })).then(res => {
      if (res.response_code == 200) {
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT
        })

        dispatch(emptyImageVideo())
        dispatch(particularPost({ postId: userPostId }))
        updateState({
          ...iState, commentModal: false, postId: '', commentIndex: 0, comment: '', commentImages: '', commentVideo: '', imageFile: '', videoFile: '', emojiFlag: false
        })
      }
    }).catch(err => {
      const message = err && err.response && err.response.data ? err.response.data.message : "Something went handleSuit"
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
    })

  }
  const hidecommentModal = () => {
    dispatch(emptyImageVideo())
    updateState({ ...iState, commentModal: false, comment: '', multipleComents: [], commentImages: '', imageFile: '', commentVideo: '', videoFile: '', emojiFlag: false })
  }



  const deleteUserComment = (id, postid) => {
    dispatch(deleteComment({ commentId: id, postId: postid })).then(res => {
      if (res.response_code == 200) {
        dispatch(particularPost({ postId: postid }))
        dispatch(postComment({ postId: postid }))
      }
    }).catch(err => {
      const message = err && err.response && err.response ? err.response.message : "Something went handleSuit"
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
    })
  }

  const postLike = (id, n) => {
    const data = { postId: id, likeStatus: n }
    dispatch(likePost(data)).then(res => {
      if (res.response_code == 200) {
        dispatch(particularPost({ postId: id }, false))
      }
    }).catch(err => {
      const message = err && err.response && err.response ? err.response.message : "Something went handleSuit"
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
    })
  }

  const userPostDetail = (userId) => {
    dispatch(otherUserPost({ userId: userId }))
    window.localStorage.setItem("userId", JSON.stringify(userId))
  }

  const postLikedUsers = (id, type) => {
    if (type === 'blast') {
      dispatch(blastUsers({ postId: id }))
      handleShow()
    } else if (type === 'like') {
      dispatch(likedUsers({ postId: id }))
      handleShow()
    } else if (type === 'unlike') {
      dispatch(unlikedUsers({ postId: id }))
      handleShow()
    }
  }

  const handleEmojiChange = (e, emojiObject) => {
    let emojiComment = `${comment}${emojiObject.emoji}`
    updateState({ ...iState, comment: emojiComment })
  }
  const handleEmojiPopup = () => {
    if (emojiFlag) {
      updateState({ ...iState, emojiFlag: false })
    } else {
      updateState({ ...iState, emojiFlag: true })
    }
  }
  const handlePreviewUrl =(url)=>{
    const win = window.open(url, "_blank");
    win.focus();
  }
  return (
    <Fragment>
      <div>
        <Header />
        <div className="TalkArea">
          <div className="container">
            <div className="row">
              <div className="col-sm-3">
              <div className="TalkLeft">
                      <h4><img src={streetTalkLogo} /></h4>
                      <ul>
                          <div className='streettakMenu'>
                        <i className="fa fa-home" aria-hidden="true"></i>
                          <li onClick={() => handlePath('/login')}>
                            <a>Home</a>
                          </li>
                          </div>
                          <div className='streettakMenu'>
                          <i className="fa fa-user" aria-hidden="true"></i>
                          <li onClick={() => handlePath('/login')}> 
                            <a>Profile</a>
                          </li>
                          </div>
                          <div className='streettakMenu'>
                          <i className="fas fa-cog"></i>
                          <li onClick={() => handlePath('/login')}>
                            <a>Settings</a>
                          </li>
                          </div>
                          <div className='streettakMenu'>
                          <i className="fas fa-bell"></i>
                          <li onClick={() => handlePath('/login')}>
                            <a>Notification</a>
                          </li>
                          </div>
                          <div className='streettakMenu'>
                          <i className="fas fa-comment-alt"></i>
                          <li onClick={() => handlePath('/login')}>
                            <a>Message</a>
                          </li>
                          </div>
                        </ul>
                      </div>
                    
              </div>

              <div className="col-sm-6 padding_none">
                <div className="PostArea">
                  {particularPostData && particularPostData.Data ?
                    <div className="PostBox">
                      <div className="PostIcon">
                        {particularPostData.userData.profilePic ? <img src={particularPostData.userData.profilePic} /> : `${particularPostData.userData.firstName.charAt(0).toUpperCase()}${particularPostData.userData.lastName.charAt(0).toUpperCase()}`}
                        {/* <img src={particularPostData.userData.profilePic} /> */}
                      </div>
                      <div className="PostRight">
                        
                          <React.Fragment>
                            <span></span>
                            <Dropdown alignRight className="FilterCardDropdown PostDots" >
                              <Dropdown.Toggle id="dropdown-basic">
                                <i className="fas fa-ellipsis-h"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                              </Dropdown.Menu>
                            </Dropdown>
                          </React.Fragment>
                          
                        <div className="PostContent">
                          <div className="padinglft">
                            <h4>
                              <Link to="/login" onClick={() => userPostDetail(particularPostData.Data.userId)}>{particularPostData.userData.firstName && `${particularPostData.userData.firstName} ${particularPostData.userData.lastName}`}</Link>
                              <span className="Time">{`${moment(particularPostData.Data.createdAt).fromNow(true)} ago`}</span>
                              <span className="postDate">{moment(particularPostData.Data.createdAt).format('MM-DD-YYYY')}</span>
                                      <span>{particularPostData.Data.shared ? <span>
                                        <img src={Blast} />
                                        <span className="blastBy"> {` Blasted By ${particularPostData.userData.firstName}  ${particularPostData.userData.lastName}`}</span>
                                        </span> : ''}
                                      </span>
                            </h4>

                            <p>{particularPostData.Data.newCaption} </p>
                          </div>
                        </div>
                        <div className={particularPostData.Data.shared ? 'borderShared' : ''}>
                          {particularPostData.Data.shared &&
                            <div>
                              <figure className="sharedIcon">
                                {particularPostData.OtherUser.profilePic ? <img src={particularPostData.OtherUser.profilePic} /> : `${particularPostData.OtherUser.firstName.charAt(0).toUpperCase()}${particularPostData.OtherUser.lastName.charAt(0).toUpperCase()}`}
                              </figure>
                              <Link to="/login"><h6 className="mt-2 padinglft" style={{ color: '#565656' }} onClick={() => userPostDetail(particularPostData.OtherUser._id)}>{`${particularPostData.OtherUser.firstName} ${particularPostData.OtherUser.lastName}`}</h6></Link>
                            </div>}
                          {particularPostData.Data.caption.split(/[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi)
                            .map((part, index) => index % 2 === 0 ? <p className="padinglft">{part}</p> :
                              <p className="padinglft linkMargin" onClick={()=> handlePreviewUrl(particularPostData.Data.caption)}>
                                <Preview
                                  url={particularPostData.Data.caption}
                                  loadingText="loading..."
                                    notFound="URL Not Found!"
                                />
                              </p>)}
                          {/* <p className="padinglft">{particularPostData.Data.caption} </p> */}

                          <div className="PostImages padingleft">
                            {particularPostData.Data.video && particularPostData.Data.hasOwnProperty("video") && particularPostData.Data.video.map((video, i) => {
                              return (
                                <React.Fragment>
                                  <span className='mt-2'><Player className='singleVideo'>
                                    <source className='singleVideo' src={video} />
                                    <BigPlayButton position="center" />
                                  </Player></span>
                                </React.Fragment>
                              )
                            })}

                            {particularPostData.Data.images && particularPostData.Data.images.map((img, i) => {
                              return (
                                <React.Fragment>
                                  {i <= 2 && <span onClick={() => openImageViewModal(particularPostData.Data)} className={particularPostData.Data.images.length > 1 ? "Images" : ''}><img className={particularPostData.Data.images.length == 1 ? 'singleImg' : ''} src={img} /></span>}
                                  {particularPostData.Data.images.length === 4 && i === 3 && <span onClick={() => openImageViewModal(particularPostData.Data)} className="Images"><img className={particularPostData.Data.images.length == 1 ? 'singleImg' : ''} src={img} /></span>}

                                </React.Fragment>
                              )
                            })}
                            {particularPostData.Data.images && particularPostData.Data.images.length > 4 && <span onClick={() => openImageViewModal(particularPostData.Data)} className="Images" style={{ cursor: 'pointer' }}>
                              <img className={particularPostData.Data.images.length == 1 ? 'singleVideo' : ''} src={particularPostData.Data.images[particularPostData.Data.images.length - 1]} />
                              <span className="Count">+{particularPostData.Data.images.length - 4}</span></span>}
                            {/* </Link> */}

                          </div>
                        </div>
                        <div className="LikeLine">
                          <ul>
                            <li>
                              <a title={(particularPostData.Data && particularPostData.Data.blastStatus === "true") ? 'Blasted' : 'Blast'}>
                                {(particularPostData.Data && particularPostData.Data.blastStatus === "true") ? <img src={Blast} /> :
                                  <img src={Icon1} />}
                                <span>{particularPostData.Data.blastCount}</span>
                              </a>
                            </li>
                            <li>
                              <a title='Comments'>
                                <img src={Icon2}  />
                                <span>{particularPostData.Data.numberOfComments}</span>
                              </a>
                            </li>
                            <li>
                              <a title='Like'>
                                {(particularPostData.Data && particularPostData.Data.likedStatus === "true") ? <img src={Like} /> :
                                  <img src={Icon4} />}
                                <span>{particularPostData.Data.likeCount}</span>
                              </a>
                            </li>
                            <li>
                              <a title='Dislike'>
                                {(particularPostData.Data && particularPostData.Data.unlikedStatus === "true") ? <img src={Dislike} onClick={() => postLike(particularPostData.Data._id, 3)} /> :
                                  <img src={Icon6} />}
                                <span >{particularPostData.Data.unlikeCount}</span>
                              </a>
                            </li>
                            <li>
                              <a title='Share'>
                                <img src={Icon5} />
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="postComment">
                          {(postCommentData && postCommentData.length > 0) ? postCommentData.map((item, key) => {
                            return (
                              <React.Fragment>
                                {item.postId === particularPostData.Data._id &&
                                  <div className="PostCommentBox">
                                    <ul>
                                      <li>
                                        <div className="Comment">
                                          <figure>
                                            {item.userDetail[0].profilePic ? <img src={item.userDetail[0].profilePic} /> : `${item.userDetail[0].firstName.charAt(0).toUpperCase()}${item.userDetail[0].lastName.charAt(0).toUpperCase()}`}
                                          </figure>
                                          <figcaption>
                                            <h5>{`${item.userDetail[0].firstName} ${item.userDetail[0].lastName}`}</h5>
                                            {/* <h6>Noida</h6> */}
                                            {commentId === item._id ?
                                              <span>
                                                <input
                                                  type='file'
                                                  id='Image'
                                                  onChange={onCommentFileHandler}
                                                  onClick={e => e.target.value = null}
                                                  style={{ display: 'none' }}
                                                />
                                                <label htmlFor='Image'>
                                                  <i className="fas fa-camera"></i>
                                                </label>
                                                <input
                                                  type="text"
                                                  name="updateComment"
                                                  value={updateComment}
                                                  onChange={handleInputChange}
                                                /> </span> : <span> <p>{item.comment}</p></span>}
                                            {commentImages && commentId === item._id ?
                                              <span><i className="pl-2 far fa-times-circle" onClick={() => removeCommentData('img')}></i>

                                                <img style={{ height: '100px' }} src={commentImages} />
                                              </span>
                                              : item.images && commentId !== item._id ?
                                                <img style={{ height: '100px' }} src={item.images && item.images} />
                                                : ''
                                            }
                                            {commentVideo && commentId === item._id ?
                                              <span> <i className="pl-2 far fa-times-circle" onClick={() => removeCommentData('video')}></i>
                                                <Player fluid={false} height={100} width={200}>
                                                  <source src={commentVideo} />
                                                  <BigPlayButton position="center" />
                                                </Player></span>
                                              : item.video && commentId !== item._id ?
                                                <span>
                                                  <Player fluid={false} height={100} width={200}>
                                                    <source src={item.video && item.video} />
                                                    <BigPlayButton position="center" />
                                                  </Player></span>
                                                : ''}

                                          </figcaption>
                                          <div style={{ padding: "10px 0 0 5px " }}>
                                            {profileData._id === item.userId &&
                                              <Dropdown alignRight className="FilterCardDropdown PostDots" style={{ position: 'relative', top: '0px' }}>
                                                <Dropdown.Toggle id="dropdown-commrt">
                                                  <i className="fas fa-ellipsis-h"></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                  {profileData._id === item.userId ?
                                                    <React.Fragment>
                                                      <Dropdown.Item className="FilterCardDropdownItem"
                                                        onClick={() => updatePostComment(item, item._id)}
                                                      ><i className="fas fa-edit postMenu"> </i>
                                                        <a>Edit comment</a>
                                                      </Dropdown.Item>
                                                      <Dropdown.Item className="FilterCardDropdownItem" onClick={() => deleteUserComment(item._id, item.postId)}>
                                                        <i className="far fa-trash-alt postMenu"> </i>
                                                        <a >Delete comment</a>
                                                      </Dropdown.Item>
                                                    </React.Fragment>
                                                    : ''}
                                                </Dropdown.Menu>
                                              </Dropdown>
                                            }
                                            {commentId === item._id ? <span className='d-flex pt-2'><i className="far fa-check-circle" onClick={() => saveComment(item.postId)}></i><i className="pl-2 far fa-times-circle" onClick={cancelEditComment}></i></span> : ''}
                                          </div>
                                        </div>

                                        <span className='timeSpan'>{`${moment(item.createdAt).fromNow(true)} ago`}</span>
                                      </li>
                                    </ul>
                                  </div>}
                              </React.Fragment>
                            )
                          }) : ''
                          }
                        </div><div className="CommentPost" >
                                  <figure>
                                    <img src={User} />
                                  </figure>
                                  <input type="text" disabled placeholder="Leave your thoughts here...." />
                                </div>
                      </div>
                    </div>
                    :
                    <CustomLoader loading={particularPostLoader} />
                  }
                  {isOpen && (
                    <LightboxCustom
                      images={lightBoxImg}
                      lightBoxOpen={() => updateState({
                        ...iState, isOpen: false
                      })}
                    />
                  )}
                </div>
              </div>

              <div className="col-sm-3">
                <div className="TalkRight">
                  <div className="Search">
                    <form>
                      <span>
                        <i className="fa fa-search"></i>
                      </span>
                      <input type="text" placeholder="Search" />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ModalBox">
         
        </div>
      
        <div className="ModalBox">
          <Modal id="MultipleModal" show={imageViewModal} onHide={hideimageViewModal}>
            {modalImageData && Object.keys(modalImageData).length > 0 &&
              <div className="MultipleBox">
                <div className="MultipleLeft">
                  <a className="Close" data-dismiss="modal" onClick={hideimageViewModal}>&times;</a>

                  <Carousel>
                    {modalImageData && modalImageData.images && modalImageData.images.length > 0 && modalImageData.images.map((img, i) => (
                      <Carousel.Item key={i}>
                        <figure><img src={img} /></figure>

                      </Carousel.Item>
                    ))}
                  </Carousel>
                  <div className="LikeLine">
                    <ul>
                      <li>
                        <a title={(particularPostData.Data && particularPostData.Data.blastStatus === "true") ? 'Blasted' : 'Blast'}>
                          {(particularPostData.Data && particularPostData.Data.blastStatus === "true") ? <img src={Blast} /> :
                            <img src={Icon1} />}
                          <span>{particularPostData.Data.blastCount}</span>
                        </a>
                      </li>
                      <li>
                        <a title='Comments'>
                          <img src={Icon2} />
                          <span>{particularPostData.Data.numberOfComments}</span>
                        </a>
                      </li>
                      <li>
                        <a title='Like'>
                          {(particularPostData.Data && particularPostData.Data.likedStatus === "true") ? <img src={Like} onClick={() => postLike(particularPostData.Data._id, 3)} /> :
                            <img src={Icon4} onClick={() => postLike(particularPostData.Data._id, 1)} />}
                          <span onClick={() => postLikedUsers(particularPostData.Data._id, 'like')}>{particularPostData.Data.likeCount}</span>
                        </a>
                      </li>
                      <li>
                        <a title='Dislike'>
                          {(particularPostData.Data && particularPostData.Data.unlikedStatus === "true") ? <img src={Dislike} onClick={() => postLike(particularPostData.Data._id, 3)} /> :
                            <img src={Icon6} onClick={() => postLike(particularPostData.Data._id, 2)} />}
                          <span onClick={() => postLikedUsers(particularPostData.Data._id, 'unlike')}>{particularPostData.Data.unlikeCount}</span>
                        </a>
                      </li>
                      <li>
                        <a title='Share'>
                          <img src={Icon5} />
                        </a>
                      </li>
                    </ul>
                  </div>


                </div>

                <div className="MultipleRight">
                  <div className="PostBox">
                    <div className="PostIcon">
                      {particularPostData.userData.profilePic ? <img src={particularPostData.userData.profilePic} /> : `${particularPostData.userData.firstName.charAt(0).toUpperCase()}${particularPostData.userData.lastName.charAt(0).toUpperCase()}`}
                    </div>
                    <div className="PostRight">
                      <div className="PostContent">
                        <h4><a>{`${particularPostData.userData.firstName} ${particularPostData.userData.lastName}`}</a><span className="Time">{`${moment(modalImageData.createdAt).fromNow(true)} ago`}</span>
                        <span className="postDate">{moment(modalImageData.createdAt).format('MM-DD-YYYY')}</span>
                        </h4>
                        <p style={{ height: '85px' }}>{modalImageData.caption ? modalImageData.caption : ''}</p>
                      </div>
                    </div>
                  </div>

                  <div className="LikeLine">
                    <ul>
                      <li>
                        <a title={(particularPostData.Data && particularPostData.Data.blastStatus === "true") ? 'Blasted' : 'Blast'}>
                          {(particularPostData.Data && particularPostData.Data.blastStatus === "true") ? <img src={Blast} /> :
                            <img src={Icon1} />}
                          <span >{particularPostData.Data.blastCount}</span>
                        </a>
                      </li>
                      <li>
                        <a title='Comments'>
                          <img src={Icon2} />
                          <span>{particularPostData.Data.numberOfComments}</span>
                        </a>
                      </li>
                      <li>
                        <a title='Like'>
                          {(particularPostData.Data && particularPostData.Data.likedStatus === "true") ? <img src={Like} onClick={() => postLike(particularPostData.Data._id, 3)} /> :
                            <img src={Icon4} onClick={() => postLike(particularPostData.Data._id, 1)} />}
                          <span onClick={() => postLikedUsers(particularPostData.Data._id, 'like')}>{particularPostData.Data.likeCount}</span>
                        </a>
                      </li>
                      <li>
                        <a title='Dislike'>
                          {(particularPostData.Data && particularPostData.Data.unlikedStatus === "true") ? <img src={Dislike} onClick={() => postLike(particularPostData.Data._id, 3)} /> :
                            <img src={Icon6} onClick={() => postLike(particularPostData.Data._id, 2)} />}
                          <span onClick={() => postLikedUsers(particularPostData.Data._id, 'unlike')}>{particularPostData.Data.unlikeCount}</span>
                        </a>
                      </li>
                      <li>
                        <a title='Share'>
                          <img src={Icon5} />
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="MultipleComment">
                    <ul>
                      {(postCommentData && postCommentData.length > 0) ? postCommentData.map((item, key) => {
                        return (
                          <React.Fragment>
                            <li>
                              <figure>
                                {item.userDetail[0].profilePic ? <img src={item.userDetail[0].profilePic} /> : `${item.userDetail[0].firstName.charAt(0).toUpperCase()}${item.userDetail[0].lastName.charAt(0).toUpperCase()}`}
                              </figure>
                              <figcaption>
                                <h4>{`${item.userDetail[0].firstName} ${item.userDetail[0].lastName}`}</h4>
                                <p>{item.comment}</p>
                              </figcaption>
                            </li>
                          </React.Fragment>
                        )
                      }) : ''}
                    </ul>
                  </div>
                </div>
              </div>
            }
          </Modal>
          
          <Modal className="modal" id="ReplyModal" show={commentModal} onHide={hidecommentModal}>
              <div className="modal-content">
                <div className="ReplyComment">
                  <h6>Comments</h6>
                  <a onClick={hidecommentModal} className="Close" data-dismiss="modal">&times;</a>
                  <h2>&nbsp;</h2>

                  <div className="ReplyCommentHead">
                    {multipleComents && multipleComents.length > 0 ? multipleComents.map((item, i) => (
                      <ul>
                        <li>
                          <figure>
                            {(profileData && profileData.firstName) && profileData.profilePic ? <img src={profileData.profilePic} /> : profileData.firstName ? `${profileData.firstName.charAt(0).toUpperCase()}${profileData.lastName.charAt(0).toUpperCase()}` : ''}
                          </figure>
                          <figcaption>
                            <h3>{profileData && profileData.firstName ? `${profileData.firstName} ${profileData.lastName}` : ''}</h3>
                            {item.comment ? <h5>{item.comment}</h5> : ''}
                            {item.images ? <img style={{ height: '100px' }} src={item.images} /> :
                              item.video ? <Player autoPlay muted fluid={false} height={100} width={200}>
                                <source src={item.video} />
                                <BigPlayButton position="center" />
                              </Player> : ''
                            }
                          </figcaption>
                        </li>
                      </ul>
                    ))
                      : ''}
                  </div>

                  <div className="ReplyCommentBody">
                    <figure>
                      {/* {(profileData && profileData.firstName) && profileData.profilePic ? <img src={profileData.profilePic} /> : profileData.firstName ? `${profileData.firstName.charAt(0).toUpperCase()}${profileData.lastName.charAt(0).toUpperCase()}` : ''} */}
                    </figure>

                    {(Object.keys(commentImages).length > 0 || Object.keys(commentImages).length > 0) ?
                      <span>

                        <div>
                          {commentImages && (particularPostId === commentIndex)  &&
                            <div><i className="pl-2 far fa-times-circle" onClick={() => removeCommentData('img')}></i>
                              <img style={{ height: '100px' }} src={commentImages} />
                            </div>}
                          {commentVideo && (particularPostId === commentIndex) &&
                            <div> <i className="pl-2 far fa-times-circle" onClick={() => removeCommentData('video')}></i>
                              <Player autoPlay muted fluid={false} height={100} width={200}>
                                <source src={commentVideo} />
                                <BigPlayButton position="center" />
                              </Player></div>}
                        </div>
                      </span>
                      :
                      <div className="EmojiBox">
                        <textarea rows="5" className="form-control" name="comment" value={comment} onChange={handleInputChange} placeholder="Add a comment"></textarea>
                        {emojiFlag ? <Picker onEmojiClick={handleEmojiChange} /> : ''}
                      </div>
                    }
                  </div>
                  <div className="ReplyCommentFooter">
                    <input
                      type='file'
                      id='multipleImage1'
                      onChange={(e) => onCommentFileHandler(e)}
                      onClick={e => e.target.value == null}
                      style={{ display: 'none' }}
                    />
                    <ul>
                      <li>
                        <a><label htmlFor='multipleImage1'><i className="fa fa-picture-o" onClick={() => updatePostId(commentIndex)}></i></label></a>
                      </li>
                      <li>
                        <a><label htmlFor='multipleImage1'><i className="fa fa-video-camera" onClick={() => updatePostId(commentIndex)}></i></label></a>
                      </li>
                      <li>
                        <i className="fas fa-grin" style={{ color: '#F8914E',fontSize:'22px',padding:'4px 0' }} onClick={() => handleEmojiPopup()}></i>
                      </li>
                    </ul>
                    {comment !== '' || commentImages !== '' || commentVideo !== '' ? <img style={{ width: '30px' }} src={addNew} onClick={() => addComment()} /> : ''}
                    <button disabled={comment === '' && commentImages === '' && commentVideo === '' && multipleComents.length === 0} onClick={replyComment}>Reply</button>
                  </div>
                </div>
              </div>

            </Modal>


        </div>

        <Footer />
      </div>
    </Fragment>
  );
}

export default StreetTalkSharePost