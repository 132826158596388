import { userProfileAction } from '../actionTypes'
import {Url} from '../../config/config'
import axios from 'axios'
import {isLoggedIn, multiPartData} from '../../utils'

const token = isLoggedIn('userData')

export const emptyUserData = () => ({
  type: userProfileAction.EMPTY_USER_DATA
})
export const getUserProfileInitiate = () => ({
  type: userProfileAction.GET_USER_PROFILE_INITIATE,
})

export const getUserProfileSuccess = (data) => ({
  type: userProfileAction.GET_USER_PROFILE_SUCCESS,
  payload: data
})

export const getUserProfileFailure = () => ({
  type: userProfileAction.GET_USER_PROFILE_FAILURE
})

export function getUserProfile(payload) {
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(getUserProfileInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/userProfile`, payload,{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(getUserProfileSuccess(data))
                  }
                  else{
                    dispatch(getUserProfileFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(getUserProfileFailure())
                  reject(err);
              })
      );
  }
}

export const updateUserProfileInitiate = () => ({
  type: userProfileAction.UPDATE_USER_PROFILE_INITIATE,
})

export const updateUserProfileSuccess = (data) => ({
  type: userProfileAction.GET_USER_PROFILE_SUCCESS,
  payload:data
})

export const updateUserProfileFailure = () => ({
  type: userProfileAction.UPDATE_USER_PROFILE_FAILURE
})


export function updateUserProfile(payload) {
  const updatedData = multiPartData(payload)
  const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(updateUserProfileInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/updateProfile`, updatedData,{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){

                      delete data.Data.password
                      delete data.Data.otpData
                      dispatch(updateUserProfileSuccess(data))
                  }
                  else{
                    dispatch(updateUserProfileFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(updateUserProfileFailure())
                  reject(err);
              })
      );
  }
}