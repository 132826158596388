import React, { useEffect, useState, useLayoutEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash' ;
import '../App.css'; 
import { Link } from "react-router-dom"; 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';  
import Form from 'react-bootstrap/Form'; 
import Button from 'react-bootstrap/Button'
import countryList from 'react-select-country-list';

import Header from './header';       
import Footer from './footer';
import Sidenav from './sidenav'; 

import Profile from "../assets/media/Profile.jpg";
import {getUserProfile, updateUserProfile, emptyUserData} from '../redux/actions/userProfileAction'
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2'
import CustomLoader from './customLoader';
import  {changeLanguge} from '../utils'
import moment from 'moment'


const initialState = {
	firstName: '',
	lastName:'',
	profilePic:'',
	emailId: '',
	imageReview:'',
	dateOfBirth:'',
	dateOfBirthError:'',
	firstNameEmpty:'',
	lastNameEmpty:'',
	invalidPhone:'',
	editEnable: false,
	phoneValue:'',
	phoneFormat:'',
	errorMsg:'',
	updatedData:{},
	bio:'',
	headerImage:'',
	language:'en'
}

const Dashboard = () => {
	const [iState, updateState] = useState(initialState)
	const dispatch = useDispatch()
	const userData = useSelector(state => state.userProfile)
	const options = useMemo(() => countryList().getData(), [])
	const {
		profileData,
		loader
	 } = userData
	const countryLabel = options && options.filter(d =>{
		return d.label === profileData.country
	})[0]
	
	const {
		firstName,
		lastName,
		profilePic,
		emailId,
		imageReview,
		firstNameEmpty,
		lastNameEmpty,
		invalidPhone,
		phoneValue,
		editEnable,
		errorMsg,
		phoneFormat,
		bio,
		language,
		headerImage,
		dateOfBirth,
		dateOfBirthError,
	} = iState

	useEffect(() => {
		dispatch(getUserProfile())
	},[])

	const googleTranslateElementInit = () => {
		var language  
		language = 	new window.google.translate.TranslateElement({ pageLanguage: 'en',includedLanguages: "en,ar,tr,ru,zh-CN,bg,nl,fr,de,hi,it,ja,", layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE }, 'google_translate_element');
		// new window.google.translate.TranslateElement({ pageLanguage: 'en', layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT }, 'google_translate_element')
	 }
	 
	 useEffect(() => {
		 var addScript = document.createElement('script');
		 addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
		 document.body.appendChild(addScript);
		 window.googleTranslateElementInit = googleTranslateElementInit;
		 
	 }, [])


	useEffect(async () => {
		if(!_.isEmpty(profileData)){
			const updateData = _.cloneDeep(iState)
			updateData.firstName = profileData.firstName
			updateData.lastName= profileData.lastName
			updateData.profilePic= profileData.profilePic
			updateData.imageReview=profileData.profilePic
			updateData.emailId = profileData.emailId
			updateData.phoneValue= profileData.phoneNumber ? `${profileData.countryCode}${profileData.phoneNumber}` : ''
			updateData.bio = profileData.bio ? profileData.bio :''
			updateData.dateOfBirth = profileData.dateOfBirth
			updateData.dateOfBirthError=''
			updateData.firstNameEmpty=''
			updateData.lastNameEmpty=''
			updateData.invalidPhone=''
			updateData.errorMsg=''
			updateData.editEnable=false
			updateData.updatedData={countryCode:profileData.countryCode || '', phoneNumber:profileData.phoneNumber || ''}
			// console.log('.firstName)',changeLanguge(profileData.firstName))

			// let data= await changeLanguge(profileData.firstName)
			// console.log(data)
			updateState (
				updateData
			)
		}

	},[profileData])

	const handleInputChange = (e) => {
		const {name, value} = e.target
		updateState({
			...iState, [name]: value,
			updatedData:{
				...iState.updatedData,[name]: value
			}
		})
	}

	const onFileHandler = (e) => {
		const file = e.target.files[0]
		if(file) {
			if(file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
				previewsFile(file)
				
			}else {
				toast.error('Only formats are allowed: jpeg, jpg, png files',{
					position: toast.POSITION.TOP_RIGHT
				})
			}
		} 
	}

	const previewsFile = (file) => {
		const reader = new FileReader()
		reader.addEventListener("load", () => {
       updateState({
				 ...iState, imageReview: reader.result,
				 updatedData:{
					 ...iState.updatedData,profilePic:file
				 }
			 })
		}, false)
		if(file) {
			reader.readAsDataURL(file)
		}
	}

	const handlePhoneInputChange = (value, country) => {
		const phoneNumber = value.replace(country.dialCode,'')
		if(value !== (phoneValue.replace('+',''))){
			updateState({
				...iState, phoneValue: value,phoneFormat:country.format,
				updatedData:{
					...iState.updatedData,
					countryCode:`+${country.dialCode}`,
					phoneNumber:phoneNumber,
				}
			})
		}
  };
	const handleValidation=()=>{

		let firstNameEmpty=''
		let lastNameEmpty=''
		let invalidPhone=''
		let dateOfBirthError=''
		let formIsValid=true;

		if(!firstName.trim()){
			firstNameEmpty="First Name can't be empty";
			formIsValid=false;
		}
		if(!lastName.trim()){
			lastNameEmpty="Last Name can't be empty";
			formIsValid=false;
		}
		if(!phoneValue.trim()){
			invalidPhone="Phone number can't be empty";
			formIsValid=false;
		}
		if(phoneValue.trim() && (phoneValue.length < phoneFormat.replace('+','').replace('(','').replace(')','').replace('-','').replace(/ /g,'').length)){
			invalidPhone='Phone number format is incorrect';
			formIsValid=false;
		}
		if(dateOfBirth){
			let thirteenYearsAgo = moment().subtract(13, "years");
			let birthday = moment(dateOfBirth);
	
			if (!birthday.isValid()) {
				dateOfBirthError="Invalid Date";
				formIsValid=false;   
			}
			else if (thirteenYearsAgo.isBefore(birthday)) {
				dateOfBirthError="Your age must be greater than 13";
				formIsValid=false;   
			}
		}
		updateState({
			...iState,firstNameEmpty,lastNameEmpty,errorMsg:'', invalidPhone,dateOfBirthError
		})
		return formIsValid;
	}
	const togleEdit =(flag) => {
		updateState({
			...iState, editEnable:flag
		})
	}
	const handleSubmit=( event)=>{
				event.preventDefault();
				let formIsValid=handleValidation();
				if(formIsValid){
				 dispatch(updateUserProfile(iState.updatedData)).then(res => {
					if (res.response_code == 200) {
							toast.success(res.message, {
									position: toast.POSITION.TOP_RIGHT
							});
							togleEdit(false)
					}else{
						updateState({
								...iState, errorMsg:res.message,
								firstNameEmpty:'',
								lastNameEmpty:'',
								invalidPhone:'',
								dateOfBirthError:''
							})
						}
					}).catch(err => {
								const message = err && err.response && err.response.data ? err.response.data.message : "Something went wrong."
								toast.error(message, {
										position: toast.POSITION.TOP_RIGHT
								});
						})
    }
}
	return (
		<div> 
			<div id="google_translate_element"></div>
			<Header/> 
				<div className="DashboardArea"> 
					<div className="container">
						<div className="row">
							<div className="col-sm-3">
								<Sidenav/> 
							</div>
							<div className="col-sm-9">
								 
								<div className="DashboardRight">
									<span style={{ color: "red" }}>{errorMsg}</span> 

									{loader ? 
                    <CustomLoader loading={loader} />: 
									<div className="ProfileEdit userProfile">
										<Form  autoComplete='off'>
											<figure>
												{!imageReview ?  <div>{`${firstName.charAt(0)}${lastName.charAt(0).toUpperCase()}`}</div> : <img src={imageReview} /> }
												<input
												  type='file'
													id='profileImage'
													onChange={onFileHandler}
													onClick={e => e.target.value == null}
													style={{display: 'none'}}
												/>
												{editEnable ? 
												<label htmlFor='profileImage'>
													<i className="fa fa-pencil"></i></label> : ''}
											</figure>

											<Form.Group> 
												<div>
													<span className="label"><i className="fa fa-user"></i></span>
													<Form.Control
													  type="text"
														placeholder="Enter First Name"
														name='firstName'
														value={firstName}
														onChange={handleInputChange}
														disabled={!editEnable}
														/>
												</div>
												<span style={{ color: "red",paddingLeft:'40px' }}>{firstNameEmpty}</span>
											</Form.Group>
											<Form.Group> 
												<div>
													<span className="label"><i className="fa fa-user"></i></span>
													<Form.Control
													  type="text"
														placeholder="Enter Last Name"
														name='lastName'
														value={lastName}
														onChange={handleInputChange}
														disabled={!editEnable}
														/>
												</div>
												<span style={{ color: "red",paddingLeft:'40px' }}>{lastNameEmpty}</span>
											</Form.Group>
											<Form.Group> 
												<div>
													<span className="label"><i className="fa fa-user"></i></span>
													<Form.Control
													  type="date"
														placeholder="Date of birth"
														name='dateOfBirth'
														value={dateOfBirth}
														onChange={handleInputChange}
														disabled={!editEnable}
														/>
												</div>
												<span style={{ color: "red",paddingLeft:'40px' }}>{dateOfBirthError}</span>
											</Form.Group>
											<Form.Group> 
												<div>
												<span className="label"><i className="fa fa-envelope"></i></span>
													<Form.Control
														type="email"
														placeholder="email"
														name='emailId'
														value={emailId}
														disabled
												/>
												</div>
											</Form.Group>
											<div className='customPhoneDiv'>
												<span className="label"><i className="fa fa-phone"></i></span>
												<div>
												<PhoneInput
												placeholder='Enter Phone Number'
												inputExtraProps={{
													name: "phoneValue",
													required: true,
													autoFocus: true
												}}
												disabled={!editEnable}
												country={countryLabel && countryLabel.value.toLowerCase()}
												value={phoneValue}
												onChange={handlePhoneInputChange}
											/>
												<span style={{ color: "red" }}>{invalidPhone}</span>
											</div>
											</div>
											<Form.Group> 
												<div>
												<span className="label"><i className="far fa-list-alt"></i></span>
													<textarea className="form-control textArea"
														rows="4" 
														className="form-control" 
														name="bio"
														value={bio}
														onChange={handleInputChange}
														autoComplete="off"
														disabled={!editEnable}
														placeholder="Tell me something about you">
													</textarea>
												</div>
											</Form.Group>
											{/* <Form.Group> 
												<div>
												<span className="label"><i className="fa fa-envelope"></i></span>
													<Form.Control
												/>
												<select value={language} onChange={handleLanguage}>
													<option value="en">English</option>
													<option value="hi">Hindi</option>
													<option value="ar">Arabic</option>
													<option value="zh">Chinese</option>
												</select>
												</div>
											</Form.Group> */}
												{editEnable ? 
												<Button
												variant='success'
												// type="submit"
												disabled={_.isEmpty(iState.updatedData)}
												onClick={handleSubmit}
											>
												Save
											</Button>: 
											<Button
												variant='primary'
												onClick={()=>togleEdit(true)}
											>
												Edit
											</Button>
											}
									</Form> 
									</div>
              }
										
								</div> 
							</div> 
						</div> 
					</div>
					</div> 

		<Footer/>
	</div>
	)
}
export default Dashboard 