import React,{useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux';
import '../App.css';
import { Link, useHistory } from "react-router-dom";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { toast } from 'react-toastify';
import PublicBlog from "../assets/media/public_blog.png"
import MyBlog from "../assets/media/my_blog.png"
import Blog from "../assets/media/blog.png"
import AddNew from "../assets/media/add_new.png"
import ShoppingBuddies from "../assets/media/shopping_buddy.png"
import Icon1 from "../assets/media/sidenav-1.png";
import Icon2 from "../assets/media/sidenav-2.png";
import Icon3 from "../assets/media/sidenav-3.png";
import Icon4 from "../assets/media/sidenav-4.png";
import Icon5 from "../assets/media/sidenav-5.png";
import Icon6 from "../assets/media/sidenav-6.png";
import Icon7 from "../assets/media/sidenav-7.png";
import Icon8 from "../assets/media/sidenav-8.png";
import Icon9 from "../assets/media/sidenav-9.png";
import Icon10 from "../assets/media/sidenav-10.png";
import Icon11 from "../assets/media/sidenav-11.png";
import Icon12 from "../assets/media/sidenav-12.png";
import product1 from "../assets/media/Product-1.jpg";
import product2 from "../assets/media/Product-2.jpg";
import product3 from "../assets/media/Product-3.jpg";
import product4 from "../assets/media/Product-4.jpg";
import { logOut } from '../utils';
import { logoutUser } from '../redux/actions/loginAction'
import {otherUserPost} from '../redux/actions/streetTalkAction'
import {
  notificationCount
} from '../redux/actions/streetTalkAction'

const Sidenav = () => {
	const history = useHistory()
	const dispatch = useDispatch()
  const postdata = useSelector(state => state.streetTalk)
  const userDataId = JSON.parse(window.localStorage.getItem("userData"))
	const {
    notificationCountData
  } = postdata
	const blogsPath = (path) => {
		history.push(path)
	}

	useEffect(() => {
		const interval = setInterval(() => {
			dispatch(notificationCount()).then((data) => {
        if (data.response_code == 401) {
				localStorage.clear()
          history.push('/')
		  
        }
      })
		}, 30000);
		return () => clearInterval(interval);
	}, []);

	const userPostDetail = (userId) => {
    dispatch(otherUserPost({ userId: userId }))
    window.localStorage.setItem("userId", JSON.stringify(userId))
  }

	return (
		<div>
			<div className="SidenavArea">
				<div className="SidenavTop">
					<ul>
					<li>
							<Link to="/street-talk">
								<span className="Icon"><i className="fa fa-home" style={{color:'#F8914E',fontSize:'23px'}} aria-hidden="true"></i></span>
									Home
								</Link>
						</li>
						<li onClick={() => userPostDetail(userDataId._id)}>
							<Link to="/street-talk-profile" >
								<span className="Icon"><img src={MyBlog} /></span>
									Profile
								</Link>
						</li>

						<li>
							<Link to="/change-password">
								<span className="Icon"><img src={Icon10} /></span>
									Settings
								</Link>
						</li>

						<li className="notiDot notiDotjcd">
						{notificationCountData && notificationCountData[0] && notificationCountData[0].postCount &&  <span className="countMain">{notificationCountData[0].postCount}</span>}
							<Link to="/notification">
								<span className="Icon"><i className="fas fa-bell" style={{color:'#F8914E ', fontSize:'20px'}} aria-hidden="true"></i></span>
									Notification
								</Link>
						</li>
					</ul>
				</div>

			</div>

		</div>
	)
}
export default Sidenav