import React from 'react'
import '../App.css';
import { Link, useHistory } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Banner from "../assets/media/pic.jpg";
import About from "../assets/media/pic1.jpg";

import Deals1 from "../assets/media/Deals-1.jpg";
import Deals2 from "../assets/media/Deals-2.jpg";
import Deals3 from "../assets/media/Deals-3.jpg";

import Header from './header';
import Footer from './footer';
import { isLoggedIn } from '../utils'

const Streetak_Landing = () => {
	const history = useHistory()
	const loginStatus = isLoggedIn('userData')

	const handleStreetalk = ()=>{
    if(loginStatus){
			history.push('/street-talk')
		} else {
			history.push('/street-talk-public')
		}
	}
  const commingSoong =()=> {
		history.push('/comming-soon')
	}
	return (
		<div>
			<div>
				<Header />
			</div>

			<section className="streettak">
				<img src={Banner} />
				<div className="street">
					<h2>Welcome to StreetTak</h2>
					<h6>Let’s Talk About It</h6>
					<div className="text-center mt-5">
						<button onClick={handleStreetalk} className="Button">Speak Freely</button>
					</div>
				</div>
			</section>

			<section className="aboutUs">
				<div className="container">
					<div className="AboutRow ">
						<div className="aboutLeft">
							<h3>Let’s Talk About it and Let’s You and Me.</h3>
							<p>The main focus of StreetTak is to provide a platform where all people can participate in a public conversation and speak freely and express their freedom of speech in safety without the fear of being “deplatformed, censured or bullied” and we do not encourage any type of violence. StreetTak is dedicated to enhancing the user’s experience by offering plenty of opportunities for users to engage with each other in an interesting and safe online environment.</p>
							<div className="aboutRight">
								<img src={About} />
							</div>
						</div>

					</div>
				</div>
			</section>

			<section>
				<div className="DealsArea">
					<div className="container">
						<div className="row">
							<div className="col-sm-4" onClick={commingSoong}>
								<div className="DealsBox">
									<figure>
										<img src={Deals1} />
									</figure>
									<figcaption>
										<h3>Create a Bargain Requirement</h3>
										<a>Create Kiosk <i className="fa fa-long-arrow-right"></i></a>
										<p>Comming soon...</p>
									</figcaption>
								</div>
							</div>

							<div className="col-sm-4" onClick={commingSoong}>
								<div className="DealsBox">
									<figure>
										<img src={Deals2} />
									</figure>
									<figcaption>
										<h3>Use Group Shopping Tool</h3>
										<a>See <i className="fa fa-long-arrow-right"></i></a>
										<p>Comming soon...</p>
									</figcaption>
								</div>
							</div>

							<div className="col-sm-4" onClick={commingSoong}>
								<div className="DealsBox">
									<figure>
										<img src={Deals3} />
									</figure>
									<figcaption>
										<h3>Vendors</h3>
										<a>Create Kiosk <i className="fa fa-long-arrow-right"></i></a>
										<p>Comming soon...</p>
									</figcaption>
								</div>
							</div>
						</div>
					</div>
					{/* <h3 style={{color:"green"}}>GroupBuy Marketplace In StreetTak, Join Bargains</h3> */}
				</div>
			</section>
			
			<Footer />
		</div>
	)
}
export default Streetak_Landing