import { forgetPasswordAction } from '../actionTypes'

const initialState = {
  loader: false,
  forgetPasswordStatus: false
}

export default function userLogin(state = initialState, {type, payload}) {
  switch(type) {
    case forgetPasswordAction.FORGOT_PASSWORD_INITIATE: {
      return {
        ...state, loader: true, forgetPasswordStatus: false
      }
    }
    case forgetPasswordAction.FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state, loader: false, forgetPasswordStatus: true
      }
    }
    case forgetPasswordAction.FORGOT_PASSWORD_FAILURE: {
      return {
        ...state, loader: false, forgetPasswordStatus: false
      }
    }
    default: 
      return state
  }
}