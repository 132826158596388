import React, {useState,useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom"; 
import '../App.css'; 
import { toast } from 'react-toastify';
import { Link } from "react-router-dom"; 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';  
import Form from 'react-bootstrap/Form'; 
import Button from 'react-bootstrap/Button'

import Header from './header';       
import Footer from './footer';
import Sidenav from './sidenav'; 
import {resetPassword} from '../redux/actions/setPasswordAction'
import {checkPassword} from '../utils'
import { InputGroup } from 'react-bootstrap';

const initialState={
	password:'',
	newPassword:'',
	compareNewPassword:'',
	passwordErr: '',
	newPasswordErr: '',
	compareNewPasswordErr:'',
	errorMsg:''
}
const Changepassword = () => {
	const history=useHistory();
	const [iState, updateState] = useState(initialState)
	const dispatch = useDispatch()
	let [showpassword, setShowPassword] = useState(true);
	let [showNewPassword, setShowNewPassword] = useState(true);
	let [showComparePassword, setShowComparePassword] = useState(true);
  const {
		password,
		newPassword,
		compareNewPassword,
		passwordErr,
		newPasswordErr,
		compareNewPasswordErr,
		errorMsg
	} = iState

	const handleValidation=()=>{
		let compareNewPasswordErr='';
		let passwordErr='';
		let newPasswordErr='';
		let formIsValid=true;

		if(!password.trim()){
			passwordErr="Password can't be empty";
			formIsValid=false;
		}
    if(!newPassword.trim()){
			newPasswordErr="New Password can't be empty";
			formIsValid=false;
		}
		if(!compareNewPassword.trim()){
			compareNewPasswordErr="Confirm Password can't be empty";
			formIsValid=false;
		}	
    if(newPassword && compareNewPassword && (newPassword !== compareNewPassword)){
			compareNewPasswordErr="Confirm password must be same as new password";
			formIsValid=false;
		}
		if(newPassword){
			let valid = checkPassword(newPassword)
			if(!valid) {
				newPasswordErr="Your password must contain at least one uppercase, lowercase, number, special character and length must be > 8."
			  formIsValid= false
			}
		}
		updateState({
			...iState, newPasswordErr, compareNewPasswordErr, passwordErr, errorMsg: ''
		})
		return formIsValid;
	}
		const handleInputChange = (e) => {
			const {name, value} = e.target
			updateState({
				...iState, [name]: value
			})
		}
	const handleSubmit = (e) => {
		e.preventDefault()
		let formIsValid=handleValidation()
		if(formIsValid) {
			const data = {password, newPassword, compareNewPassword}
     dispatch(resetPassword(data)).then(res => {
			if (res.response_code == 200) {
					toast.success(res.message, {
							position: toast.POSITION.TOP_RIGHT
					})

					history.push('/dashboard')
			}else{
				updateState({
					...iState, errorMsg:res.message,comparePasswordError:'', passwordError:''
				})
			}
	}).catch(err => {
			console.log(err,'err')
				// const message = err && err.response && err.response.data ? err.response.data.message : "Something went wrong."
				// toast.error(message, {
				// 		position: toast.POSITION.TOP_RIGHT
				// });
		});			
		}
	}

	const googleTranslateElementInit = () => {
		var language  
		language = 	new window.google.translate.TranslateElement({ pageLanguage: 'en',includedLanguages: "en,ar,tr,ru,zh-CN,bg,nl,fr,de,hi,it,ja,", layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE }, 'google_translate_element');
		// new window.google.translate.TranslateElement({ pageLanguage: 'en', layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT }, 'google_translate_element')
	 }
	 
	 useEffect(() => {
		 var addScript = document.createElement('script');
		 addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
		 document.body.appendChild(addScript);
		 window.googleTranslateElementInit = googleTranslateElementInit;
		 
	 }, [])

	    return(
			<div> 
			<div id="google_translate_element"></div>
		   <Header/> 
		    	<div className="DashboardArea"> 
		    		<div className="container">
		    			<div className="row">
		    				<div className="col-sm-3">
		    					<Sidenav/> 
		    				</div>
		    				<div className="col-sm-9"> 
		    					<div className="DashboardRight"> 
												<span style={{ color: "red" }}>{errorMsg}</span>
						    		<div className="ProfileEdit userProfile">
											<h1 className="Title">Change Password</h1>
						    			<Form onSubmit={handleSubmit} autoComplete='off'> 
										  	<Form.Group>
										  		<div className='changePassword'>
											  		<span className="label"><i className="fa fa-lock"></i></span>
														<InputGroup>
											    	<Form.Control 
												  		type={showpassword ? "password" : "text"}
															placeholder="Current Password"
															name='password'
															value={password}
															onChange={handleInputChange}
													/> 
													<InputGroup.Append>
														<InputGroup.Text id="basic-addon2">
															<a onClick={(e)=>{setShowPassword(!showpassword)}} style={{color: "#004AAD"}}  className="IconEye">
																<span>{showpassword ? <i className="fa fa-eye-slash" aria-hidden="true"></i> :
																<i className="fa fa-eye" aria-hidden="true"></i>
															}</span></a>
															</InputGroup.Text>
													</InputGroup.Append>
													</InputGroup>
										  		</div>
												<span style={{ color: "red", marginLeft:'37px' }}>{passwordErr}</span>
										  	</Form.Group>
											 <Form.Group> 
										  		<div className='changePassword'>
											  		<span className="label"><i className="fa fa-lock"></i></span>
														<InputGroup>
											    	<Form.Control
												  		type={showNewPassword ? "password" : "text"}
															placeholder="New Password"
															name='newPassword'
															value={newPassword}
															onChange={handleInputChange}
													  /> 
														<InputGroup.Append>
															<InputGroup.Text id="basic-addon2">
																<a onClick={(e)=>{setShowNewPassword(!showNewPassword)}} style={{color: "#004AAD"}}  className="IconEye">
																	<span>{showNewPassword ? <i className="fa fa-eye-slash" aria-hidden="true"></i> :
																	<i className="fa fa-eye" aria-hidden="true"></i>
																}</span></a>
																</InputGroup.Text>
														</InputGroup.Append>
														</InputGroup>
										  		</div>
												<span style={{ color: "red", marginLeft:'37px' }}>{newPasswordErr}</span>
										  	</Form.Group>
                        <Form.Group> 
										  		<div className='changePassword'>
											  		<span className="label"><i className="fa fa-lock"></i></span>
														<InputGroup>
											    	<Form.Control
															type={showComparePassword ? "password":'text'}
														placeholder="Confirm Password"
														name='compareNewPassword'
														value={compareNewPassword}
														onChange={handleInputChange}
													/> 
													<InputGroup.Append>
														<InputGroup.Text id="basic-addon2">
															<a onClick={(e)=>{setShowComparePassword(!showComparePassword)}} style={{color: "#004AAD"}}  className="IconEye">
																<span>{showComparePassword ? <i className="fa fa-eye-slash" aria-hidden="true"></i> :
																<i className="fa fa-eye" aria-hidden="true"></i>
															}</span></a>
															</InputGroup.Text>
													</InputGroup.Append>
													</InputGroup>
										  		</div>
												<span style={{ color: "red", marginLeft:'37px' }}>{compareNewPasswordErr}</span>
										  	</Form.Group>
										  	<Form.Group> 
													<div>
														<span className="label"></span>
														<button className="Save" type='submit'>Save</button>
													</div>
										  	</Form.Group>			
																			   
										</Form> 
						    		</div>
		    					</div> 
		    				</div> 
		    			</div> 
		    		</div>
		       	</div> 

			<Footer/>
		</div>
	)
}
export default Changepassword 