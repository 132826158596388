import { signUpAction } from '../actionTypes'

const initialState = {
  signUpUserData: {},
  loader: false
}

export default function userSignUp(state = initialState, {type, payload}) {
  switch(type) {
    case signUpAction.USER_SIGNUP_INITIATE: {
      return {
        ...state, loader: true, signUpUserData: {}
      }
    }
    case signUpAction.USER_SIGNUP_SUCCESS: {
      return {
        ...state, loader: false
      }
    }
    case signUpAction.USER_SIGNUP_FAILURE: {
      return {
        ...state, loader: false
      }
    }
    case signUpAction.VERIFY_OTP_INITIATE: {
      return {
        ...state, loader: true
      }
    }
    case signUpAction.VERIFY_OTP_SUCCESS: {
      return {
        ...state, loader: false
      }
    }
    case signUpAction.VERIFY_OTP_FAILURE: {
      return {
        ...state, loader: false
      }
    }
    default: 
      return state
  }
}