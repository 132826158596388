import React, { useState, Fragment, useEffect, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../App.css";
import _, { remove } from 'lodash'
import { Link, useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { toast } from 'react-toastify';
import streetTalkLogo from "../assets/media/streettalk.svg"

import Header from "./header";
import Footer from "./footer";
import CustomLoader from './customLoader';
import { sendRequestBuddies, deletePendingBuddies } from '../redux/actions/buddiesAction'
import { getUserProfile } from '../redux/actions/userProfileAction'
import {
  emptyUserLikeData,
  majorNews,
  peopleMayKnow,
  streetTakNews,
  allfollowing,
  allfollowers,
  otherUserPost,
  particularPost,
  notificationCount,
  follwersRemoveRequest
} from '../redux/actions/streetTalkAction'


const initialState = {
  unfollowModal:false,
  scrollFlag: false,
  followUserId:'',
  removeModal:false
}
const FollowFollowing = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  let scrollRef = useRef(0)
  const [iState, updateState] = useState(initialState)
  const userData = useSelector(state => state.userProfile)
  const postdata = useSelector(state => state.streetTalk)
  const userDataId = JSON.parse(window.localStorage.getItem("userData"))
  const followersCount = JSON.parse(window.localStorage.getItem("Followerscount"))
  const followingCount = JSON.parse(window.localStorage.getItem("Followingcount"))
  const [key, setKey] = useState("home");

  const {
    unfollowModal,
    scrollFlag,
    followUserId,
    removeModal
  } = iState
  const {
    profileData,
    loader
  } = userData

  const {
    postLoader,
    peopleMayKnowData,
    majorNewsData,
    streetTakNewsData,
    allFollowingData,
    allFollowersData,
    notificationCountData
  } = postdata

  useEffect(() => {
    dispatch(getUserProfile())
    dispatch(peopleMayKnow())
    dispatch(majorNews())
    dispatch(streetTakNews())
    dispatch(allfollowing())
    dispatch(allfollowers())
    dispatch(notificationCount())
    return (() => {
      dispatch(emptyUserLikeData())
    });
  }, [])

  useEffect(() => {
		const interval = setInterval(() => {
			dispatch(notificationCount()) .then((data) => {
        if (data.response_code == 401) {
				localStorage.clear()
          history.push('/')
        }
      })
		}, 30000);
		return () => clearInterval(interval);
	}, []);

  const handleRequest = (e, id, type) => {
    e.preventDefault()
    if (type === 'follow') {
      dispatch(sendRequestBuddies({ reciverId: id })).then(res => {
        if (res.response_code == 200) {
          toast.success(res.message, {
            position: toast.POSITION.TOP_RIGHT
          });
          dispatch(allfollowing())
          dispatch(allfollowers())
          dispatch(peopleMayKnow())
          dispatch(getUserProfile())
        }
      }).catch(err => {
        const message = err && err.response && err.response.data ? err.response.data.message : "Something went wrong."
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT
        });
      })
    } else {
      updateState({ ...iState, unfollowModal: true, followUserId: id })
    }
  }

  const userPostDetail = (userId) => {
    dispatch(otherUserPost({ userId: userId }))
    window.localStorage.setItem("userId", JSON.stringify(userId))
  }

  const particularPostDetail = (id, userId) => {
    dispatch(particularPost({ postId: id }))
    window.localStorage.setItem("postId", JSON.stringify(id))
    history.push(`/street-talk-details/${id}`)
  }

  const handlePreviewUrl = (url) => {
    const win = window.open(url, "_blank");
    win.focus();
  }
  const handlePath = (path) => {
    history.push(path)
  }

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (scrollRef.current <= 1590 && scrollFlag) {
        updateState({ ...iState, scrollFlag: false, })
      }
      if (scrollRef.current >= 1590 && !scrollFlag) {
        updateState({ ...iState, scrollFlag: true, })
      }
      scrollRef.current = currentScrollY;

    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollFlag]);

  const handleTab = (key) => {
    if (key === 'home') {
      setKey('home')
      dispatch(allfollowing())
    } else {
      setKey('profile')
      dispatch(allfollowers())
    }
  }

  const openUnfollowModal =(id)=> {
    updateState({
      ...iState,followUserId:id, unfollowModal:true
    })

  }
  const handleunfollowModal = (type) => {
    if (type === 'cancel') {
      updateState({ ...iState, unfollowModal: false, followUserId: '' })
    } else if (type === 'yes') {
      dispatch(deletePendingBuddies({ reciverId: followUserId })).then(res => {
        if (res.response_code == 200) {
          updateState({ ...iState, unfollowModal: false, followUserId: '' })
          dispatch(allfollowing())
          dispatch(getUserProfile())
        }
      }).catch(err => {
        const message = err && err.response && err.response.data ? err.response.data.message : "Something went handleSuit"
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT
        });
      })
    }
  }
  const openRemoveModal = (id)=>{
    updateState({
      ...iState, followUserId:id,removeModal:true
    })
  }
  const handleRemoveModal = (type) => {
    console.log('iddddddddddddd',followUserId)
    if (type === 'cancel') {
      updateState({ ...iState, removeModal: false, followUserId: '' })
    } else if (type === 'yes') {
      dispatch(follwersRemoveRequest({ reciverId: followUserId })).then(res => {
        if (res.response_code == 200) {
          updateState({ ...iState, removeModal: false, followUserId: '' })
          dispatch(allfollowers())
          dispatch(getUserProfile())
        }
      }).catch(err => {
        const message = err && err.response && err.response.data ? err.response.data.message : "Something went handleSuit"
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT
        });
      })
    }
  }
  return (
    <Fragment>
      <div>
        <Header />
        <div className="TalkArea">
          <div className="Commantabs">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => handleTab(k)}
            >
              <Tab eventKey="home" title="Following">
                <div className="container StickyProfile">
                  <div className="row">
                    <div className="col-sm-3 PostionSticky" >
                      <div className="TalkLeft">
                        <h4><img src={streetTalkLogo} /></h4>
                        <div className="followerInner">
                          <div className="followerDet">
                            <div className="follwerLeft">
                              {(profileData && profileData.firstName) && profileData.profilePic ? <img src={profileData.profilePic} /> : profileData.firstName ? `${profileData.firstName.charAt(0).toUpperCase()}${profileData.lastName.charAt(0).toUpperCase()}` : ''}
                            </div>
                            <div className="follwerRight">
                              <h2>{profileData && profileData.firstName && profileData.firstName ? `${profileData.firstName} ${profileData.lastName}` : ''}</h2>
                              <p>{profileData && profileData.uniqueId && profileData.uniqueId}</p>
                              <h6>{followersCount} Following, {followingCount} Followers</h6>
                            </div>
                          </div>
                        </div>
                        <ul>
                          <div className='streettakMenu'>
                            <i className="fa fa-home" aria-hidden="true"></i>
                            <li onClick={() => handlePath('/street-talk')}>
                              <a>Home</a>
                            </li>
                          </div>
                          <div className='streettakMenu'>
                            <i className="fa fa-user" aria-hidden="true"></i>
                            <Link to="/street-talk-profile" onClick={() => userPostDetail(profileData._id)}>
                              <li>
                                <a>Profile</a>
                              </li>
                            </Link>
                          </div>
                          <div className='streettakMenu'>
                            <i className="fas fa-cog"></i>
                            <li onClick={() => handlePath('/change-password')}>
                              <a>Settings</a>
                            </li>
                          </div>
                          <div className='streettakMenu notiDot'>
                            {notificationCountData && notificationCountData[0] && notificationCountData[0].postCount &&  <span className="countMain">{notificationCountData[0].postCount}</span>}
                            <i className="fas fa-bell"></i>
                            <li onClick={() => handlePath('/notification')}>
                              <a>Notification</a>
                            </li>
                          </div>
                          {/* <div className='streettakMenu'>
                        <i className="fas fa-comment-alt"></i>
                        <li onClick={() => handlePath('/chat')}>
                          <a>Message</a>
                        </li>
                       
                        </div> */}
                        </ul>
                      </div>
                      {majorNewsData && majorNewsData.length > 0 &&
                          <div className="NewsBox">
                            <h4>StreetTak Major News</h4>
                            <article onClick={() => particularPostDetail(majorNewsData[0]._id)}>
                              <aside>
                                {majorNewsData[0].images[0] ? <img src={majorNewsData[0].images[0]} /> :<h6>{majorNewsData[0].caption}</h6>}
                              </aside>
                              <figure>
                                {majorNewsData[0].usersDetail[0].profilePic ? <img src={majorNewsData[0].usersDetail[0] && majorNewsData[0].usersDetail[0].profilePic} /> :
                                  `${majorNewsData[0].usersDetail[0].firstName.charAt(0).toUpperCase()}${majorNewsData[0].usersDetail[0].lastName.charAt(0).toUpperCase()}`}
                              </figure>
                              <figcaption>
                                <h5>{`${majorNewsData[0].usersDetail[0].firstName} ${majorNewsData[0].usersDetail[0].lastName}`}</h5>
                                {/* <p>@GameHuntress</p> */}
                              </figcaption>
                            </article>
                          </div>}
                    </div>

                    <div className="col-sm-6 padding_none">
                      <div className="PostArea">
                        {allFollowingData && allFollowingData.length > 0 && allFollowingData.map((item, i) => (

                          <div className="PostBox">
                            <div className="folowMain">
                              <div className="folow">
                              <Link to="/street-talk-profile" onClick={() => userPostDetail(item.usersDetail[0]._id)}>
                                <div className="folowImg">
                                  {item.usersDetail[0] && item.usersDetail[0].profilePic ? <img src={item.usersDetail[0].profilePic} /> : item.usersDetail[0].firstName ? `${item.usersDetail[0].firstName.charAt(0).toUpperCase()}${item.usersDetail[0].lastName.charAt(0).toUpperCase()}` : ''}
                                </div>
                                </Link>
                                <div className="folowText" >
                                <Link to="/street-talk-profile" onClick={() => userPostDetail(item.usersDetail[0]._id)}>
                                  <h3>{`${item.usersDetail[0].firstName} ${item.usersDetail[0].lastName}`} <span>{item.usersDetail[0].uniqueId}</span></h3>
                                  </Link>
                                </div>
                              </div>
                              <div className="folowingLinnk" onClick={()=>openUnfollowModal(item.usersDetail[0]._id)}>
                                <a>Unfollow</a>
                              </div>
                            </div>
                            <div className="folowWord">
                              <p>{item.usersDetail[0].bio}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>


                    <div className="col-sm-3 BottomSticky" id={scrollFlag ? 'peopleKnowBox' : ''} >
                      <div className="TalkRight" >
                        <div className="Search" >
                          <form>
                            <span>
                              <i className="fa fa-search"></i>
                            </span>
                            <input type="text" placeholder="Search" />
                          </form>
                        </div>
                        {majorNewsData && majorNewsData.length > 0 &&
                          <div className="NewsBox">
                            <h4>StreetTak Major News</h4>
                            <article onClick={() => particularPostDetail(majorNewsData[0]._id)}>
                              <aside>
                                <img src={majorNewsData[0].images[0]} />
                              </aside>
                              <figure>
                                {majorNewsData[0].usersDetail[0].profilePic ? <img src={majorNewsData[0].usersDetail[0] && majorNewsData[0].usersDetail[0].profilePic} /> :
                                  `${majorNewsData[0].usersDetail[0].firstName.charAt(0).toUpperCase()}${majorNewsData[0].usersDetail[0].lastName.charAt(0).toUpperCase()}`}
                              </figure>
                              <figcaption>
                                <h5>{`${majorNewsData[0].usersDetail[0].firstName} ${majorNewsData[0].usersDetail[0].lastName}`}</h5>
                                {/* <p>@GameHuntress</p> */}
                              </figcaption>
                            </article>
                          </div>}
                        <div className="NewsBox NewsFeed">
                          <h4>News</h4>
                          {streetTakNewsData && streetTakNewsData.Data && streetTakNewsData.Data.length > 0 ? streetTakNewsData.Data.map((item, i) => (
                            <article onClick={() => handlePreviewUrl(item.url)}>
                              <aside>
                                <img src={item.urlToImage} />
                              </aside>
                              <figcaption>
                                <h5>{item.title}</h5>
                              </figcaption>
                            </article>
                          )) : ''}
                        </div>


                        <div className="PeopleBox" >
                          <h4>People You Might Know</h4>

                          {peopleMayKnowData && peopleMayKnowData.length > 0 && peopleMayKnowData.map((item, i) => (
                            <ul>
                              <li>
                                <figure>
                                  {item.profilePic ? <img src={item.profilePic} /> : `${item.firstName.charAt(0).toUpperCase()}${item.lastName.charAt(0).toUpperCase()}`}
                                </figure>
                                <figcaption>
                                  <h5>{`${item.firstName} ${item.lastName}`}</h5>
                                  <h6>{item.uniqueId}</h6>
                                </figcaption>
                                <a className="add-friend">
                                  <i className="fa fa-plus" onClick={(e) => handleRequest(e, item._id, 'follow')}></i>
                                </a>
                              </li>
                            </ul>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="clear"></div>

                  </div>
                </div>
              </Tab>
              <Tab eventKey="profile" title="Followers">
                <div className="container StickyProfile">
                  <div className="row">
                    <div className="col-sm-3 PostionSticky" >
                      <div className="TalkLeft">
                        <h4><img src={streetTalkLogo} /></h4>
                        <div className="followerInner">
                          <div className="followerDet">
                            <div className="follwerLeft">
                              {(profileData && profileData.firstName) && profileData.profilePic ? <img src={profileData.profilePic} /> : profileData.firstName ? `${profileData.firstName.charAt(0).toUpperCase()}${profileData.lastName.charAt(0).toUpperCase()}` : ''}
                            </div>
                            <div className="follwerRight">
                              <h2>{profileData && profileData.firstName && profileData.firstName ? `${profileData.firstName} ${profileData.lastName}` : ''}</h2>
                              <p>{profileData && profileData.uniqueId && profileData.uniqueId}</p>
                              <h6>{followersCount} Following, {followingCount} Followers</h6>
                            </div>
                          </div>
                        </div>
                        <ul>
                          <div className='streettakMenu'>
                            <i className="fa fa-home" aria-hidden="true"></i>
                            <li onClick={() => handlePath('/street-talk')}>
                              <a>Home</a>
                            </li>
                          </div>
                          <div className='streettakMenu'>
                            <i className="fa fa-user" aria-hidden="true"></i>
                            <Link to="/street-talk-profile" onClick={() => userPostDetail(profileData._id)}>
                              <li>
                                <a>Profile</a>
                              </li>
                            </Link>
                          </div>
                          <div className='streettakMenu'>
                            <i className="fas fa-cog"></i>
                            <li onClick={() => handlePath('/change-password')}>
                              <a>Settings</a>
                            </li>
                          </div>
                          <div className='streettakMenu notiDot'>
                            {notificationCountData && notificationCountData[0] && notificationCountData[0].postCount &&  <span className="countMain">{notificationCountData[0].postCount}</span>}
                            <i className="fas fa-bell"></i>
                            <li onClick={() => handlePath('/notification')}>
                              <a>Notification</a>
                            </li>
                          </div>
                          {/* <div className='streettakMenu'>
                        <i className="fas fa-comment-alt"></i>
                        <li onClick={() => handlePath('/chat')}>
                          <a>Message</a>
                        </li>
                       
                        </div> */}
                        </ul>
                       
                      </div>
                      {majorNewsData && majorNewsData.length > 0 &&
                          <div className="NewsBox">
                            <h4>StreetTak Major News</h4>
                            <article onClick={() => particularPostDetail(majorNewsData[0]._id)}>
                              <aside>
                                {majorNewsData[0].images[0] ? <img src={majorNewsData[0].images[0]} /> :<h6>{majorNewsData[0].caption}</h6>}
                              </aside>
                              <figure>
                                {majorNewsData[0].usersDetail[0].profilePic ? <img src={majorNewsData[0].usersDetail[0] && majorNewsData[0].usersDetail[0].profilePic} /> :
                                  `${majorNewsData[0].usersDetail[0].firstName.charAt(0).toUpperCase()}${majorNewsData[0].usersDetail[0].lastName.charAt(0).toUpperCase()}`}
                              </figure>
                              <figcaption>
                                <h5>{`${majorNewsData[0].usersDetail[0].firstName} ${majorNewsData[0].usersDetail[0].lastName}`}</h5>
                                {/* <p>@GameHuntress</p> */}
                              </figcaption>
                            </article>
                          </div>}
                    </div>

                    <div className="col-sm-6 padding_none">
                      <div className="PostArea">
                        {allFollowersData && allFollowersData.length > 0 && allFollowersData.map((item, i) => (
                        <div className="PostBox">
                          <div className="folowMain">
                            <div className="folow">
                            <Link to="/street-talk-profile" onClick={() => userPostDetail(item.usersDetail[0]._id)}>
                              <div className="folowImg">
                                {item.usersDetail[0] && item.usersDetail[0].profilePic ? <img src={item.usersDetail[0].profilePic} /> : item.usersDetail[0].firstName ? `${item.usersDetail[0].firstName.charAt(0).toUpperCase()}${item.usersDetail[0].lastName.charAt(0).toUpperCase()}` : ''}
                              </div>
                              </Link>
                              <div className="folowText">
                              <Link to="/street-talk-profile" onClick={() => userPostDetail(item.usersDetail[0]._id)}>
                                <h3>{`${item.usersDetail[0].firstName} ${item.usersDetail[0].lastName}`} <span>{item.usersDetail[0].uniqueId}</span></h3>
                                </Link>
                              </div>
                            </div>
                            <div className="folowLinnk" onClick={()=> openRemoveModal(item.usersDetail[0]._id)}>
                              <a>Remove</a>
                            </div>
                          </div>
                          <div className="folowWord">
                            <p>{item.usersDetail[0].bio}</p>
                          </div>
                        </div>
                      ))}
                      </div>
                    </div>

                    <div className="col-sm-3 BottomSticky" id={scrollFlag ? 'peopleKnowBox' : ''} >
                      <div className="TalkRight" >
                        <div className="Search" >
                          <form>
                            <span>
                              <i className="fa fa-search"></i>
                            </span>
                            <input type="text" placeholder="Search" />
                          </form>
                        </div>
                        {majorNewsData && majorNewsData.length > 0 &&
                          <div className="NewsBox">
                            <h4>StreetTak Major News</h4>
                            <article onClick={() => particularPostDetail(majorNewsData[0]._id)}>
                              <aside>
                                <img src={majorNewsData[0].images[0]} />
                              </aside>
                              <figure>
                                {majorNewsData[0].usersDetail[0].profilePic ? <img src={majorNewsData[0].usersDetail[0] && majorNewsData[0].usersDetail[0].profilePic} /> :
                                  `${majorNewsData[0].usersDetail[0].firstName.charAt(0).toUpperCase()}${majorNewsData[0].usersDetail[0].lastName.charAt(0).toUpperCase()}`}
                              </figure>
                              <figcaption>
                                <h5>{`${majorNewsData[0].usersDetail[0].firstName} ${majorNewsData[0].usersDetail[0].lastName}`}</h5>
                                {/* <p>@GameHuntress</p> */}
                              </figcaption>
                            </article>
                          </div>}
                        <div className="NewsBox NewsFeed">
                          <h4>News</h4>
                          {streetTakNewsData && streetTakNewsData.Data && streetTakNewsData.Data.length > 0 ? streetTakNewsData.Data.map((item, i) => (
                            <article onClick={() => handlePreviewUrl(item.url)}>
                              <aside>
                                <img src={item.urlToImage} />
                              </aside>
                              <figcaption>
                                <h5>{item.title}</h5>
                              </figcaption>
                            </article>
                          )) : ''}
                        </div>


                        <div className="PeopleBox" >
                          <h4>People You Might Know</h4>

                          {peopleMayKnowData && peopleMayKnowData.length > 0 && peopleMayKnowData.map((item, i) => (
                            <ul>
                              <li>
                                <figure>
                                  {item.profilePic ? <img src={item.profilePic} /> : `${item.firstName.charAt(0).toUpperCase()}${item.lastName.charAt(0).toUpperCase()}`}
                                </figure>
                                <figcaption>
                                  <h5>{`${item.firstName} ${item.lastName}`}</h5>
                                  <h6>{item.uniqueId}</h6>
                                </figcaption>
                                <a className="add-friend">
                                  <i className="fa fa-plus" onClick={(e) => handleRequest(e, item._id, 'follow')}></i>
                                </a>
                              </li>
                            </ul>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="clear"></div>

                  </div>
                </div>
              </Tab>

            </Tabs>

          </div>
          <div className="ModalBox">
          <Modal id="unFollowModal" show={unfollowModal} onHide={handleunfollowModal}>
            <div className="ReactionBox">
              <div className="ReactionHead">
                <h4>Are you sure you Want to Unfollow?</h4>
              </div>
              <div className="ReactionBody">
                <div>
                  <button onClick={() => handleunfollowModal('cancel')}>Cancel</button>
                  <button onClick={() => handleunfollowModal('yes')}>Yes</button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
        <div className="ModalBox">
          <Modal id="removeModal" show={removeModal} onHide={handleRemoveModal}>
            <div className="ReactionBox">
              <div className="ReactionHead">
                <h4>Are you sure you Want to Remove?</h4>
              </div>
              <div className="ReactionBody">
                <div>
                  <button onClick={() => handleRemoveModal('cancel')}>Cancel</button>
                  <button onClick={() => handleRemoveModal('yes')}>Yes</button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
        </div>
        <Footer />
      </div>
    </Fragment>

  )
}

export default FollowFollowing