import React, {useState,useEffect,Fragment } from 'react'; 
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import config from '../config/config';
import { useHistory } from "react-router-dom"; 
import '../App.css'; 
import { Link } from "react-router-dom"; 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'; 
import Form from 'react-bootstrap/Form'; 
import Button from 'react-bootstrap/Button'
import Biglogo from "../assets/media/gbLogo.png";
import Header from './header';       
import Footer from './footer';
import {changePassword} from '../redux/actions/setPasswordAction'
import {checkPassword} from '../utils'
import { InputGroup } from 'react-bootstrap';
import streetTalk from "../assets/media/streettalk.svg"


const initialState = {
	newPassword: '',
	compareNewPassword: '',
	comparePasswordError: '',
	passwordError: '',
	errorMsg:''
}
const ForgetPassword = () => {
 	let notify = () => toast("Wow so easy!");
 	const history=useHistory();
	 const dispatch = useDispatch()
	 const [iState, updateState] = useState(initialState)
	 let [showpassword, setShowPassword] = useState(true);
	 let [showNewPassword, setShowNewPassword] = useState(true);
	 const userState  = useSelector(state => state.login)
	 const {
		newPassword,
		compareNewPassword,
		comparePasswordError,
		passwordError,
		errorMsg
	 } = iState

 	let handleValidation=()=>{
		let comparePasswordError='';
		let passwordError='';
		let formIsValid=true;

    if(!newPassword.trim()){
			passwordError="New Password can't be empty";
			formIsValid=false;
		}
		if(!compareNewPassword.trim()){
			comparePasswordError="Confirm Password can't be empty";
			formIsValid=false;
		}	
    if(newPassword && compareNewPassword && (newPassword !== compareNewPassword)){
			comparePasswordError="Confirm password must be same as new password";
			formIsValid=false;
		}
		if(newPassword){
			let valid = checkPassword(newPassword)
			if(!valid) {
				passwordError="Your password must contain at least one uppercase, lowercase, number, special character and length must be > 8."
			  formIsValid= false
			}
		}
		updateState({
			...iState, comparePasswordError, passwordError, errorMsg: ''
		})
		return formIsValid;
	}
const handleChange = (e) => {
	const {name, value} = e.target
	updateState({
		...iState, [name]: value
	})
}
	const handleSubmit = (e) => {
		e.preventDefault()
		let id = JSON.parse(localStorage.getItem('signupData')).id
		let formIsValid=handleValidation()
		if(formIsValid) {
			const data = {_id:id,newPassword, compareNewPassword}
     dispatch(changePassword(data)).then(res => {
			if (res.response_code == 200) {
					toast.success(res.message, {
							position: toast.POSITION.TOP_RIGHT
					});
					history.push('/login')
					window.localStorage.removeItem('signupData');
			}else{
				updateState({
					...iState, errorMsg:res.message,comparePasswordError:'', passwordError:''
				})
			}
	}).catch(err => {
			console.log(err,'err')
				// const message = err && err.response && err.response.data ? err.response.data.message : "Something went wrong."
				// toast.error(message, {
				// 		position: toast.POSITION.TOP_RIGHT
				// });
		});			
		}
	}

		return (
		<div> 
			<Header/>
				
			<section>
				<div className="LoginArea">
					<Container> 
							<Row>
								<Col sm={6}>
									<div className="LoginLeft" style={{margin: "40px 0"}}>
											<img src={streetTalk} />
									</div>
								</Col>
								<Col sm={6}>
									<div className="LoginRight">
										<h3>Reset Password</h3>
										<h2>At vero eos et accusamus et iusto odio dignissimos ducimus qui</h2>
						
										<span style={{ color: "red" }}>{errorMsg}</span>
										<Form onSubmit={handleSubmit} autoComplete='off'>
											<Form.Group> 
												<InputGroup>
												<Form.Control
												type={showpassword ? "password" : "text"}
												placeholder="New Password"
												name='newPassword'
												value={newPassword}
												onChange={handleChange}
												/><InputGroup.Append>
												<InputGroup.Text id="basic-addon2">
													<a onClick={(e)=>{setShowPassword(!showpassword)}} style={{color: "#004AAD"}}  className="IconEye">
														<span>{showpassword ? <i className="fa fa-eye-slash" aria-hidden="true"></i> :
														<i className="fa fa-eye" aria-hidden="true"></i>
													}</span></a>
													</InputGroup.Text>
											</InputGroup.Append>
											</InputGroup>
												<span style={{ color: "red" }}>{passwordError}</span>
											</Form.Group>
											
											<Form.Group> 
												<InputGroup>
												<Form.Control
												  type={showNewPassword ? "password" : "text"}
													placeholder="Confirm Password"
													name='compareNewPassword'
													value={compareNewPassword}
													onChange={handleChange}
													/>
													<InputGroup.Append>
														<InputGroup.Text id="basic-addon2">
															<a onClick={(e)=>{setShowNewPassword(!showNewPassword)}} style={{color: "#004AAD"}}  className="IconEye">
																<span>{showNewPassword ? <i className="fa fa-eye-slash" aria-hidden="true"></i> :
																<i className="fa fa-eye" aria-hidden="true"></i>
															}</span></a>
															</InputGroup.Text>
													</InputGroup.Append>
													</InputGroup>
													<span style={{ color: "red" }}>{comparePasswordError}</span>
											</Form.Group>
											<h6><Button type='submit'>Reset Password</Button></h6> 
											
									</Form> 
									</div>
								</Col>
							</Row> 
					</Container>
				</div>
			</section> 

		<Footer/>
	</div>
		)
	}
export default ForgetPassword