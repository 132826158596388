import { buddiesAction } from '../actionTypes'

const initialState = {
  loader: false,
  allBuddiesData:[],
  searchBuddies:[],
  searchLoader: false,
  sendRequestLoader: false,
  pendingList: [],
  pendingListLoader: false,
  acceptLoader: false,
  deleteLoader: false,
  deleteBuddiesLoader: false
}

export default function buddies(state = initialState, {type, payload}) {
  switch(type) {
    case buddiesAction.GET_ALL_BUDDIES_INITIATE: {
      return {
        ...state, loader: true, allBuddiesData:[],
      }
    }
    case buddiesAction.GET_ALL_BUDDIES_SUCCESS: {
      return {
        ...state, loader: false, allBuddiesData: payload
      }
    }
    case buddiesAction.GET_ALL_BUDDIES_FAILURE: {
      return {
        ...state, loader: false
      }
    }
    case buddiesAction.SEARCH_BUDDIES_INITIATE: {
      return {
        ...state, searchLoader: true, searchBuddies:[]
      }
    }
    case buddiesAction.SEARCH_BUDDIES_SUCCESS: {
      return {
        ...state, searchLoader: false, searchBuddies: payload.Data
      }
    }
    case buddiesAction.SEARCH_BUDDIES_FAILURE: {
      return {
        ...state, searchLoader: false
      }
    }
    case buddiesAction.SEND_REQUEST_BUDDIES_INITIATE: {
      return {
        ...state, sendRequestLoader: true
      }
    }
    case buddiesAction.SEND_REQUEST_BUDDIES_SUCCESS: {
      return {
        ...state, sendRequestLoader: false
      }
    }
    case buddiesAction.SEND_REQUEST_BUDDIES_FAILURE: {
      return {
        ...state, sendRequestLoader: false
      }
    }
    case buddiesAction.PENDING_BUDDIES_INITIATE: {
      return {
        ...state, pendingListLoader: true, pendingList: []
      }
    }
    case buddiesAction.PENDING_BUDDIES_SUCCESS: {
      return {
        ...state, pendingListLoader: false, pendingList: payload
      }
    }
    case buddiesAction.PENDING_BUDDIES_FAILURE: {
      return {
        ...state, pendingListLoader: false
      }
    }
    case buddiesAction.ACCEPT_PENDING_BUDDIES_INITIATE: {
      return {
        ...state, acceptLoader: true
      }
    }
    case buddiesAction.ACCEPT_PENDING_BUDDIES_SUCCESS: {
      return {
        ...state, acceptLoader: false, 
      }
    }
    case buddiesAction.ACCEPT_PENDING_BUDDIES_FAILURE: {
      return {
        ...state, acceptLoader: false
      }
    }
    case buddiesAction.DELETE_PENDING_BUDDIES_INITIATE: {
      return {
        ...state, deleteLoader: true
      }
    }
    case buddiesAction.DELETE_PENDING_BUDDIES_SUCCESS: {
      return {
        ...state, deleteLoader: false
      }
    }
    case buddiesAction.DELETE_PENDING_BUDDIES_FAILURE: {
      return {
        ...state, deleteLoader: false
      }
    }
    case buddiesAction.DELETE_BUDDIES_INITIATE: {
      return {
        ...state, deleteBuddiesLoader: true
      }
    }
    case buddiesAction.DELETE_BUDDIES_SUCCESS: {
      return {
        ...state, deleteBuddiesLoader: false
      }
    }
    case buddiesAction.DELETE_BUDDIES_FAILURE: {
      return {
        ...state, deleteBuddiesLoader: false
      }
    }
    case buddiesAction.EMPTY_BUDDIES_LIST: {
      return {
        ...state, searchBuddies:[], loader: false
      }
    }
    default: 
      return state
  }
}