import React, {useState,useEffect,Fragment } from 'react'; 
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import config from '../config/config';
import { useHistory } from "react-router-dom"; 
import '../App.css'; 
import { Link } from "react-router-dom"; 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup'
import Col from 'react-bootstrap/Col'; 
import Form from 'react-bootstrap/Form'; 
import Button from 'react-bootstrap/Button'
import Biglogo from "../assets/media/gbLogo.png";
import Header from './header';       
import Footer from './footer';
import {loginUser} from '../redux/actions/loginAction'
import {emptyUserLikeData} from '../redux/actions/streetTalkAction'
import streetTalk from "../assets/media/streettalk.svg"
import firebase from '../firebase'

const initialState = {
	emailId: '',
	password: '',
	deviceToken:'',
	emailIdError: '',
	passwordError: '',
	errorMsg:'',
	deviceType:'web',
}
const Login = () => {
 	const history=useHistory();
	 const dispatch = useDispatch()
	 const [iState, updateState] = useState(initialState)
	 let [showpassword, setShowPassword] = useState(true);
	 const userState  = useSelector(state => state.login)
	 const {
		emailId,
		password,
		emailIdError,
		passwordError,
		errorMsg,
		deviceToken,
		deviceType
	 } = iState
	 
	 window.scrollTo({
		top: 0,
		left: 100,
		behavior: 'smooth'
	});
	useEffect(() => {
			dispatch(emptyUserLikeData())
	},[])
	
	// useEffect(()=>{
  //   const messaging = firebase.messaging()
  //   messaging.requestPermission().then(()=>{
  //     return messaging.getToken()
  //   }).then(token=>{
  //     window.localStorage.setItem("NotificationToken", JSON.stringify(token))
  //   }).catch(()=>{
  //     console.log('error')
  //   })
  // },[])

 
	// const notificationToken = JSON.parse(window.localStorage.getItem("NotificationToken"))
 	let handleValidation=()=>{
		let emailIdError='';
		let passwordError='';
		let formIsValid=true;

		if(!emailId.trim()){
			emailIdError="*Email id can't be empty";
			formIsValid=false;
		}
		else {
			if (!/^.+?@.+?\..+$/.test(emailId)){
				emailIdError="*Email format is not valid";
				formIsValid = false;
			 }
			}
		if(!password.trim()){
			passwordError="*Password can't be empty";
			formIsValid=false;
		}
		updateState({
			...iState, emailIdError, passwordError,errorMsg:''
		})
		return formIsValid;
	}
const handleChange = (e) => {
	const {name, value} = e.target
	updateState({
		...iState, [name]: value
	})
}
	const handleSubmit = (e) => {
		e.preventDefault()
		let formIsValid=handleValidation()
		if(formIsValid) {
			const data = {emailId, password}
     dispatch(loginUser(data)).then(res => {
			if (res.response_code == 200) {
					toast.success(res.message, {
							position: toast.POSITION.TOP_RIGHT
					});
					delete res.Data.password
					delete res.Data.otpData
      		window.localStorage.setItem('userData',JSON.stringify(res.Data))
					
					history.push('/street-talk')
			}else{
				updateState({
					...iState, errorMsg:res.message,emailIdError:'', passwordError:''
				})
			}
	}).catch(err => {
			console.log(err,'err')
				// const message = err && err.response && err.response.data ? err.response.data.message : "Something went wrong."
				// toast.error(message, {
				// 		position: toast.POSITION.TOP_RIGHT
				// });
		});			
		}
	}

		return (
		<div> 
			<Header/>
				
			<section>
				<div className="LoginArea">
					<Container> 
							<Row>
								<Col sm={6}>
									<div className="LoginLeft" style={{margin: "40px 0"}}>
											<img src={streetTalk} />
									</div>
								</Col>
								<Col sm={6}>
									<div className="LoginRight">
										<h3>Login</h3>
										<h2></h2>
						
										<span style={{ color: "red" }}>{errorMsg}</span>
										<Form onSubmit={handleSubmit} autoComplete='off'>
											<Form.Group> 
												<Form.Control
												type="email"
												placeholder="Email Address"
												name='emailId'
												value={emailId}
												onChange={handleChange}
												/>
												<span style={{ color: "red" }}>{emailIdError}</span>
											</Form.Group>
											
											<Form.Group> 
												<InputGroup>
												<Form.Control style={{position:"relative"}}
												  type={showpassword ? "password" : "text"}
													placeholder="Password"
													name='password'
													value={password}
													onChange={handleChange}
													/>
													<InputGroup.Append>
															<InputGroup.Text id="basic-addon2">
													<a onClick={(e)=>{setShowPassword(!showpassword)}} style={{color: "#004AAD"}}  className="IconEye">
															<span>{showpassword ? <i className="fa fa-eye-slash" aria-hidden="true"></i> :
															<i className="fa fa-eye" aria-hidden="true"></i>
														}</span></a>
															</InputGroup.Text>
													</InputGroup.Append>
												</InputGroup>
													<span style={{ color: "red" }}>{passwordError}</span>
											</Form.Group>

											<Form.Group> 
												<div className="forget">
													<Form.Check label="Remember me" />
													<Link to="/forget">Forgot Password?</Link>
												</div>
										</Form.Group>
											<h6><Button type='submit'>Login</Button></h6> 
											<h5>Don't have an Account? <Link to="/register">Create Account</Link></h5>
											
									</Form> 
									</div>
								</Col>
							</Row> 
					</Container>
				</div>
			</section> 

		<Footer/>
	</div>
		)
	}
export default Login