import React, {useState,useEffect,Fragment } from 'react'; 
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import config from '../config/config';
import { useHistory } from "react-router-dom"; 
import '../App.css'; 
import { Link } from "react-router-dom"; 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'; 
import Form from 'react-bootstrap/Form'; 
import Button from 'react-bootstrap/Button'

import Biglogo from "../assets/media/gbLogo.png";
import streetTalk from "../assets/media/streettalk.svg"

import Header from './header';       
import Footer from './footer';
import {sendOtp} from '../redux/actions/setPasswordAction'

const initialState = {
	emailId: '',
	emailIdError: '',
	errMsg: ''
}
const Forget=()=>{ 
	const history=useHistory();
	const [iState, updateState] = useState(initialState)
	const [submitDisable, updateSubmitDisable ] =  useState(false);
	const dispatch = useDispatch()
	const {
		emailId,
		emailIdError,
		errMsg
	} = iState

	const handleInputChange = (e) => {
		const {name, value} = e.target
		updateState({
			...iState, [name]: value
		})
	}

	const handleValidation=()=>{
		 let emailIdError='';
		 let formIsValid=true;
		 if(!emailId.trim()){
			emailIdError="Email id can't be empty";
			formIsValid=false;
		}
		else {
			if (!/^.+?@.+?\..+$/.test(emailId)){
				emailIdError="Email format is not valid";
				formIsValid = false;
			 }
			}
		// setEmailIdError(emailIdError);
		updateState({
			...iState, emailIdError
		})

		return formIsValid;
	}

	const handleSubmit=(e)=>{

		e.preventDefault()

		let formIsValid=handleValidation();
		if(formIsValid){
		updateSubmitDisable(true)
     dispatch(sendOtp({emailId:emailId})).then(res => {
			if (res.response_code == 200) {
					toast.success(res.message, {
							position: toast.POSITION.TOP_RIGHT
					})
					history.push('/otp')
			}else{
				updateState({
					...iState, errorMsg:res.message,emailIdError:''
				})
			}
	}).catch(err => {
			console.log(err,'err')
				// const message = err && err.response && err.response.data ? err.response.data.message : "Something went wrong."
				// toast.error(message, {
				// 		position: toast.POSITION.TOP_RIGHT
				// });
		});
		}
	}
	    return <div> 
	    	<Header/>
				 
				<section>
					<div className="LoginArea">
						<Container> 
						  	<Row>
						    	<Col sm={6}>
						    		<div className="LoginLeft" style={{margin: "15px 0"}}>
			        					<img src={streetTalk} style={{width: "70%"}} />
						    		</div>
						    	</Col>
						    	<Col sm={6}>
						    		<div className="LoginRight">
						    			<h3>Forgot Password?</h3>
						    			<h2>Please enter your registered email id.</h2>
						    			<Form onSubmit={handleSubmit} autoComplete='off'>
										  	<Form.Group> 
										    	<Form.Control
													   type="email"
														 placeholder="Email Address"
														 name='emailId'
														 value={emailId}
														 onChange={handleInputChange}
														 />
														 <span style={{ color: "red" }}>{emailIdError}</span>
										  	</Form.Group> 
										  	
										  	<h6><Button type='submit' disabled={submitDisable}>Send OTP</Button></h6> 
										  	<h5>Back to <Link to="/login">Login</Link></h5>
										</Form> 
						    		</div>
						    	</Col>
					  		</Row> 
						</Container>
					</div>
				</section> 
			<Footer/>
		</div>
  	}

export default Forget