import React from 'react'
import '../App.css'; 
import { Link } from "react-router-dom"; 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'; 

import Banner from "../assets/media/contact.jpg";
import GB from "../assets/media/GB.png";
import Contact1 from "../assets/media/Contact-1.svg";
import Contact2 from "../assets/media/Contact-2.svg";
import Contact3 from "../assets/media/Contact-3.svg";

import Header from './header';
import Footer from './footer';

class contact extends React.Component {
  	render() { 
	    return <div> 
	    		<div className="Header">
		    		<Header/>
		    	</div> 
				
				 
		        <div className="BannerArea">
		        	<img src={Banner} />
		        	<div className="BannerText">
			            <div className="container">
			                <h4><span>Contact</span> Us</h4>
			            </div>
			        </div>
		        </div>
			    

			    <section>
			        <div className="ContactArea">

			        	<Container> 
						  	<Row>
						    	<Col sm={4}>
						    		<div className="ContactBox">
			                            <figure><img src={Contact1} /></figure>
			                            <h3>Talk to us</h3>
			                            <p>Lorem ipsum dolor sit amet, consetetur <br />
			                                sadipscing elitr.</p>
			                            <h4>+9195165***86</h4> 
			                        </div>
						    	</Col>
						    	<Col sm={4}>
						    		<div className="ContactBox">
			                            <figure><img src={Contact2} /></figure>
			                            <h3>Talk to us</h3>
			                            <p>Lorem ipsum dolor sit amet, consetetur <br />
			                                sadipscing elitr.</p>
			                            <h4>support@streettak.com</h4> 
			                            <a href="">Email Us</a>
			                        </div>
						    	</Col>
						    	<Col sm={4}>
						    		<div className="ContactBox" style={{padding:'34px 0'}}>
			                            <figure><img src={Contact3} /></figure>
			                            <h3>Reach Us</h3>
			                            <p>Lorem ipsum dolor sit amet, consetetur <br />
			                                sadipscing elitr.</p>
			                            <h4>H-146, 147, Sector 63 Rd, <br />
			                                Noida - 201301</h4> 
			                        </div>
						    	</Col>
					  		</Row> 
						</Container> 
			        </div>
			    </section> 
			<Footer/>
		</div>
  	}
}
export default contact