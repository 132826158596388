export const loginAction = {
  USER_LOGIN_INITIATE: 'USER_LOGIN_INITIATE',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGIN_FAILURE: 'USER_LOGIN_FAILURE',
  USER_LOGOUT_INITIATE: 'USER_LOGOUT_INITIATE',
  USER_LOGOUT_SUCCESS: 'USER_LOGOUT_SUCCESS',
  USER_LOGOUT_FAILURE: 'USER_LOGOUT_FAILURE',
}

export const signUpAction = {
  USER_SIGNUP_INITIATE: 'USER_SIGNUP_INITIATE',
  USER_SIGNUP_SUCCESS: 'USER_SIGNUP_SUCCESS',
  USER_SIGNUP_FAILURE: 'USER_SIGNUP_FAILURE',
  VERIFY_OTP_INITIATE: 'VERIFY_OTP_INITIATE',
  VERIFY_OTP_SUCCESS: 'VERIFY_OTP_SUCCESS',
  VERIFY_OTP_FAILURE: 'VERIFY_OTP_FAILURE',
  RESEND_OTP_INITIATE: 'RESEND_OTP_INITIATE',
  RESEND_OTP_SUCCESS: 'RESEND_OTP_SUCCESS',
  RESEND_OTP_FAILURE: 'RESEND_OTP_FAILURE',
  

}

export const changePasswordAction = {
  USER_CHANGE_PASSWORD_INITIATE: 'USER_CHANGE_PASSWORD_INITIATE',
  USER_CHANGE_PASSWORD_SUCCESS: 'USER_CHANGE_PASSWORD_SUCCESS',
  USER_CHANGE_PASSWORD_FAILURE: 'USER_CHANGE_PASSWORD_FAILURE'
}

export const forgetPasswordAction = {
  FORGOT_PASSWORD_INITIATE: 'FORGOT_PASSWORD_INITIATE',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE'
}

export const resetPasswordAction = {
  USER_RESET_PASSWORD_INITIATE: 'USER_RESET_PASSWORD_INITIATE',
  USER_RESET_PASSWORD_SUCCESS: 'USER_RESET_PASSWORD_SUCCESS',
  USER_RESET_PASSWORD_FAILURE: 'USER_RESET_PASSWORD_FAILURE'
}

export const userProfileAction = {
  GET_USER_PROFILE_INITIATE: 'GET_USER_PROFILE_INITIATE',
  GET_USER_PROFILE_SUCCESS: 'GET_USER_PROFILE_SUCCESS',
  GET_USER_PROFILE_FAILURE: 'GET_USER_PROFILE_FAILURE',
  UPDATE_USER_PROFILE_INITIATE: 'UPDATE_USER_PROFILE_INITIATE',
  UPDATE_USER_PROFILE_SUCCESS: 'UPDATE_USER_PROFILE_SUCCESS',
  UPDATE_USER_PROFILE_FAILURE: 'UPDATE_USER_PROFILE_FAILURE',
  EMPTY_USER_DATA: 'EMPTY_USER_DATA'
}

export const buddiesAction = {
  GET_ALL_BUDDIES_INITIATE: 'GET_ALL_BUDDIES_INITIATE',
  GET_ALL_BUDDIES_SUCCESS: 'GET_ALL_BUDDIES_SUCCESS',
  GET_ALL_BUDDIES_FAILURE: 'GET_ALL_BUDDIES_FAILURE',
  SEARCH_BUDDIES_INITIATE: 'SEARCH_BUDDIES_INITIATE',
  SEARCH_BUDDIES_SUCCESS: 'SEARCH_BUDDIES_SUCCESS',
  SEARCH_BUDDIES_FAILURE: 'SEARCH_BUDDIES_FAILURE',
  SEND_REQUEST_BUDDIES_INITIATE: 'SEND_REQUEST_BUDDIES_INITIATE',
  SEND_REQUEST_BUDDIES_SUCCESS: 'SEND_REQUEST_BUDDIES_SUCCESS',
  SEND_REQUEST_BUDDIES_FAILURE: 'SEND_REQUEST_BUDDIES_FAILURE',
  PENDING_BUDDIES_INITIATE: 'PENDING_BUDDIES_INITIATE',
  PENDING_BUDDIES_SUCCESS: 'PENDING_BUDDIES_SUCCESS',
  PENDING_BUDDIES_FAILURE: 'PENDING_BUDDIES_FAILURE',
  ACCEPT_PENDING_BUDDIES_INITIATE: 'ACCEPT_PENDING_BUDDIES_INITIATE',
  ACCEPT_PENDING_BUDDIES_SUCCESS: 'ACCEPT_PENDING_BUDDIES_SUCCESS',
  ACCEPT_PENDING_BUDDIES_FAILURE: 'ACCEPT_PENDING_BUDDIES_FAILURE',
  DELETE_PENDING_BUDDIES_INITIATE: 'DELETE_PENDING_BUDDIES_INITIATE',
  DELETE_PENDING_BUDDIES_SUCCESS: 'DELETE_PENDING_BUDDIES_SUCCESS',
  DELETE_PENDING_BUDDIES_FAILURE: 'DELETE_PENDING_BUDDIES_FAILURE',
  DELETE_BUDDIES_INITIATE: 'DELETE_BUDDIES_INITIATE',
  DELETE_BUDDIES_SUCCESS: 'DELETE_BUDDIES_SUCCESS',
  DELETE_BUDDIES_FAILURE: 'DELETE_BUDDIES_FAILURE',
  EMPTY_BUDDIES_LIST: 'EMPTY_BUDDIES_LIST'
}

export const streetTalkAction ={
  CREATE_POST_INITIATE: 'CREATE_POST_INITIATE',
  CREATE_POST__SUCCESS: 'CREATE_POST__SUCCESS',
  CREATE_POST__FAILURE: 'CREATE_POST__FAILURE',
  CREATE_COMMENT_INITIATE: 'CREATE_COMMENT_INITIATE',
  CREATE_COMMENT__SUCCESS: 'CREATE_COMMENT__SUCCESS',
  CREATE_COMMENT__FAILURE: 'CREATE_COMMENT__FAILURE',
  POST_COMMENT_INITIATE: 'POST_COMMENT_INITIATE',
  POST_COMMENT__SUCCESS: 'POST_COMMENT__SUCCESS',
  POST_COMMENT__FAILURE: 'POST_COMMENT__FAILURE',
  USER_POST_INITIATE: 'USER_POST_INITIATE',
  USER_POST__SUCCESS: 'USER_POST__SUCCESS',
  USER_POST__FAILURE: 'USER_POST__FAILURE',
  LIKE_POST_INITIATE: 'LIKE_POST_INITIATE',
  LIKE_POST__SUCCESS: 'LIKE_POST__SUCCESS',
  LIKE_POST__FAILURE: 'LIKE_POST__FAILURE',
  DELETE_POST_INITIATE: 'DELETE_POST_INITIATE',
  DELETE_POST__SUCCESS: 'DELETE_POST__SUCCESS',
  DELETE_POST__FAILURE: 'DELETE_POST__FAILURE',
  DELETE_COMMENT_INITIATE: 'DELETE_COMMENT_INITIATE',
  DELETE_COMMENT_SUCCESS: 'DELETE_COMMENT_SUCCESS',
  DELETE_COMMENT_FAILURE: 'DELETE_COMMENT_FAILURE',
  PARTICULAR_POST_INITIATE: 'PARTICULAR_POST_INITIATE',
  PARTICULAR_POST_SUCCESS: 'PARTICULAR_POST_SUCCESS',
  PARTICULAR_POST_FAILURE: 'PARTICULAR_POST_FAILURE',
  STREETTALK_POST_INITIATE: 'STREETTALK_POST_INITIATE',
  STREETTALK_POST_SUCCESS: 'STREETTALK_POST_SUCCESS',
  STREETTALK_POST_FAILURE: 'STREETTALK_POST_FAILURE',
  VENDOR_POST_INITIATE: 'VENDOR_POST_INITIATE',
  VENDOR_POST_SUCCESS: 'VENDOR_POST_SUCCESS',
  VENDOR_POST_FAILURE: 'VENDOR_POST_FAILURE',
  OTHER_USER_POST_INITIATE: 'OTHER_USER_POST_INITIATE',
  OTHER_USER_POST_SUCCESS: 'OTHER_USER_POST_SUCCESS',
  OTHER_USER_POST_FAILURE: 'OTHER_USER_POST_FAILURE',
  BLAST_USER_INITIATE: 'BLAST_USER_INITIATE',
  BLAST_USER_SUCCESS: 'BLAST_USER_SUCCESS',
  BLAST_USER_FAILURE: 'BLAST_USER_FAILURE',
  LIKED_USER_INITIATE: 'LIKED_USER_INITIATE',
  LIKED_USER_SUCCESS: 'LIKED_USER_SUCCESS',
  LIKED_USER_FAILURE: 'LIKED_USER_FAILURE',
  UNLIKED_USER_INITIATE: 'UNLIKED_USER_INITIATE',
  UNLIKED_USER_SUCCESS: 'UNLIKED_USER_SUCCESS',
  UNLIKED_USER_FAILURE: 'UNLIKED_USER_FAILURE',
  EMPTY_USER_LIKED_DATA: 'EMPTY_USER_LIKED_DATA',
  VENDOR_CHECK_INITIATE: 'VENDOR_CHECK_INITIATE',
  VENDOR_CHECK_SUCCESS: 'VENDOR_CHECK_SUCCESS',
  VENDOR_CHECK_FAILURE: 'VENDOR_CHECK_FAILURE',
  UPDATE_POST_INITIATE: 'UPDATE_POST_INITIATE',
  UPDATE_POST_SUCCESS: 'UPDATE_POST_SUCCESS',
  UPDATE_POST_FAILURE: 'UPDATE_POST_FAILURE',
  UPDATE_COMMENT_INITIATE: 'UPDATE_COMMENT_INITIATE',
  UPDATE_COMMENT_SUCCESS: 'UPDATE_COMMENT_SUCCESS',
  UPDATE_COMMENT_FAILURE: 'UPDATE_COMMENT_FAILURE',
  POST_REPORT_INITIATE: 'POST_REPORT_INITIATE',
  POST_REPORT_SUCCESS: 'POST_REPORT_SUCCESS',
  POST_REPORT_FAILURE: 'POST_REPORT_FAILURE',
  PEOPLE_MAY_KNOW_INITIATE: 'PEOPLE_MAY_KNOW_INITIATE',
  PEOPLE_MAY_KNOW_SUCCESS: 'PEOPLE_MAY_KNOW_SUCCESS',
  PEOPLE_MAY_KNOW_FAILURE: 'PEOPLE_MAY_KNOW_FAILURE',
  MAJOR_NEWS_INITIATE: 'MAJOR_NEWS_INITIATE',
  MAJOR_NEWS_SUCCESS: 'MAJOR_NEWS_SUCCESS',
  MAJOR_NEWS_FAILURE: 'MAJOR_NEWS_FAILURE',
  UPDATE_COVER_PHOTO_INITIATE: 'UPDATE_COVER_PHOTO_INITIATE',
  UPDATE_COVER_PHOTO_SUCCESS: 'UPDATE_COVER_PHOTO_SUCCESS',
  UPDATE_COVER_PHOTO_FAILURE: 'UPDATE_COVER_PHOTO_FAILURE',
  SHARED_BLAST_POST_INITIATE: 'SHARED_BLAST_POST_INITIATE',
  SHARED_BLAST_POST_SUCCESS: 'SHARED_BLAST_POST_SUCCESS',
  SHARED_BLAST_POST_FAILURE: 'SHARED_BLAST_POST_FAILURE',
  UPLOAD_IMAGE_VIDEO_INITIATE: 'UPLOAD_IMAGE_VIDEO_INITIATE',
  UPLOAD_IMAGE_VIDEO_SUCCESS: 'UPLOAD_IMAGE_VIDEO_SUCCESS',
  UPLOAD_IMAGE_VIDEO_FAILURE: 'UPLOAD_IMAGE_VIDEO_FAILURE',
  MULTIPLE_COMMENTS_INITIATE: 'MULTIPLE_COMMENTS_INITIATE',
  MULTIPLE_COMMENTS_SUCCESS: 'MULTIPLE_COMMENTS_SUCCESS',
  MULTIPLE_COMMENTS_FAILURE: 'MULTIPLE_COMMENTS_FAILURE',
  UPLOAD_MULTIPLE_IMAGES_INITIATE: 'UPLOAD_MULTIPLE_IMAGES_INITIATE',
  UPLOAD_MULTIPLE_IMAGES_SUCCESS: 'UPLOAD_MULTIPLE_IMAGES_SUCCESS',
  UPLOAD_MULTIPLE_IMAGES_FAILURE: 'UPLOAD_MULTIPLE_IMAGES_FAILURE',
  MULTIPLE_POST_INITIATE: 'MULTIPLE_POST_INITIATE',
  MULTIPLE_POST_SUCCESS: 'MULTIPLE_POST_SUCCESS',
  MULTIPLE_POST_FAILURE: 'MULTIPLE_POST_FAILURE',
  EMPTY_IMAGE_VIDEO_URL:'EMPTY_IMAGE_VIDEO_URL',
  SHARE_POST_INITIATE: 'SHARE_POST_INITIATE',
  SHARE_POST_SUCCESS: 'SHARE_POST_SUCCESS',
  SHARE_POST_FAILURE: 'SHARE_POST_FAILURE',
  STREETTAK_NEWS_INITIATE: 'STREETTAK_NEWS_INITIATE',
  STREETTAK_NEWS_SUCCESS: 'STREETTAK_NEWS_SUCCESS',
  STREETTAK_NEWS_FAILURE: 'STREETTAK_NEWS_FAILURE',
  COMMENT_LIKE_INITIATE: 'COMMENT_LIKE_INITIATE',
  COMMENT_LIKE_SUCCESS: 'COMMENT_LIKE_SUCCESS',
  COMMENT_LIKE_FAILURE: 'COMMENT_LIKE_FAILURE',
  ALL_FOLLOWING_INITIATE: 'ALL_FOLLOWING_INITIATE',
  ALL_FOLLOWING_SUCCESS: 'ALL_FOLLOWING_SUCCESS',
  ALL_FOLLOWING_FAILURE: 'ALL_FOLLOWING_FAILURE',
  ALL_FOLLOWERES_INITIATE: 'ALL_FOLLOWERES_INITIATE',
  ALL_FOLLOWERES_SUCCESS: 'ALL_FOLLOWERES_SUCCESS',
  ALL_FOLLOWERES_FAILURE: 'ALL_FOLLOWERES_FAILURE',
  REMOVE_FOLLOWERES_INITIATE: 'REMOVE_FOLLOWERES_INITIATE',
  REMOVE_FOLLOWERES_SUCCESS: 'REMOVE_FOLLOWERES_SUCCESS',
  REMOVE_FOLLOWERES_FAILURE: 'REMOVE_FOLLOWERES_FAILURE',
  NOTIFICATION_LIST_INITIATE: 'NOTIFICATION_LIST_INITIATE',
  NOTIFICATION_LIST_SUCCESS: 'NOTIFICATION_LIST_SUCCESS',
  NOTIFICATION_LIST_FAILURE: 'NOTIFICATION_LIST_FAILURE',
  NOTIFICATION_COUNT_INITIATE: 'NOTIFICATION_COUNT_INITIATE',
  NOTIFICATION_COUNT_SUCCESS: 'NOTIFICATION_COUNT_SUCCESS',
  NOTIFICATION_COUNT_FAILURE: 'NOTIFICATION_COUNT_FAILURE',
  NOTIFICATION_SETTING_INITIATE: 'NOTIFICATION_SETTING_INITIATE',
  NOTIFICATION_SETTING_SUCCESS: 'NOTIFICATION_SETTING_SUCCESS',
  NOTIFICATION_SETTING_FAILURE: 'NOTIFICATION_SETTING_FAILURE',
  NOTIFICATION_STATUS_INITIATE: 'NOTIFICATION_STATUS_INITIATE',
  NOTIFICATION_STATUS_SUCCESS: 'NOTIFICATION_STATUS_SUCCESS',
  NOTIFICATION_STATUS_FAILURE: 'NOTIFICATION_STATUS_FAILURE',
  UNSEEN_NOTIFICATION_INITIATE: 'UNSEEN_NOTIFICATION_INITIATE',
  UNSEEN_NOTIFICATION_SUCCESS: 'UNSEEN_NOTIFICATION_SUCCESS',
  UNSEEN_NOTIFICATION_FAILURE: 'UNSEEN_NOTIFICATION_FAILURE',
  READ_ALL_NOTIFICATION_INITIATE: 'READ_ALL_NOTIFICATION_INITIATE',
  READ_ALL_NOTIFICATION_SUCCESS: 'READ_ALL_NOTIFICATION_SUCCESS',
  READ_ALL_NOTIFICATION_FAILURE: 'READ_ALL_NOTIFICATION_FAILURE',
}



