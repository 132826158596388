import axios from 'axios'
export function logOut(userType) {
  return new Promise((res, rej) => {
      window.localStorage.removeItem(userType);
      res(true);
  });
}

export function isLoggedIn(userType) {

  let session = getObject(userType) || {};
  session = Object.keys(session).length && JSON.parse(session)
  let accessToken = session && session && session['jwtToken'] || "";
  return accessToken;
}

export function getObject(key) {
  if (window && window.localStorage) {
      return window.localStorage.getItem(key);
  }
}

export function multiPartData(data, type=''){
  let multiPart = new FormData();
  for (let key in data){
     if(type === 'multiple' &&  key === 'blogImage') {
        data[key].forEach(file=>{
        multiPart.append(key, file);
      });
     
     } else if(key !== 'blogImage') {
      multiPart.append(key,data[key])
      }
  }

  return multiPart
}

export function checkPassword(str)
{
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
}


// export  async function    changeLanguge  (text){
//   const lang = window.localStorage.getItem("language")
//   console.log('lang', lang, text)

   
//         let data= await  axios.post(
//                 'https://translation.googleapis.com/language/translate/v2',
//                 {},
//                 {
//                   params: {
//                     q: text,
//                     target: lang,
//                     key: 'AIzaSyANnWNDk-nzl9c4-RfWQTes1MkCNR-vnLU'
//                   }
//                 }
//               )

//               console.log(data.data.data.translations[0].translatedText)

//               return data.data.data.translations[0].translatedText
//               .then(response => {
//                 // response.json({ message: 'Request received!', response })
//                 console.log('name',(response.data.data.translations[0].translatedText))
//                 let tranlation = response.data.data.translations[0].translatedText
//                 console.log("tranlation==>",tranlation)
//                 response.JSON()
//                 return {trnas:response.data.data.translations[0].translatedText}
//                 //  resolve(JSON.parse(tranlation));
//               })
//               .catch(err => {
//                   reject(err);
//               })
      

//   const response = axios
//       .post(
//         'https://translation.googleapis.com/language/translate/v2',
//         {},
//         {
//           params: {
//             q: text,
//             target: lang,
//             key: 'AIzaSyANnWNDk-nzl9c4-RfWQTes1MkCNR-vnLU'
//           }
//         }
//       )
//       .then((response) => {
//         console.log('coverted', response.data.data.translations[0].translatedText)
//         return (response.data.data.translations[0].translatedText);
//       })
//       .catch((err) => {
//         console.log('rest api error', err);
//       });
// }