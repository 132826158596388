import React, {useState,useEffect,Fragment } from 'react';  
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import config from '../config/config';
import { useHistory } from "react-router-dom"; 
import '../App.css'; 
import { Link } from "react-router-dom"; 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'; 
import Form from 'react-bootstrap/Form'; 
import Button from 'react-bootstrap/Button'

import Biglogo from "../assets/media/gbLogo.png";
import streetTalk from "../assets/media/streettalk.svg"

import Header from './header';       
import Footer from './footer';
import {verifyOtp, resendOtpUser} from '../redux/actions/signUpAction';

const initialState = {
	value1: '',
	value2: '',
	value3: '',
	value4: '',
	otpError:'',
	errorMsg:'',
}
const Otp = () => {
	const history = useHistory();
	const dispatch = useDispatch()
	const [iState, updateState] = useState(initialState);
	const forgotData  = useSelector(state => state.forgotPassword)
	const [seconds, setSeconds ] =  useState(30);
	const {
		value1,
		value2,
		value3,
		value4,
		errorMsg,
		otpError
	} = iState

	useEffect(()=>{
	let myInterval = setInterval(() => {
					if (seconds > 0) {
							setSeconds(seconds - 1);
					}
					if (seconds === 0) {
									clearInterval(myInterval)
					} 
			}, 1000)
			return ()=> {
					clearInterval(myInterval);
				};
	});


	
	const handleInputChange = (event) => {
		const {name, value} = event.target
		updateState({
			...iState, [name]: value
		})
		setTimeout(()=>{
			const form = event.target.form;
			const index = Array.prototype.indexOf.call(form, event.target)
			form.elements[index + 1].focus();
		},0)
	}

	const handleValidation = () => {

		let otpError = '';
		let formIsValid = true;
		const otp = value1.concat(value2,value3,value4)
		if (!otp.trim()) {
			otpError = "please enter otp";
			formIsValid = false;
		}else if(otp && otp.length<4){
			otpError = "please enter otp";
			formIsValid = false;
		}
		updateState({
			...iState, errorMsg:'',otpError
		})
		return formIsValid;
	}
	let handleSubmit = (e) => {
		e.preventDefault()
		let id = JSON.parse(localStorage.getItem('signupData')).id
		let formIsValid = handleValidation();
		if (formIsValid) {
			const data = {_id:id,otp:value1.concat(value2,value3,value4) }
     dispatch(verifyOtp(data)).then(res => {
			if (res.response_code == 200) {
					toast.success(res.message, {
							position: toast.POSITION.TOP_RIGHT
					});
					if(forgotData.forgetPasswordStatus) {
						history.push('/reset-password')
					} else {
					history.push('/login')
					window.localStorage.removeItem('signupData');
					}
			}else{
				updateState({
					...iState, errorMsg:res.message,otpError:''
				})
			}
			}).catch(err => {
					console.log(err,'err')
						// const message = err && err.response && err.response.data ? err.response.data.message : "Something went wrong."
						// toast.error(message, {
						// 		position: toast.POSITION.TOP_RIGHT
						// });
				})
		}
	}

	const resendOtp = (e) => {
		e.preventDefault()
		let emailId = JSON.parse(localStorage.getItem('signupData')).email
		dispatch(resendOtpUser({emailId:emailId})).then(res => {
		 if (res.response_code == 200) {
			 setSeconds(30)
				 toast.success(res.message, {
						 position: toast.POSITION.TOP_RIGHT
				 })
		 }else{
			toast.error(res.message, {
						position: toast.POSITION.TOP_RIGHT
				});
		 }
 }).catch(err => {
		 console.log(err,'err')
			 // const message = err && err.response && err.response.data ? err.response.data.message : "Something went wrong."
			 // toast.error(message, {
			 // 		position: toast.POSITION.TOP_RIGHT
			 // });
	 });			
	}

	    return <div> 
	    	<Header/>
				 
				<section>
					<div className="LoginArea">
						<Container> 
						  	<Row>
						    	<Col sm={6}>
						    		<div className="LoginLeft" style={{margin: "0"}}>
			        					<img src={streetTalk} style={{width: "70%"}} />
						    		</div>
						    	</Col>
						    	<Col sm={6}>
						    		<div className="LoginRight">
						    			<h3>OTP</h3>
						    			<h2>Please enter OTP</h2>

											<span style={{ color: "red" }}>{errorMsg}</span>
						    			<Form onSubmit={handleSubmit} autoComplete='off'>
										  	<Form.Group> 
										  		<div className="Otp">
											    	<Form.Control
															type="text" 
															name='value1'
															value={value1}
															onChange={handleInputChange}
															maxLength='1'
														/>
											    	<Form.Control
															type="number"
															name='value2'
															value={value2}
															onChange={handleInputChange}
															maxLength='1'
														/>
											    	<Form.Control
															type="number"
															name='value3'
															value={value3}
															onChange={handleInputChange}
															maxLength='1'
														/>
											    	<Form.Control
														  type="number" 
															name='value4'
															value={value4}
															onChange={handleInputChange}
															maxLength='1'
														/>
										  		</div>
												<span style={{ color: "red" }}>{otpError}</span>
										  	</Form.Group> 
												<div style={{display: 'flex'}}>
										  	<Button variant="primary" type="submit" className='mr-4'>Submit</Button> 
										  	<Button variant="primary" disabled={seconds!==0} onClick={resendOtp}>Resend OTP</Button> 
												</div>
										</Form> 
						    		</div>
						    	</Col>
					  		</Row> 
						</Container>
					</div>
				</section> 
			<Footer/>
		</div>
  	}

export default Otp