import React, { useState, Fragment , useEffect,useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import "../App.css";
import _, { remove } from 'lodash'
import { Link, useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Dropdown from "react-bootstrap/Dropdown"
import { Player, BigPlayButton } from 'video-react';
import InputEmoji from "react-input-emoji";
import moment from 'moment'
import logo from "../assets/media/gbLogo.png";
import Icon1 from "../assets/media/Icon-1.svg";
import Icon2 from "../assets/media/Icon-2.svg";
import Icon4 from "../assets/media/Icon-4.svg";
import Icon5 from "../assets/media/Icon-5.svg";
import Icon6 from "../assets/media/Icon-6.svg";
import streetTalkLogo from "../assets/media/streettalk.svg"
import User from "../assets/media/defaultProfileImage.jpeg";
import Blast from "../assets/media/blast.svg"
import Like from "../assets/media/like.svg"
import Dislike from "../assets/media/dislike.svg"
import { toast } from 'react-toastify';
import LightboxCustom from './lightBox'
import InfiniteScroll from "react-infinite-scroll-component";
import Preview from  'preview-url-component'

import Header from "./header";
import Footer from "./footer";
import CustomLoader from './customLoader';
import {searchAllBuddies, sendRequestBuddies, emptyBuddies} from '../redux/actions/buddiesAction'
import {getUserProfile, updateUserProfile, emptyUserData} from '../redux/actions/userProfileAction'
import {
  createPost,
  streetTalkPost,
  vendorTalkPost,
  createComment, 
  postComment, 
  likePost,
  deletePost,
  deleteComment,
  particularPost,
  otherUserPost,
  blastUsers,
  likedUsers,
  unlikedUsers,
  emptyUserLikeData,
  vendorCheck,
  particularUpdateComment,
  updatePost,
  postReport,
  majorNews,
  peopleMayKnow,
  streetTalkPostSuccess,
  vendorTalkPostSuccess,
  streetTakNews
} from '../redux/actions/streetTalkAction'

const initialState = {
  userId:'',
  caption:'',
  images:[],
	imageReview:[],
  oldImage: [],
  video:[],
  videoReview:[],
  oldVideo: [],
  comment:'',
  selectedId:'',
  userType:'',
  updateData:{},
  type:'new',
  commentId: '',
  updateComment:'',
  postId:'',
  reported_topic:'',
  remark:'',
  reportPostId:'',
  commentImages:'',
  commentVideo:'',
  imageFile:'',
  videoFile:'',
  particularPostId:'',
  isOpen: false,
  lightBoxImg:[],
  isHovering:false,
  matchId:'',
  hoverData:{},
  isHoveringModal: false,
  timeOut:null,
  pageNumber:1,
  menuPosition:'inherit',
  menuTop:0,
  newsFlag:false
}
const StreetTalkPublic = () => {
  const dispatch = useDispatch()
  const history  = useHistory()
  let scrollRef = useRef(0)
  const [iState, updateState] = useState(initialState)
	const userData = useSelector(state => state.userProfile)
  const postdata = useSelector(state => state.streetTalk)
  const [show, setShow] = useState(false);
  const [key, setKey] = useState("home");
  const [userTypeShow, updateUserTypeShow] = useState(false)
  const [showReport, setShowReport] = useState(false);
  const [doneReport, setDoneReport] = useState(false);
  const [postCmt, updatePostCmt] = useState('')
  const [hasNextPage,setHasNextPage] = useState(true)

  
  const {
    userId,
    caption,
    images,
    imageReview,
    oldImage,
    videoReview,
    video,
    oldVideo,
    comment,
    selectedId,
    userType,
    updateData,
    type,
    commentId,
    updateComment,
    postId,
    reported_topic,
    remark,
    reportPostId,
    commentImages,
    commentVideo,
    imageFile,
    videoFile,
    particularPostId,
    isOpen,
    lightBoxImg,
    isHovering,
    matchId,
    hoverData,
    isHoveringModal,
    timeOut,
    menuPosition,
    menuTop,
    newsFlag
  } = iState

  const {
		profileData,
		loader
	 } = userData

   const {
    streetTalkPostData,
    streetTalkLoader,
    vendorTalkPostData,
    vendorTalkLoader,
    postCommentData,
    postCommentLoader,
    blastLoader,
    blastUserData,
    likedLoader,
    likedUserData,
    unlikedLoader,
    unlikedUserData,
    postLoader,
    peopleMayKnowData,
    majorNewsData,
    streetTakNewsData
   } = postdata

   
useEffect(() => {
  dispatch(streetTakNews())
	return ( ()=>{
		dispatch(emptyUserLikeData())
 });
},[])

const redirectLogin = () => {
  history.push("/login")
}

const handlePath = (path) => {
  history.push(path)
}

const  fetchMoreData =() => {
  let pageNumber = parseInt(streetTalkPostData.length/10)+1
  if(key === 'home' && pageNumber !== 1) {
  dispatch(streetTalkPost(false,{pageNumber:pageNumber}))
  .then((data) => {
    if (data.response_code && data.response_code == 200){
      let copyData = data
       copyData = [...streetTalkPostData, ...copyData.Data]
          dispatch(streetTalkPostSuccess(copyData))
      }
    if(data.Data.length < 10){
    setHasNextPage(false);
    }else{
      setHasNextPage(true)
    }
  });
} else {
  let pageNumber = parseInt(vendorTalkPostData.length/10)+1
  // updateState({...iState, pageNumber})
  dispatch(vendorTalkPost(false,{pageNumber:pageNumber}))
  .then((data) => {
    if (data.response_code && data.response_code == 200){
      let copyData = data
       copyData = [...vendorTalkPostData, ...copyData.Data]
          dispatch(vendorTalkPostSuccess(copyData))
      }
    if(data.Data.length <= 10){
    setHasNextPage(false);
    }else{
      setHasNextPage(true)
    }
  });
}
}
   const lightBoxOpen = (img) => {
     updateState({
       ...iState, isOpen: true, lightBoxImg: img
     })
   }
  const closeReportPost = () => {
    setShowReport(false)
  }


  const updatePostId =(id) => {
    updateState({...iState, particularPostId: id})
  }

  const handleShow = () => setShow(true);

  const updatePostComment = (item, id) => {
    
    if(!_.isEmpty(item)){
      const updateData = _.cloneDeep(iState)
      updateData.updateComment= item.comment 
      updateData.commentId = id
      updateData.commentImages = item.images || ''
      updateData.commentVideo = item.video || ''
    
      updateState (
        updateData
      )
    }
  }

  const saveComment = (id) => {
  const data ={commentId: commentId, comment: updateComment, commentImage: imageFile, video: videoFile}
  dispatch(particularUpdateComment(data)).then(res => {
    if (res.response_code == 200) {
        updateState({
          ...iState, commentId:"", updateComment:'', commentImages:'', commentVideo:'', imageFile:'', videoFile:''
        })
        if(key === 'home') {
          dispatch(streetTalkPost(false,{pageNumber:1}))
          } else {
          dispatch(vendorTalkPost())
          }
        dispatch(postComment({postId: id}))
    }
    }).catch(err => {
          const message = err && err.response && err.response ? err.response.message : "Something went handleSuit"
          toast.error(message, {
              position: toast.POSITION.TOP_RIGHT
          });
      })
  }
  const cancelEditComment = () => {
    updateState({
      ...iState, commentId:"", updateComment:''
    })
  }

  useEffect(() => {
    if(key === 'home') {
      dispatch(streetTalkPost(false, {pageNumber:1})) 
      } else {
      dispatch(vendorTalkPost(false,{pageNumber:1}))
      }
	},[])

  const handleTab = (key) => {
    if (key === 'home') {
      setKey('home')
      dispatch(streetTalkPost(false, {pageNumber:1})) 
    } else {
    setKey('profile')
		// dispatch(emptyUserLikeData())
    dispatch(vendorTalkPost(false,{pageNumber:1}))
    }
  }
const handleInputChange = (e) => {
  const {name, value} = e.target
  updateState({
    ...iState, [name]: value
  })
}

const submitComment = (e, id) => {
  e.preventDefault()
  const data ={postId: id, comment: postCmt, images: imageFile, video:videoFile}
 dispatch(createComment(data)).then(res => {
  if (res.response_code == 200) {
      updateState({
        ...iState, postCmt:'', commentImages:'', commentVideo:'', imageFile:'', videoFile:'', commentId:''
      })
      updatePostCmt('')
      if(key === 'home') {
        dispatch(streetTalkPost(false, {pageNumber:1})) 
        } else {
        dispatch(vendorTalkPost())
        }
      dispatch(postComment({postId: id}))
  }
  }).catch(err => {
        const message = err && err.response && err.response ? err.response.message : "Something went handleSuit"
        toast.error(message, {
            position: toast.POSITION.TOP_RIGHT
        });
    })
}

const handleComment = ( text) => {
  updatePostCmt(text)
}
const onCommentFileHandler = (e) => {
  e.preventDefault()
  const file = e.target.files[0]
  if(file) {
    if(file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
      commentPreviewsFile(file)
    } else if(file.type === 'video/mp4'){
      const reader = new FileReader()
          reader.addEventListener("load", () => {
            updateState({
              ...iState, commentVideo: reader.result, videoFile: file,
              //  particularPostId: id
            })
          }, false)
          if(file) {
            reader.readAsDataURL(file)
          }
    }
    else {
      toast.error('Only formats are allowed: jpeg, jpg, png files',{
        position: toast.POSITION.TOP_RIGHT
      })
    }
  } 
}

const commentPreviewsFile = (file, id) => {
  const reader = new FileReader()
  reader.addEventListener("load", () => {
    updateState({
      ...iState, commentImages: reader.result, imageFile: file,
      //  particularPostId: id
    })
  }, false)
  if(file) {
    reader.readAsDataURL(file)
  }
}

const removeCommentData = (type) => {
  if(type=== 'img') {
  updateState({
    ...iState, commentImages:''
  })
} else if(type === 'video'){
  updateState({
    ...iState, commentVideo:''
  })
}
}


    const deleteImage =(i) => {
      if(type === 'new') {
        images.splice(i,1)
        imageReview.splice(i,1)
        updateState({...iState, images, imageReview})
      } 
      else if (type === 'edit'){
        if(oldImage.length-1 < i){
          let index = oldImage.length ? i-(imageReview.length-1) : i
        imageReview.splice(i,1)
        images.splice(index,1)
      }else if(oldImage.length){
        oldImage.splice(i,1)
      }
        updateState({...iState, oldImage, imageReview, images})
        
      }
    
    }
    const deleteVideo =(i, type) => {
      if(type === 'new') {
        video.splice(i,1)
        videoReview.splice(i,1)
        updateState({...iState, videoReview, video})
      } 
      else if (type === 'edit'){
        if(oldVideo.length-1 < i){
          let index = oldVideo.length ? i-(videoReview.length-1) : i
          videoReview.splice(i,1)
          video.splice(index,1)
      }else if(oldVideo.length){
        oldVideo.splice(i,1)
      }
        updateState({...iState, oldVideo, videoReview, video})
        
      }
    
    }



const showComment = (id) => {
  updateState({...iState, selectedId: id})
  const data ={postId: id}
 dispatch(postComment(data))
}

const postLke = (id, n) => {
  const data ={postId: id, likeStatus:n}
 dispatch(likePost(data)).then(res => {
  if (res.response_code == 200) {
    if(key === 'home') {
      dispatch(streetTalkPost(false)) 
      } else {
      dispatch(vendorTalkPost(false))
      }
  }
  }).catch(err => {
        const message = err && err.response && err.response ? err.response.message : "Something went handleSuit"
        toast.error(message, {
            position: toast.POSITION.TOP_RIGHT
        });
    })
}


const userPostDetail= (userId) => {
  dispatch(otherUserPost({userId:userId}))
  window.localStorage.setItem("userId", JSON.stringify(userId))
}

const postLikedUsers = (id, type) => {
  if(type === 'blast') {
    dispatch(blastUsers({postId: id}))
    handleShow()
  } else if(type === 'like') {
    dispatch(likedUsers({postId: id}))
    handleShow()
  } else if(type === 'unlike') {
    dispatch(unlikedUsers({postId: id}))
    handleShow()
  }
}
const handleOnEnter = (e,text) => {
  if(e.keyCode == 13){ 
    return false;
  }
}

const handleMouseHover = (data, id) => {
  clearTimeout(timeOut)
  updateState({
    ...iState, isHovering: true, hoverData: data, matchId: id,timeOut:null
  })
}
const handleHover = (data, id) => {
  clearTimeout(timeOut)
  updateState({
    ...iState, isHoveringModal: true, hoverData: data, matchId: id, timeOut:null
  })
}
const handleMouseLeave = () => {
const timeOut =  setTimeout(() => {
    updateState({
      ...iState, isHovering: false, hoverData:{}, matchId:''
    })
  }, 2000);
  updateState({
    ...iState, timeOut
  })
}

const handleLeave = () => {
  const timeOut =  setTimeout(() => {
    updateState({
      ...iState, hoverData:{}, matchId:'',isHoveringModal:false
    })
   }, 2000);
    updateState({
      ...iState, timeOut
    })
}

useEffect(() => {
  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (scrollRef.current <= 800  && newsFlag) {
      updateState({...iState,newsFlag:false,})
    }
    if (scrollRef.current >= 800 && !newsFlag) {
      updateState({...iState,newsFlag:true,})
    }
    console.log('scrolll', currentScrollY)
    scrollRef.current = currentScrollY;
  };
  window.addEventListener("scroll", handleScroll, { passive: true });

  return () => window.removeEventListener("scroll", handleScroll);
}, [newsFlag]);

const handlePreviewUrl =(url)=>{
  const win = window.open(url, "_blank");
  win.focus();
}

  return (
    <Fragment>
      <div>
        <Header />
        <div className="TalkArea">
          <div className="Commantabs">
            <Tabs 
              id="controlled-tab-example"
               activeKey={key} 
               onSelect={(k) => handleTab(k)}
               >
              <Tab eventKey="home" title="StreetTalk">
                <div className="container StickyProfile">
                  <div className="row">
                    <div className="col-sm-3 PostionSticky">
                       <div className="TalkLeft">
                      <h4><img src={streetTalkLogo} /></h4>

                      <ul>
                          <div className='streettakMenu'>
                        <i className="fa fa-home" aria-hidden="true"></i>
                          <li onClick={() => handlePath('/')}>
                            <a>Home</a>
                          </li>
                          </div>
                          <div className='streettakMenu'>
                          <i className="fa fa-user" aria-hidden="true"></i>
                          <Link to="/street-talk-profile" onClick={() => userPostDetail(profileData._id)}>
                          <li> 
                            <a>Profile</a>
                          </li>
                          </Link>
                          </div>
                          <div className='streettakMenu'>
                          <i className="fas fa-cog"></i>
                          <li onClick={() => handlePath('/change-password')}>
                            <a>Settings</a>
                          </li>
                          </div>
                          <div className='streettakMenu'>
                          <i className="fas fa-bell"></i>
                          <li onClick={() => handlePath('/')}>
                            <a>Notification</a>
                          </li>
                          </div>
                          {/* <div className='streettakMenu'>
                          <i className="fas fa-comment-alt"></i>
                          <li onClick={() => handlePath('/chat')}>
                            <a>Message</a>
                          </li>
                          </div> */}
                        </ul>
                      </div>
                   
                      <div className="TalkRight">
                      <div className="NewsBox NewsFeed">
                            <h4>News</h4>
                            {streetTakNewsData && streetTakNewsData.Data && streetTakNewsData.Data.length > 0 ?
                            <article onClick={()=>handlePreviewUrl( streetTakNewsData.Data[0].url)}>
                              <aside>
                                <img src={ streetTakNewsData.Data[0].urlToImage} />
                              </aside>
                              <figcaption>
                                <h5>{ streetTakNewsData.Data[0].title}</h5>
                              </figcaption>
                            </article>
                          :''}
                          </div>
                        </div>
                  
                  
                    </div>

                    <div className="col-sm-6 padding_none">
                      <div className="PostArea">
                        <h1>Latest Post</h1>

                        <div className="PostUpload" onClick={redirectLogin}>
                          <figure>
                            <img src={User} />
                          </figure>
                          <input
                            // type="text"
                            placeholder="What is Happening Now ?"
                            // onChange={handleInputChange}
                            disabled
                            autoComplete="off"
                          />
                        </div>
                        <InfiniteScroll
                          dataLength={streetTalkPostData.length}
                          next={fetchMoreData}
                          hasMore={hasNextPage}
                        >
                        {(streetTalkPostData && streetTalkPostData.length>0) ? streetTalkPostData.map((data,i) => (
                          <div className="PostBox">
                          <div className="PostIcon">
                           {data.usersDetail[0].profilePic ?  <img src={data.usersDetail[0].profilePic} /> : `${data.usersDetail[0].firstName.charAt(0).toUpperCase()}${data.usersDetail[0].lastName.charAt(0).toUpperCase()}`}
                          </div>
                          <div className="PostRight">
                          
                          <span></span>
                          <Dropdown alignRight className="FilterCardDropdown PostDots" onClick={redirectLogin} >
                            <Dropdown.Toggle  id="dropdown-basic">
                              <i className="fas fa-ellipsis-h" ></i>
                            </Dropdown.Toggle>
                        </Dropdown>
                            <div className="PostContent" onClick={redirectLogin}>
                            <div className="padinglft">
                              <h4>
                              <div
                                  onMouseEnter={() =>handleMouseHover(data, data._id)}
                                  onMouseLeave={handleMouseLeave}
                                >
                                <Link to="/street-talk-profile" >{data.usersDetail[0].firstName && `${data.usersDetail[0].firstName} ${data.usersDetail[0].lastName}`}</Link>
                                </div>
                                <span className="Time">{`${moment(data.createdAt).fromNow(true)} ago`}</span>
                                <span className="postDate">{moment(data.createdAt).format('MM-DD-YYYY')}</span>
                                <span>{data.shared ? <span>
                                  <img src={Blast} />
                                  <span className="blastBy"> {` Blasted By ${data.usersDetail[0].firstName}  ${data.usersDetail[0].lastName}`}</span>
                                  </span> : ''}
                                </span>
                              </h4> 
                              {(isHovering || isHoveringModal) && (matchId === data._id) &&
                              <Link to="/login" >
                              <div className="hoverModal" 
                                onMouseEnter={() => handleHover(data, data._id)}
                                onMouseLeave={handleLeave}
                              >
                                <figure>
                                 {hoverData.usersDetail[0].profilePic ?  <img src={hoverData.usersDetail[0].profilePic} /> : `${hoverData.usersDetail[0].firstName.charAt(0).toUpperCase()}${hoverData.usersDetail[0].lastName.charAt(0).toUpperCase()}`}
                                </figure>
                                <h5>Follow</h5>
                                    <h4 >{`${hoverData.usersDetail[0].firstName} ${hoverData.usersDetail[0].lastName}`}</h4>
                                <p>{hoverData.usersDetail[0].uniqueId}</p>
                                <p>{hoverData.usersDetail[0].bio}</p>
                                <p>{`${hoverData.followingcount} Following ${hoverData.followerscount} Followers`}</p>
                                </div>
                                </Link>}
                              
                              <p>{data.newCaption} </p>
                              </div>
                            </div>
                            <div className={data.shared ? 'borderShared': ''}>
                                  {data.shared && 
                                  <div>
                                    <figure className="sharedIcon">
                                      {data.actualUserDetail[0].profilePic ? <img src={data.actualUserDetail[0].profilePic} /> : `${data.actualUserDetail[0].firstName.charAt(0).toUpperCase()}${data.actualUserDetail[0].lastName.charAt(0).toUpperCase()}`}
                                    </figure>
                                    <Link to="/street-talk-profile"><h6 className="mt-2 padinglft" style={{color:'#565656'}} onClick={() => userPostDetail(data.actualUserDetail[0]._id)}>{`${data.actualUserDetail[0].firstName} ${data.actualUserDetail[0].lastName}`}</h6></Link>
                                  </div>}
                                 
                                  {/* <p className="padinglft">{data.caption} </p> */}
                                  {data.caption && data.caption.split(/[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi)
                                  .map((part, index) => index % 2 === 0 ?<p className="padinglft">{part}</p>: 
                                  <p className="padinglft linkMargin">
                                  <Preview
                                    url={data.caption}
                                    loadingText="loading..."
                                      notFound="URL Not Found!"
                                  />
                                </p>)}

                            <div className="PostImages padingleft">
                            {data.video && data.hasOwnProperty("video") && data.video.map((video , i) => {
                                return (
                                 <React.Fragment >
                                {i <= 2 && <span className={data.video.length > 1 ? "Video" :''}>
                                <Link >
                                  <Player  autoPlay muted className={data.video.length == 1 ? 'singleVideo' : ''}>
                                <source className={data.video.length == 1 ? 'singleVideo' : ''} src={video} />
                                <BigPlayButton position="center" />
                              </Player>
                              </Link>
                                  </span> }
                                {data.video.length === 4 && i === 3 && <span className="Video">
                                <Link >
                                  <Player autoPlay muted className={data.video.length == 1 ? 'singleVideo' : ''}>
                                    <source className={data.video.length == 1 ? 'singleVideo' : ''} src={video} />
                                    <BigPlayButton position="center" />
                                  </Player>
                                  </Link>
                                  </span>}
                                
                                </React.Fragment>
                                )
                                })}
                                {data.video && data.video.length > 4 && <span className="Video" style={{cursor:'pointer'}}>
                                <Link >
                                <Player  autoPlay muted className={data.video.length == 1 ? 'singleVideo' : ''}>
                                <source className={data.video.length == 1 ? 'singleVideo' : ''} src={data.video[data.video.length-1]} />
                                <BigPlayButton position="center" /> 
                              </Player>
                              </Link>
                                <span className="Count">+{data.video.length - 4}</span></span>}
                                {/* </div> */}

                               {/* <Link to="/street-talk-details" > */}
                           
                             {data.images && data.images.map((img , i) => {
                                return (
                                 <React.Fragment>
                                {i <= 2 && <span onClick={()=>lightBoxOpen(data.images)} className={data.images.length > 1 ? "Images" :''}><img className={data.images.length == 1 ? 'singleImg' : ''}src={img} /></span> }
                                {data.images.length === 4 && i === 3 && <span onClick={()=>lightBoxOpen(data.images)} className="Images"><img className={data.images.length == 1 ? 'singleImg' : ''} src={img} /></span>}
                                
                                </React.Fragment>
                                )
                                })}
                                {data.images && data.images.length > 4 && <span onClick={()=>lightBoxOpen(data.images)} className="Images" style={{cursor:'pointer'}}>
                                <img className={data.images.length == 1 ? 'singleVideo' : ''} src={data.images[data.images.length-1]} />
                                <span className="Count">+{data.images.length - 4}</span></span>}
                                {/* </Link> */}
                               
                                </div>
                              </div>
                            <div className="LikeLine" onClick={redirectLogin}>
                              <ul>
                              <li>
                                  <a title={data.blastStatus ? 'Blasted' :'Blast'}>
                                    {data.blastStatus ? <img src={Blast}   onClick={()=>postLke(data._id, 3)}/> :
                                    <img src={Icon1}   onClick={()=>postLke(data._id, 0)}/> }
                                    <span onClick={()=> postLikedUsers(data._id, 'blast')}>{data.blastCount}</span>
                                  </a>
                                </li>
                                <li>
                                  <a title='Comments'>
                                    <img src={Icon2} onClick={() => showComment(data._id)}/>
                                    <span>{data.numberOfComments}</span>
                                  </a>
                                </li>
                                <li>
                                  <a title='Like'>
                                  {data.likedStatus ?  <img src={Like} onClick={()=>postLke(data._id, 3)} /> :
                                    <img src={Icon4}  onClick={()=>postLke(data._id, 1)} />}
                                     <span onClick={()=> postLikedUsers(data._id, 'like')}>{data.likeCount}</span>
                                  </a>
                                </li>
                                <li>
                                  <a title='Dislike'>
                                  {data.unlikedStatus ? <img src={Dislike}   onClick={()=>postLke(data._id, 3)}/> :
                                    <img src={Icon6}  onClick={()=>postLke(data._id, 2)}/> }
                                    <span onClick={()=> postLikedUsers(data._id, 'unlike')}>{data.unlikeCount}</span>
                                  </a>
                                </li>
                                <li>
                                  <a title='Share'>
                                    <img src={Icon5} />
                                  </a>
                                </li>
                              </ul>
                            </div>

                            <div className="CommentPost" onClick={redirectLogin}>
                              <figure>
                                <img src={User} />
                              </figure>
                              <input type="text" disabled placeholder="Leave your thoughts here...." />
                            </div>
                          </div>
                        </div>
                          )) :                           
                        <CustomLoader loading={streetTalkLoader} />
                          }
                      {isOpen &&(
                            <LightboxCustom
                              images={lightBoxImg}
                              lightBoxOpen={()=>updateState({
                                ...iState, isOpen: false
                              })}
                            />
                            )}
                        </InfiniteScroll>
                      </div>
                    </div>
                    
                    <div className="col-sm-3 BottomSticky" id={newsFlag ? 'scrollNewsBox':''}>
                      <div className="TalkRight">
                      <div className="NewsBox NewsFeed">
                            <h4>News</h4>
                            {streetTakNewsData && streetTakNewsData.Data && streetTakNewsData.Data.length > 0 ?  streetTakNewsData.Data.map((item,i)=>(
                            <article onClick={()=>handlePreviewUrl(item.url)}>
                              <aside>
                                <img src={item.urlToImage} />
                              </aside>
                              <figcaption>
                                <h5>{item.title}</h5>
                              </figcaption>
                            </article>
                          )):''}
                          </div>
                        </div>
                        </div>
                  
                    <div className="clear"></div>
                  
                  </div>
                </div>
              </Tab>
      
                <Tab eventKey="profile" title="Vendors"  >
              <div className="container StickyProfile">
                  <div className="row">
                    <div className="col-sm-3 PostionSticky">
                    <div className="TalkLeft">
                      <h4><img src={streetTalkLogo} /></h4>

                      <ul>
                          <div className='streettakMenu'>
                        <i className="fa fa-home" aria-hidden="true"></i>
                          <li onClick={() => handlePath('/street-talk-public')}>
                            <a>Home</a>
                          </li>
                          </div>
                          <div className='streettakMenu'>
                          <i className="fa fa-user" aria-hidden="true"></i>
                          <li onClick={() => handlePath('/dashboard')}> 
                            <a>Profile</a>
                          </li>
                          </div>
                          <div className='streettakMenu'>
                          <i className="fas fa-cog"></i>
                          <li onClick={() => handlePath('/change-password')}>
                            <a>Settings</a>
                          </li>
                          </div>
                          <div className='streettakMenu'>
                          <i className="fas fa-bell"></i>
                          <li onClick={() => handlePath('/')}>
                            <a>Notification</a>
                          </li>
                          </div>
                          {/* <div className='streettakMenu'>
                          <i className="fas fa-comment-alt"></i>
                          <li onClick={() => handlePath('/chat')}>
                            <a>Message</a>
                          </li>
                          </div> */}
                        </ul>
                      </div>
                      <div className="TalkRight">
                      <div className="NewsBox NewsFeed">
                            <h4>News</h4>
                            {streetTakNewsData && streetTakNewsData.Data && streetTakNewsData.Data.length > 0 ?
                            <article onClick={()=>handlePreviewUrl( streetTakNewsData.Data[0].url)}>
                              <aside>
                                <img src={ streetTakNewsData.Data[0].urlToImage} />
                              </aside>
                              <figcaption>
                                <h5>{ streetTakNewsData.Data[0].title}</h5>
                              </figcaption>
                            </article>
                          :''}
                          </div>
                        </div>
                    </div>

                    <div className="col-sm-6 padding_none">
                      <div className="PostArea">
                        <h1>Latest Post</h1>

                        <div className="PostUpload" onClick={redirectLogin}>
                          <figure>
                            <img src={User} />
                          </figure>
                          <figcaption>
                            <ul>
                              <li>
                              <div className="FeedImages">
                                </div>

                                <label htmlFor='profileImage'>
                                  {video.length == 0 && oldVideo.length == 0 &&
                                  <i className="fa fa-picture-o"></i>}
                                </label>
                              </li>
                              <li>
                                <label htmlFor='video'>
                                 {images.length == 0 && oldImage.length == 0 &&
                                  <i className="fa fa-video-camera"></i>}
                                </label>
                              </li>
                            </ul>
                          </figcaption>
                        </div>
                        <InfiniteScroll
                          dataLength={vendorTalkPostData.length}
                          next={fetchMoreData}
                          hasMore={hasNextPage}
                        >
                        {(vendorTalkPostData && vendorTalkPostData.length>0) ? vendorTalkPostData.map((data,i) => (
                          <div className="PostBox">
                          <div className="PostIcon">
                           {data.usersDetail[0].profilePic ?  <img src={data.usersDetail[0].profilePic} /> : `${data.usersDetail[0].firstName.charAt(0).toUpperCase()}${data.usersDetail[0].lastName.charAt(0).toUpperCase()}`}
                          </div>
                          <div className="PostRight">
                          {profileData._id === data.userId ? 
                          <React.Fragment>
                          <span></span>
                          <Dropdown alignRight className="FilterCardDropdown PostDots"  onClick={redirectLogin} >
                            <Dropdown.Toggle  id="dropdown-basic">
                              <i className="fas fa-ellipsis-h"></i>
                            </Dropdown.Toggle>
                        </Dropdown>
                        </React.Fragment>
                         : ''}
                            <div className="PostContent" onClick={redirectLogin}>                              
                                <div className="padinglft">
                              <h4>
                              <div
                                onMouseEnter={() =>handleMouseHover(data, data._id)}
                                onMouseLeave={handleMouseLeave}
                              >
                                <Link to="/street-talk-profile" >{data.usersDetail[0].firstName && `${data.usersDetail[0].firstName} ${data.usersDetail[0].lastName}`}</Link>
                              </div>
                                <span className="Time">{`${moment(data.createdAt).fromNow(true)} ago`}</span>
                                <span className="postDate">{moment(data.createdAt).format('MM-DD-YYYY')}</span>
                                <span>{data.shared ? <span>
                                  <img src={Blast} />
                                  <span className="blastBy"> {` Blasted By ${data.usersDetail[0].firstName}  ${data.usersDetail[0].lastName}`}</span>
                                  </span> : ''}
                                </span>
                              </h4>
                              {(isHovering || isHoveringModal) && (matchId === data._id) &&
                              <Link to="/login" >
                              <div className="hoverModal" 
                                onMouseEnter={() => handleHover(data, data._id)}
                                onMouseLeave={handleLeave}
                              >
                               <figure>
                                 {hoverData.usersDetail[0].profilePic ?  <img src={hoverData.usersDetail[0].profilePic} /> : `${hoverData.usersDetail[0].firstName.charAt(0).toUpperCase()}${hoverData.usersDetail[0].lastName.charAt(0).toUpperCase()}`}
                                </figure>
                               <h5>Follow</h5>
                                    <h4 >{`${hoverData.usersDetail[0].firstName} ${hoverData.usersDetail[0].lastName}`}</h4>
                                <p>{hoverData.usersDetail[0].uniqueId}</p>
                                <p>{hoverData.usersDetail[0].bio}</p>
                                <p>{`${hoverData.followingcount} Following ${hoverData.followerscount} Followers`}</p>
                                </div>
                                </Link>}
                              <p>{data.newCaption} </p>
                            </div>
                            </div>
                            <div className={data.shared ? 'borderShared': ''}>
                                    {data.shared &&
                                     <div >
                                    <div className="sharedIcon">
                                      {data.actualUserDetail[0].profilePic ? <img src={data.actualUserDetail[0].profilePic} /> : `${data.actualUserDetail[0].firstName.charAt(0).toUpperCase()}${data.actualUserDetail[0].lastName.charAt(0).toUpperCase()}`}
                                    </div>
                                    <Link to="/street-talk-profile"><h6 className="padinglft mt-2" style={{color:'#565656'}} onClick={() => userPostDetail(data.actualUserDetail[0]._id)}>{`${data.actualUserDetail[0].firstName} ${data.actualUserDetail[0].lastName}`}</h6></Link>
                                  </div>}
                                  {/* <p className="padinglft">{data.caption} </p> */}
                                  {data.caption && data.caption.split(/[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi)
                                  .map((part, index) => index % 2 === 0 ?<p className="padinglft">{part}</p>: 
                                  <p className="padinglft linkMargin">
                                 <Preview
                                    url={data.caption}
                                    loadingText="loading..."
                                      notFound="URL Not Found!"
                                  />
                                </p>)}

                            <div className="PostImages padingleft">
                            {data.video && data.hasOwnProperty("video") && data.video.map((video , i) => {
                                return (
                                 <React.Fragment>
                                {i <= 2 && <span className={data.video.length > 1 ? "Video" :''}>
                                <Link >
                                  <Player autoPlay muted className={data.video.length == 1 ? 'singleVideo' : ''}>
                                <source className={data.video.length == 1 ? 'singleVideo' : ''} src={video} />
                                <BigPlayButton position="center" />
                              </Player>
                              </Link>
                                  </span> }
                                {data.video.length === 4 && i===3 && <span className="Video">
                                <Link >
                                  <Player autoPlay muted className={data.video.length == 1 ? 'singleVideo' : ''}>
                                    <source className={data.video.length == 1 ? 'singleVideo' : ''} src={video} />
                                    <BigPlayButton position="center" />
                                  </Player>
                                  </Link>
                                  </span>}
                                
                                </React.Fragment>
                                )
                                })}
                                {data.video && data.video.length > 4 && <span className="Video" style={{cursor:'pointer'}}>
                                <Link>
                                <Player  autoPlay muted className={data.video.length == 1 ? 'singleVideo' : ''}>
                                <source className={data.video.length == 1 ? 'singleVideo' : ''} src={data.video[data.video.length-1]} />
                                <BigPlayButton position="center" />
                              </Player>
                              </Link>
                                <span className="Count">+{data.video.length - 4}</span></span>}


                            {/* <Link to="/street-talk-details" > */}
                             {data.images && data.images.map((img , i) => {
                                return (
                                 <React.Fragment>
                              
                                {i <= 2 && <span onClick={()=>lightBoxOpen(data.images)} className={data.images.length > 1 ? "Images" :''}><img className={data.images.length == 1 ? 'singleImg' : ''}src={img} /></span> }
                                {data.images.length === 4 && i === 3 && <span onClick={()=>lightBoxOpen(data.images)} className="Images"><img className={data.images.length == 1 ? 'singleImg' : ''} src={img} /></span>}
                                
                                </React.Fragment>
                                )
                                })}
                                {data.images && data.images.length > 4 && <span onClick={()=>lightBoxOpen(data.images)} className="Images" style={{cursor:'pointer'}}>
                                <img className={data.images.length == 1 ? 'singleImg' : ''} src={data.images[data.images.length-1]} />
                                <span className="Count">+{data.images.length - 4}</span></span>}
                                {/* </Link> */}
                                </div>
                                </div>

                            <div className="LikeLine" onClick={redirectLogin}>
                              <ul>
                              <li>
                                  <a title={data.blastStatus ? 'Blasted' :'Blast'}>
                                    {data.blastStatus ? <img src={Blast}   onClick={()=>postLke(data._id, 3)}/> :
                                    <img src={Icon1}   onClick={()=>postLke(data._id, 0)}/> }
                                    <span onClick={()=> postLikedUsers(data._id, 'blast')}>{data.blastCount}</span>
                                  </a>
                                </li>
                                <li>
                                  <a title='Comments'>
                                    <img src={Icon2} onClick={() => showComment(data._id)}/>
                                    <span>{data.numberOfComments}</span>
                                  </a>
                                </li>
                                <li>
                                  <a title='Like'>
                                  {data.likedStatus ?  <img src={Like} onClick={()=>postLke(data._id, 3)} /> :
                                    <img src={Icon4}  onClick={()=>postLke(data._id, 1)} />}
                                     <span onClick={()=> postLikedUsers(data._id, 'like')}>{data.likeCount}</span>
                                  </a>
                                </li>
                                <li>
                                  <a title='Dislike'>
                                  {data.unlikedStatus ? <img src={Dislike}   onClick={()=>postLke(data._id, 3)}/> :
                                    <img src={Icon6}  onClick={()=>postLke(data._id, 2)}/> }
                                    <span onClick={()=> postLikedUsers(data._id, 'unlike')}>{data.unlikeCount}</span>
                                  </a>
                                </li>
                                <li>
                                  <a title='Share'>
                                    <img src={Icon5} />
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="CommentPost" onClick={redirectLogin}>
                              <figure>
                                <img src={User} />
                              </figure>
                              <input type="text" disabled placeholder="Leave your thoughts here...." />
                          </div>
                          </div>
                        </div>
                          )) :           ''                
                        // <CustomLoader loading={vendorTalkLoader} />:
                          }
                          </InfiniteScroll>
                      </div>
                    </div>
                    
                    <div className="col-sm-3">
                      <div className="TalkRight">
                        <div className="NewsBox NewsFeed">
                            <h4>News</h4>
                            {streetTakNewsData && streetTakNewsData.Data && streetTakNewsData.Data.length > 0 ?  streetTakNewsData.Data.map((item,i)=>(
                            <article onClick={()=>handlePreviewUrl(item.url)}>
                              <aside>
                                <img src={item.urlToImage} />
                              </aside>
                              <figcaption>
                                <h5>{item.title}</h5>
                              </figcaption>
                            </article>
                          )):''}
                          </div>
                        </div>
                        </div>
                  
                    <div className="clear"></div>
                  
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>

        <Footer />
      </div>
    </Fragment>
  );
}

export default StreetTalkPublic