import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-country-select/dist/react-bootstrap-country-select.css';
import reportWebVitals from './reportWebVitals'; 
import { Provider } from 'react-redux'
import store from './redux/store'
import 'react-phone-input-2/lib/style.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "video-react/dist/video-react.css";
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
 document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
 

  	