import { forgetPasswordAction, resetPasswordAction, changePasswordAction } from '../actionTypes'
import {Url} from '../../config/config'
import axios from 'axios'
import {isLoggedIn} from '../../utils'

const token = isLoggedIn('userData')
export const forgotPasswordInitiate = () => ({
  type: forgetPasswordAction.FORGOT_PASSWORD_INITIATE
})

export const forgotPasswordSuccess = () => ({
  type: forgetPasswordAction.FORGOT_PASSWORD_SUCCESS
})

export const forgotPasswordFailure = () => ({
  type: forgetPasswordAction.FORGOT_PASSWORD_FAILURE
})

export function sendOtp(payload) {
  return dispatch => {
     dispatch(forgotPasswordInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/forgetpassword`, payload)
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                    const setData = {id: data.Data._id, email: data.Data.emailId}
                      window.localStorage.setItem('signupData',JSON.stringify(setData))
                      dispatch(forgotPasswordSuccess())
                  }
                  else{
                    dispatch(forgotPasswordFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(forgotPasswordFailure())
                  reject(err);
              })
      );
  }
}

export const resetPasswordInitiate = () => ({
  type: resetPasswordAction.USER_RESET_PASSWORD_INITIATE
})

export const resetPasswordSuccess = () => ({
  type: resetPasswordAction.USER_RESET_PASSWORD_SUCCESS
})

export const resetPasswordFailure = () => ({
  type: resetPasswordAction.USER_RESET_PASSWORD_FAILURE
})

export function changePassword(payload) {
  return dispatch => {
     dispatch(resetPasswordInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/changeForgetPassword`, payload)
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(resetPasswordSuccess())
                  }
                  else{
                    dispatch(forgotPasswordFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(resetPasswordFailure())
                  reject(err);
              })
      );
  }
}

export const userChangePasswordInitiate = () => ({
  type: changePasswordAction.USER_CHANGE_PASSWORD_INITIATE
})

export const userChangePasswordSuccess = () => ({
  type: changePasswordAction.USER_CHANGE_PASSWORD_SUCCESS
})

export const userChangePasswordFailure = (data) => ({
  type: changePasswordAction.USER_CHANGE_PASSWORD_FAILURE
})

export function resetPassword(payload) {
const token = isLoggedIn('userData')
  return dispatch => {
     dispatch(resetPasswordInitiate())
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/resetPassword`, payload,{
            headers: { token: `${token}` }
          })
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(resetPasswordSuccess())
                  }
                  else{
                    dispatch(forgotPasswordFailure())
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(resetPasswordFailure())
                  reject(err);
              })
      );
  }
}