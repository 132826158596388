import React, { Component, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
  
const initialState ={
  photoIndex: 0,
}
const LightboxCustom = ({images,lightBoxOpen}) => {
  const [iState, updateState] = useState(initialState)
  const {
    photoIndex
  } = iState
    return (
      <div> 
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => lightBoxOpen(false)}
            onMovePrevRequest={() =>
              updateState({
                ...iState, photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              updateState({
                ...iState, photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
      </div>
    );
  }

  export default LightboxCustom