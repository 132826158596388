import { combineReducers } from 'redux'
import login from './reducers/loginReducer'
import signUp from './reducers/signUpReducer'
import forgotPassword from './reducers/setPasswordReducer'
import userProfile from './reducers/userProfileReducer'
import buddies from './reducers/buddiesReducer'
import streetTalk from './reducers/streetTalkReducer'

const rootReducer = combineReducers({
  login,
  signUp,
  forgotPassword,
  userProfile,
  buddies,
  streetTalk,
})

export default rootReducer