import React from 'react'
import '../App.css'; 
import { Link } from "react-router-dom"; 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'; 
import streetTalkLogo from "../assets/media/streettalk.svg"

import Banner from "../assets/media/about.jpg";
import GB from "../assets/media/GB.png";
              
import Header from './header';       
import Footer from './footer';
          
class about extends React.Component {
  	render() { 
	    return <div> 
		    	<div className="Header">
		    		<Header/>
		    	</div> 
				 
		        <div className="BannerArea">
		        	<img src={Banner} />
		        	<div className="BannerText">
			            <div className="container">
			                <h4><span>About</span> StreetTak</h4>
			            </div>
			        </div>
		        </div>
			     
				<section>
			        <div className="AboutArea">
			            <div className="container">
			                
			                <div className="AboutLogo">
			                    <img src={streetTalkLogo} />
			                </div>

			                <div className="AboutBox">
			                    <div className="row">
			                        <div className="col-sm-6">
			                            <div className="AboutLeft">
			                                <h3>Our <span>Aim</span></h3>
			                                <p>The focus of StreetTak is to provide a platform where all people can participate in a public conversation and speak freely and express their freedom of speech in safety without the fear of being “deplatformed, censured or bullied” and we support initiatives that defend and respect all voices by promoting free expression and defending civil liberties and not to encourage any type of violence.</p>

			                            </div>
			                        </div>     
			                    </div>
			                </div>
			                
			                <div className="AboutBox">
			                    <div className="row">
			                        <div className="col-sm-6 offset-sm-6">
			                            <div className="AboutLeft">
			                                <h3>Our <span>mission</span></h3>

			                                <p>StreetTak is dedicated to enhancing the user’s experience by offering plenty of opportunities for users to engage with each other in an interesting and safe online social feed and e-commerce environment to converse civilly and discuss solutions to the world pressing problems and defend their values, passions, accomplishments, and ideas.</p>      
			                            </div>
			                        </div>     
			                    </div>
			                </div>        
			                
			                <div className="AboutBox">
			                    <div className="row">
			                        <div className="col-sm-6">
			                            <div className="AboutLeft">
			                                <h3>our <span>vision</span></h3>
			                                
			                                <p>Is to provide a safe online platform where users can express themselves freely in a feed and make use of the power of their followers to form a collective bargaining power to purchase products in GroupBuy.</p>
																			</div>
			                        </div>     
			                    </div>
			                </div>
			                
			                <div className="AboutBox">
			                    <div className="row">
			                        <div className="col-sm-6 offset-sm-6">
			                            <div className="AboutLeft">
			                                <h3>our <span>Believe</span></h3>
																			<p>Is to support equality and universal acceptance for all people and provide to participate in a public conversation where the distribution of misleading information are minimized, especially when its intent is to cause offline harmBanner.</p>        
			                            </div>
			                        </div>     
			                    </div>
			                </div> 
			            
									</div>
			        </div>
			    </section>

			<Footer/>
		</div>
  	}   
}
export default about