
import React,{ useState } from "react";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;
// const override = css`
//   display: block;
//   margin: 0 auto;
//   border-color: '#0042b9';
// `;

const CustomLoader = (props) => {
  const { loading} = props

  return (
    <div className='customLoader'>
      <ClipLoader color={'#0042b9'} loading={loading} size={50} />
    </div>
  );
}

export default CustomLoader;