import { loginAction } from '../actionTypes'

const initialState = {
  userData: {},
  loader: false,
  logOutLoader: false,
  loginStatus: false
}

export default function userLogin(state = initialState, {type, payload}) {
  switch(type) {
    case loginAction.USER_LOGIN_INITIATE: {
      return {
        ...state, loader: true, userData: {}
      }
    }
    case loginAction.USER_LOGIN_SUCCESS: {
      return {
        ...state, loader: false, loginStatus: true, userData: payload
      }
    }
    case loginAction.USER_LOGIN_FAILURE: {
      return {
        ...state, loader: false,userData: payload
      }
    }
    case loginAction.USER_LOGOUT_INITIATE: {
      return {
        ...state, logOutLoader: true, userData: {}
      }
    }
    case loginAction.USER_LOGOUT_SUCCESS: {
      return {
        ...state, logOutLoader: false, userData:{}, loginStatus: false
      }
    }
    case loginAction.USER_LOGOUT_FAILURE: {
      return {
        ...state, logOutLoader: false
      }
    }
    default: 
      return state
  }
}