import { signUpAction } from '../actionTypes'
import {Url} from '../../config/config'
import axios from 'axios'

export const userSignupInitiate = () => ({
  type: signUpAction.USER_SIGNUP_INITIATE
})

export const userSignupSuccess = () => ({
  type: signUpAction.USER_SIGNUP_SUCCESS
})

export const userSignUpFailure = (data) => ({
  type: signUpAction.USER_SIGNUP_FAILURE,
  payload: data
})

export function registerUser(payload) {
  return dispatch => {
     dispatch(userSignupInitiate(payload))
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/signup`, payload)
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                    const setData = {id: data.Data._id, email: data.Data.emailId}
                      window.localStorage.setItem('signupData',JSON.stringify(setData))
                      dispatch(userSignupSuccess())
                  }
                  else{
                    dispatch(userSignUpFailure(data))
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(userSignUpFailure(err))
                  reject(err);
              })
      );
  }
}

export const verifyOtpInitiate = () => ({
  type: signUpAction.VERIFY_OTP_INITIATE,
})

export const verifyOtpSuccess = (data) => ({
  type: signUpAction.VERIFY_OTP_SUCCESS,
  payload: data
})

export const verifyOtpFailure = () => ({
  type: signUpAction.VERIFY_OTP_FAILURE
})

export function verifyOtp(payload) {
  return dispatch => {
     dispatch(verifyOtpInitiate(payload))
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/verifyOtp`, payload)
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(verifyOtpSuccess())
                  }
                  else{
                    dispatch(verifyOtpFailure(data))
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(verifyOtpFailure(err))
                  reject(err);
              })
      );
  }
}

export const resendOtpInitiate = () => ({
  type: signUpAction.RESEND_OTP_INITIATE,
})

export const resendOtpSuccess = () => ({
  type: signUpAction.RESEND_OTP_SUCCESS
})

export const resendOtpFailure = () => ({
  type: signUpAction.RESEND_OTP_FAILURE
})

export function resendOtpUser(payload) {
  return dispatch => {
     dispatch(resendOtpInitiate(payload))
      return new Promise((resolve, reject) =>
          axios.post(`${Url}gbmall/user/resendOTP`, payload)
              .then(response => {
                const data = response.data
                  if (data.response_code && data.response_code == 200){
                      dispatch(resendOtpSuccess())
                  }
                  else{
                    dispatch(resendOtpFailure(data))
                  }
                  resolve(data);
              })
              .catch(err => {
                  dispatch(resendOtpFailure(err))
                  reject(err);
              })
      );
  }
}
