import React from 'react'
import '../App.css';
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import logo from "../assets/media/gbLogo.png";
import facebook from "../assets/media/facebook.svg";
import instagram from "../assets/media/instagram.svg";
import youtube from "../assets/media/youtube.svg";
import twitter from "../assets/media/twitter.svg";
import streetTalk from "../assets/media/streettalk.svg"

const footer = () => {

	let path = window.location.href.split('/')
	return (
		<footer>
			<div className="Footer">
				<Container>
					<Row>
						<Col sm={6}>
							<div className="Foots">
								<img src={streetTalk} /> 

								<p>StreetTak is dedicated to enhancing the user’s experience by offering plenty of opportunities for users to engage with each other in an interesting and safe online social feed and e-commerce environment.</p>

								<p><i className="fa fa-copyright"></i> 2021 StreetTak All Right Reserved</p>

							</div>
						</Col>
						<Col sm={2}>
							<div className="Foots">
								<h3>Quick Links</h3>
								<ul>
									<li>
										<Link to="/about">About Us</Link>
									</li>
									{/* <li>
										<Link to="/vendor-list">Vendors</Link>
									</li> */}
									<li>
										<Link to="/contact">Contact</Link>
									</li>
									<li>
										<Link to="/terms-and-condition">Terms & conditions</Link>
									</li>
									<li>
									<Link to="/privacy-policy">Privacy & Policy</Link>
									</li>
								</ul>
							</div>
						</Col>
						{/* <Col sm={2}>
							<div className="Foots">
								<h3>Useful Links</h3>
								<ul>
									<li>
										<Link to="/policy">Return</Link>
									</li>
									<li>
										<Link to="/support">Support Policy</Link>
									</li>
									<li>
										<Link to="/size-chart">Size Guide</Link>
									</li>
									<li>
										<Link to="/faq">FAQs</Link>
									</li>
								</ul>
							</div>
						</Col> */}
						<Col sm={2} className="p_left0">
							{/* <div className="Foots">
								<h3>Subscribe</h3>
								<p>Get E-mail updates about our latest shop and special offers. </p>
							</div> */}
							<div className="Foots">
								<h3>Follow Us</h3>
								<ol>
									<li><a href="javascipt:void(0);"><img src={facebook} /></a></li>
									<li><a href="javascipt:void(0);"><img src={instagram} /></a></li>
									<li><a href="javascipt:void(0);"><img src={youtube} /></a></li>
									<li><a href="javascipt:void(0);"><img src={twitter} /></a></li>
								</ol>
							</div>
						</Col>
					</Row>
				</Container>

			</div>
		</footer>
	)
}
export default footer