import React from 'react'
import '../App.css'; 
import { Link } from "react-router-dom";  

import Header from './header';       
import Footer from './footer';
import Sidenav from './sidenav'; 

import product1 from "../assets/media/Product-1.jpg";
import product2 from "../assets/media/Product-2.jpg"; 
 

class ChatDetails  extends React.Component {
  	render() { 
	    return <div> 
		   
		   <Header/> 
				 
		    	<div className="DashboardArea"> 
		    		<div className="container">
		    			<div className="row">
		    				<div className="col-sm-3">
		    					<Sidenav/> 
		    				</div>
 
		    				<div className="col-sm-9"> 
		    					<div className="DashboardRight"> 
									<h1 className="Title">Chat</h1>

						    		<div className="MessagesDetails">  
					                    <ul>                        
					                        <li className="OtherMessage"> 
					                            <figure><img src={product1} /></figure> 
					                            <div className="Message"> 
					                                <p>Are we meeting today?</p> 
					                                <span className="time">10:10 AM, Today</span>                          
					                            </div>
					                        </li>
					                        
					                        <li className="OtherMessage"> 
					                            <figure><img src={product1} /></figure> 
					                            <div className="Message"> 
					                                <p>Hi Aiden, how are you? How is the project coming along?</p>
					                                <p>Are we meeting today?</p> 
					                                <span className="time">10:15 AM, Today</span>                          
					                            </div>
					                        </li>

					                        <li className="Mymessage"> 
					                            <div className="Message">
					                                <p>Project has been already finished and I have results to show you.</p>
					                                <span className="time">10:15 AM, Today</span>
					                            </div>                  
					                        </li>
					                        
					                        <li className="OtherMessage"> 
					                            <figure><img src={product1} /></figure> 
					                            <div className="Message"> 
					                                <p>Are we meeting today I have results?</p>
					                                <p>Project has been already finished and to show you.</p> 
					                                <span className="time">10:18 AM, Today</span>                          
					                            </div>
					                        </li>

					                        <li className="Mymessage"> 
					                            <div className="Message">
					                                <p>Well we have good budget for the project</p>
					                                <span className="time">10:25 AM, Today</span>
					                            </div>                  
					                        </li>

					                    </ul>

					                    <div className="MessageReply">
					                        <input type="text" placeholder="Type Something ..." className="form-control" />
					                        <div className="ReplyIcon"> 
					                            <button>Send</button>                            
					                        </div>
					                    </div>
					                </div>
							    	  
		    					</div> 
		    				</div> 
		    			</div> 
		    		</div>
		       	</div> 

			<Footer/>
		</div>
  	}
}
export default ChatDetails 