import React, { useRef, useState, useEffect } from 'react'
import '../App.css';
import Header from './header';
import { useHistory } from "react-router-dom";
import { isLoggedIn } from '../utils'
import StreetTakLogo from '../assets/media/StreetTakLogo.png'
import Biglogo from '../assets/media/Big-logo.png'
import ArrowIcon from '../assets/media/Arrow-Icon_1Arrow- Icon.png'
import streettakScreenShor from '../assets/media/streettak-screenshot.png'
import streettakUserprofile from '../assets/media/streettak-userprofile.png'
import streettakConnect from '../assets/media/streettak-connect.png'
import streeettakNews from '../assets/media/streeettak-news.png'
import streettakMarketplace from '../assets/media/streettak-marketplace.png'
import streettakFeatures from '../assets/media/streettak-features.png'
import streettakFeaturenews from '../assets/media/streettak-featurenews.png'
import streettakShare from '../assets/media/streettak-share.png'
import streettakEngage from '../assets/media/streettak-engage.png'
import streettakCommunity from '../assets/media/streettak-community.png'
import streettakBuy from '../assets/media/streettak-buy.png'
import StreetTakProfile from '../assets/media/StreetTak-profile.png'
import StreetTakProfile2 from '../assets/media/StreetTak-profile2.png'
import GooglePlay from '../assets/media/google-play-big.png'
import AppleApp from '../assets/media/itunes-big.png'
import Screenshot_Top from "../assets/media/images/street_tak_top_screenshot.png";
import ScreenShot_down from "../assets/media/images/streettak_left_latest.png";
import ScreenShot_down_right from "../assets/media/images/streettak_right_latest.png";
import GB_MarketPlace from "../assets/media/images/GB_Marketplace.png";
const initialState ={
  scrollFlag:false,
  navMenuFlag: false
}
const StreettakLandingPage = () => {   
  const history = useHistory()
  const myRef = useRef(null);
  let scrollRef = useRef(0)
  const myFeatureRef = useRef(null);
  const loginStatus = isLoggedIn('userData')
  const [iState, updateState] = useState(initialState)
  const {scrollFlag,navMenuFlag} = iState

  const executeScroll = () => {myRef.current.scrollIntoView()
    updateState({
      ...iState,navMenuFlag:false
    })}
  const executeFeatureScroll = () => {myFeatureRef.current.scrollIntoView()
    updateState({
      ...iState,navMenuFlag:false
    })
}
  const handleStreetalk = () => {
    if (loginStatus) {
      history.push('/street-talk')
    } else {
      history.push('/street-talk-public')
    }
  }

  const handleFlag =()=> {
    if(!navMenuFlag) {
      updateState({
        ...iState,navMenuFlag:true
      })
      console.log(iState)
    } else {
      updateState({
        ...iState,navMenuFlag:false
      })
    }
  }
  // console.log("updateState", updateState)
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (scrollRef.current <= 125 && scrollFlag) {
        updateState({...iState,scrollFlag:false,})
      }
      if (scrollRef.current >= 125 && !scrollFlag) {
        updateState({...iState,scrollFlag:true,})
      }
      scrollRef.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollFlag]);

  return (
    <div>
      <div data-animation="default" className={ scrollFlag ? "navbar w-nav headernew active":"navbar w-nav headernew"} data-easing2="ease-in-quad" data-easing="ease-in-quad" data-collapse="medium" data-w-id="860509fe-e33d-2178-c932-660930665508" role="banner" data-duration="400" data-doc-height="1">
        <div className="nav-block">
          <a href="javascript:void(0)" aria-current="page" className="brand w-nav-brand w--current"  onClick={() => history.push('/')}>
            
            <img src={StreetTakLogo} width="222" alt="" className="brand" />
          </a>
          <nav role="navigation" className={navMenuFlag ? "nav-menu w-nav-menu menuItem" :"nav-menu w-nav-menu"}>
            <a href="javascript:void(0)" onClick={executeScroll} className="nav-link w-nav-link">How it works</a>
            <a href="javascript:void(0)" onClick={executeFeatureScroll} className="nav-link w-nav-link">Features</a>
            <a href="javascript:void(0)" onClick={() => history.push('/about')} className="nav-link w-nav-link">About</a>
            <a href="https://streettak.com/gbMall/#/" target='_blank'  className="nav-link w-nav-link"><img src={GB_MarketPlace} width="92px" alt=""  /></a>
            {!loginStatus ? <a href="javascript:void(0)" onClick={() => history.push('/register')} className="nav-link w-nav-link">Become a Member</a> : ''}
          </nav>
          {/* {!loginStatus ? */}
            <div className="link-arrow nav">
              <a href="javascript:void(0)" onClick={() => history.push('/login')} className="link-button ">Login</a>
              <img src={ArrowIcon} loading="lazy" alt="StreetTak learn more arrow" className="arrow" />
            </div>
             {/* : ''} */}
          {/* {loginStatus ? */}
            <div className="menu-button w-nav-button" onClick={handleFlag}>
              <div className="menu-icon w-icon-nav-menu"></div>
            </div>
            {/* : ''} */}
        </div>
      </div>

      <div className="header-section wf-section">
        <div className="hero-block">
          <div data-w-id="3516d2a7-340b-914d-a622-e32a4024563c" className="hero-content landing">
            <h1 className="hero-title">REMEMBER HOW IT FELT TO EXPRESS YOUR FREEDOM AND SPEAK FREELY ON THE STREET?<span className="hero-text-span"></span></h1>
            <div className="hero-text">Join Streettak and express your freedom.</div>
            <div className="hero-buttons-grid" >
            <a href="https://play.google.com/store/apps/details?id=com.streettak" target="_blank" className="primary-button w-button newCss"><img src={GooglePlay} /></a>
            <a href="https://apps.apple.com/in/app/streettak/id1586316028" target="_blank" className="primary-button w-button newCss"><img src={AppleApp} /></a>
              <div className="link-arrow" onClick={() => history.push('/about')}>
                <a href="javascript:void(0)" className="link-button w-button learnMore">Learn More</a>
                <img src={ArrowIcon} onClick={() => history.push('/about')} loading="lazy" alt="StreetTak learn more arrow" className="arrow" />
              </div>
            </div>
          </div>
          <img src={Screenshot_Top} loading="lazy" width="550px" height="650px" data-w-id="39c0fa11-4836-6ea0-f01d-3635b19a623a" alt="StreetTakhomescreen" />
        </div>
      </div>
      <div className="section customers wf-section">
        <div className="block-content customers">
          <div data-w-id="3fec52ef-bad1-4418-9a47-9d09baf7688c" className="customers-grid">
            {/* <h6 id="w-node-_06fc99b4-a5ca-1662-e299-cf2319ffc784-d01bd6d1" className="heading">Remember how it felt to express your freedom <br />and speak freely on the street?</h6> */}
            <div className="text-block-2">StreetTak provides a free, safe platform where users can participate in a public conversation without fear of being deplatformed, censored or bullied. Go ahead and speak your truth. Chat about politics, sports, current events and entertainment. Have your say.</div>
          </div>
        </div>
      </div>
      <div ref={myRef} className="section wf-section">
        <div className="block-content top-bot-margin">
          <h2 className="section-title">How StreetTak <span className="text-span">Works</span></h2>
          <div data-w-id="b00dccc6-95cd-29ed-ff75-8aeb39b7efa8" className="how-it-works-grid">
            <div data-w-id="17b8a1bc-196a-5fe8-7cd1-e606cf5d73cd" className="card-block">
              <h4 className="card-title">Create Your <strong>Profile</strong></h4>
              <div className="image-bg">
                <img src={streettakUserprofile} loading="lazy" height="148" alt="StreetTak Users" className="card-image" />
                <div className="circle-bg"></div>
              </div>
              <p className="card-paragraph">Create your own personal profile with customized header, bio and location.</p>
            </div>
            <div className="vertical-divider"></div>
            <div className="card-block">
              <h4 className="card-title">Post &amp; <strong>Connect</strong></h4>
              <div className="image-bg">
                <img src={streettakConnect} loading="lazy" height="148" alt="StreetTak Users Connect" className="card-image" />
                <div className="circle-bg"></div>
              </div>
              <p className="card-paragraph">Connect with other users. Follow users and discuss the latest in politics, sports, news and entertainment - FREELY!</p>
            </div>
            <div className="vertical-divider"></div>
            <div className="card-block">
              <h4 className="card-title">Stay <strong>Informed</strong></h4>
              <div className="image-bg">
                <img src={streeettakNews} loading="lazy" height="148" alt="StreetTak News" className="card-image" />
                <div className="circle-bg"></div>
              </div>
              <p className="card-paragraph">Immediately discuss, debate &amp; learn. News around the globe is automatically streamed in every 10 minutes!</p>
            </div>
            <div className="vertical-divider"></div>
            <div className="card-block">
              <h4 className="card-title"><strong>Shop for Deals</strong></h4>
              <div className="image-bg">
                <img src={streettakMarketplace} loading="lazy" height="148" alt="StreetTak GroupBuy Shopping " className="card-image" />
                <div className="circle-bg"></div>
              </div>
              <p className="card-paragraph">Added perk! Get access to purchase products at a discount in the GroupBuy Marketplace.<br /></p>
            </div>
          </div>
        </div>
        <div className="right-gradient-circle"></div>
      </div>
      <div ref={myFeatureRef} className="section wf-section">
        <div className="block-content features" >
          <h2 className="section-title">StreetTak <span className="text-span">Features</span></h2>
          <div data-w-id="612ae6da-cdfa-7bc7-12b4-f3fce5f05a47" className="features-grid">
            <div id="w-node-_612ae6da-cdfa-7bc7-12b4-f3fce5f05a48-d01bd6d1" className="feature-block">
              <div className="icon-bg-circle">
                <img src={streettakFeatures} loading="lazy" alt="StreetTak Chat Feature" className="feature-icon" />
              </div>
              <div className="feature-name-text">
                <h4 className="feature-title-small">Chat</h4>
                <p className="feature-text-small">Connect easily via text with users. You can also create a group. Voice and video chat.</p>
              </div>
            </div>
            <div id="w-node-f435ff70-0a95-fa3f-925d-fe37c8b29fd8-d01bd6d1" className="feature-block">
              <div className="icon-bg-circle">
                <img src={streettakFeaturenews} loading="lazy" alt="StreetTak News Streaming" className="feature-icon" />
              </div>
              <div className="feature-name-text">
                <h4 className="feature-title-small">STREET WALLET</h4>
                <p className="feature-text-small">Buy products. Send Money around the world and Convert Currency</p>
                {/* <p className="feature-text-small">News around the Globe is automatically streamed in every 10 minutes.</p> */}
              </div>
            </div>
            <div id="w-node-fa2f1f2f-fc05-7fb4-a100-4a095bead6ef-d01bd6d1" className="feature-block">
              <div className="icon-bg-circle">
                <img src={streettakShare} loading="lazy" alt="StreetTak Share Information" className="feature-icon" />
              </div>
              <div className="feature-name-text">
                <h4 className="feature-title-small">Easily Share Information</h4>
                <p className="feature-text-small">Post Ads, games, company info and new product details to the Vendor Tab.</p>
              </div>
            </div>
            <div id="w-node-cdfff0c0-81ae-46b0-a99f-bad884953b5d-d01bd6d1" className="feature-block">
              <div className="icon-bg-circle">
                <img src={streettakEngage} loading="lazy" alt="Streettak Engage" className="feature-icon" />
              </div>
              <div className="feature-name-text">
                <h4 className="feature-title-small">Engage Freely</h4>
                <p className="feature-text-small">Connect on any desired topic, freely and safely, without censorship.</p>
              </div>
            </div>
            <div id="w-node-_778e9710-2ff0-3e6f-8b58-ceb9b2d3debc-d01bd6d1" className="feature-block">
              <div className="icon-bg-circle">
                <img src={streettakCommunity} loading="lazy" alt="StreetTak Community" className="feature-icon" />
              </div>
              <div className="feature-name-text">
                <h4 className="feature-title-small">Community</h4>
                <p className="feature-text-small">Discuss, debate, learn, and follow others. StreetTak provides a whole new way to connect on different topics in a more interesting way.</p>
              </div>
            </div>
            <div id="w-node-_669f7f51-3ac3-6ddc-2e69-1b5af062bba4-d01bd6d1" className="feature-block">
              <div className="icon-bg-circle">
                <img src={streettakBuy} loading="lazy" alt="StreetTak GroupBuy Marketplace" className="feature-icon" />
              </div>
              <div className="feature-name-text">
                <h4 className="feature-title-small">GroupBuy Marketplace</h4>
                <p className="feature-text-small">Access a one of a kind e-commerce platform where you and your followers can get discounts together!</p>
              </div>
              

              
            </div>
            <div id="w-node-cdfff0c0-81ae-46b0-a99f-bad884953b5d-d01bd6d1" className="feature-block">
              <div className="icon-bg-circle">
                <img src={streettakEngage} loading="lazy" alt="Streettak Engage" className="feature-icon" />
              </div>
              <div className="feature-name-text">
                <h4 className="feature-title-small">Phone</h4>
                <p className="feature-text-small">Make calls and Group calls.</p>
              </div>
            </div>
            <div id="w-node-_778e9710-2ff0-3e6f-8b58-ceb9b2d3debc-d01bd6d1" className="feature-block">
              <div className="icon-bg-circle">
                <img src={streettakCommunity} loading="lazy" alt="StreetTak Community" className="feature-icon" />
              </div>
              <div className="feature-name-text">
                <h4 className="feature-title-small">SILENT FOLLOWERS</h4>
                <p className="feature-text-small">Chat and Call a follower with secured record and No one see your records</p>
              </div>
            </div>
            <div id="w-node-_669f7f51-3ac3-6ddc-2e69-1b5af062bba4-d01bd6d1" className="feature-block">
              <div className="icon-bg-circle">
                <img src={streettakBuy} loading="lazy" alt="StreetTak GroupBuy Marketplace" className="feature-icon" />
              </div>
              <div className="feature-name-text">
                <h4 className="feature-title-small"> STREET MARKET</h4>
                <p className="feature-text-small">individuals post and sell their used or new product</p>
              </div>
              
            </div>
          </div>
        </div>
        <div className="features-bg"></div>
      </div>
      <div className="divider"></div>
      <div className="section wf-section">
        <div className="block-content single-feature top-margin">
          <div className="single-feature-block">
            <h2 className="section-title">Shop Deals in GroupBuyMarketplace<br /> and StreetMarket .<span className="text-span"></span></h2>
            <div className="section-text">Connect with like-minded users and build your community. It comes with perks!</div>
            <a className="primary-button w-button learnAt" onClick={() => history.push('/about')}>Learn more about StreetTak</a>
          </div>
          <img src={ScreenShot_down} loading="lazy" data-w-id="98c73345-2d30-8689-20f9-dbffa7c9ec44" alt="StreetTak User Profile" />
          <img src={ScreenShot_down_right} loading="lazy" data-w-id="98c73345-2d30-8689-20f9-dbffa7c9ec44" alt="StreetTak User Profile" />
        </div>
      </div>
      <div className="section wf-section">
        <div className="block-content single-feature bot-margin">
          <img src={StreetTakProfile2} loading="lazy" data-w-id="e4389d9b-9532-0198-fec8-48e74bf48c26" srcset={`${StreetTakProfile2} 500w, ${StreetTakProfile2} 552w`} sizes="(max-width: 479px) 95vw, (max-width: 587px) 94vw, 552px" alt="StreetTak App" />
          <div className="single-feature-block">
            <h2 className="section-title">What&#x27;s on your mind?<span className="text-span"></span></h2>
            <div className="section-text">Let&#x27;s talk about it. Join StreetTak today!</div>
            <a onClick={handleStreetalk} className="primary-button w-button">Get StreetTak</a>
          </div>
        </div>
       
      </div>
      <h1 style={{color:"green",marginBottom:"20px", textAlign:"center"}}>GroupBuy Marketplace In StreetTak, Join Bargains</h1>
      <div className="divider"></div>
      <div className="footer wf-section">
        <div className="footer-block">
          <div data-w-id="e8d13286-4f22-98bd-8338-9e174ec1fd5c" className="w-layout-grid footer-grid">
            <div id="w-node-e8d13286-4f22-98bd-8338-9e174ec1fd5d-4ec1fd5a" className="left-block-footer">
              <h3 className="footer-title">StreetTak</h3>
            </div>
            <div id="w-node-e8d13286-4f22-98bd-8338-9e174ec1fd61-4ec1fd5a" className="block-footer">
              <h5 className="footer-title">Company</h5>
              <div className="footer-nav-link-line">
                <div className="footer-hover-line newline"></div>
              </div>
              <div className="footer-nav-link-line">
                <a href="javascript:void(0)" onClick={executeScroll} className="footer-link">How It Works</a>
                <div className="footer-hover-line newline"></div>
              </div>
              <div className="footer-nav-link-line">
                <a href="javascript:void(0)" onClick={executeFeatureScroll} className="footer-link">Features</a>
                <div className="footer-hover-line newline"></div>
              </div>
              <div className="footer-nav-link-line">
                <a href="javascript:void(0)" onClick={() => history.push('/about')} className="footer-link">About</a>
                <div className="footer-hover-line newline"></div>
              </div>
              <div className="footer-nav-link-line">
                <a href="javascript:void(0)" onClick={() => history.push('/terms-and-condition')} className="footer-link">Terms & Conditions</a>
                <div className="footer-hover-line newline"></div>
              </div>
              {!loginStatus ?
                <div className="footer-nav-link-line">
                  <a href="javascript:void(0)" onClick={() => history.push('/register')} className="footer-link">Become a Member</a>
                  <div className="footer-hover-line newline"></div>
                </div> : ''}
            </div>
            <div id="w-node-e8d13286-4f22-98bd-8338-9e174ec1fd76-4ec1fd5a" className="block-footer">
              <h5 className="footer-title">Download App</h5>
              <div className="footer-nav-link-line">
                <a href="https://play.google.com/store/apps/details?id=com.streettak" className="footer-link">Android</a>
                <div className="footer-hover-line newline"></div>
              </div>
              <div className="footer-nav-link-line">
                <a href="https://apps.apple.com/in/app/streettak/id1586316028" className="footer-link">iPhone</a>
                <div className="footer-hover-line newline"></div>
              </div>
              <div className="footer-nav-link-line">
                <div className="footer-hover-line newline"></div>
              </div>
            </div>

            <div id="w-node-_0e10e707-3e4a-7fa2-a49b-c9060eefa64a-4ec1fd5a" className="block-footer">
              <h5 className="footer-title">Contact</h5>
              <div className="footer-nav-link-line noline">
                <a href="javascript:void(0)" className="footer-link">StreetTak &amp; GroupBuy Companies<br />Suite 1002<br />1115 9 St SW<br />Calgary, AB<br />T2R 1C4<br />Canada</a>
              </div>
              <div className="footer-nav-link-line noline">
                <a href="javascript:void(0)" className="footer-link">(403) 852-2989<br />info@streettak.com</a>
              </div>
            </div>
          </div>
          <div className="footer-divider"></div>
          <div data-w-id="e8d13286-4f22-98bd-8338-9e174ec1fd86" className="bottom-footer">
            <div className="text-block">© 2021 StreetTak. All rights reserved</div>
          </div>
         
        </div>
      </div>
   
    </div>
  )
}

export default StreettakLandingPage