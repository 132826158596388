import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import config from '../config/config';
import '../App.css';
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import CountrySelect from 'react-bootstrap-country-select';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import Biglogo from "../assets/media/gbLogo.png";
import Header from './header';
import Footer from './footer';
import { registerUser } from '../redux/actions/signUpAction';
import { checkPassword } from '../utils'
import countryList from 'react-select-country-list';
import Select from 'react-select'
import streetTalk from "../assets/media/streettalk.svg"
import moment from 'moment'

const initialState = {
	formData: {
		firstName: '',
		lastName: '',
		emailId: '',
		country: '',
		password: '',
		comparePassword: '',
		country_Code: '',
		dateOfBirth: '',
		ageFlag:false
	},
	selectedCountry: '',
	firstNameEmpty: '',
	lastNameEmpty: '',
	emailIdEmpty: '',
	countryEmpty: '',
	passwordEmpty: '',
	comparePasswordEmpty: '',
	dateOfBirthError: '',
	ageFlagError:'',
	errorMsg: ''
}
const Register = (props) => {
	const history = useHistory();
	const [iState, updateState] = useState(initialState);
	let [showpassword, setShowPassword] = useState(true);
	let [showConfirmPassword, setShowConfirmPassword] = useState(true);
	const [submitDisable, updateSubmitDisable] = useState(false);
	const dispatch = useDispatch()
	const options = useMemo(() => countryList().getData(), [])
	const {
		firstName,
		lastName,
		emailId,
		country,
		password,
		comparePassword,
		country_Code,
		dateOfBirth,
		ageFlag
	} = iState.formData

	const {
		selectedCountry,
		firstNameEmpty,
		lastNameEmpty,
		emailIdEmpty,
		countryEmpty,
		passwordEmpty,
		comparePasswordEmpty,
		dateOfBirthError,
		ageFlagError,
		errorMsg
	} = iState

	const handleInputChange = (e) => {
		const { name, value } = e.target
		updateState({
			...iState,
			formData: {
				...iState.formData, [name]: value
			}
		})
	}

	const handleCountry = (data) => {
		updateState({
			...iState,
			formData: {
				...iState.formData, 'country': data && data.label, 'country_Code': data && data.value
			},
			selectedCountry: data
		})
	}
	const handleValidation = () => {

		let firstNameEmpty = ''
		let lastNameEmpty = ''
		let emailIdEmpty = ''
		let countryEmpty = ''
		let passwordEmpty = ''
		let comparePasswordEmpty = ''
		let dateOfBirthError = ''
		let ageFlagError=""

		let formIsValid = true;

		if (!firstName.trim()) {
			firstNameEmpty = "*First Name can't be empty";
			formIsValid = false;
		}
		if (!lastName.trim()) {
			lastNameEmpty = "*Last Name can't be empty";
			formIsValid = false;
		}
		if (!emailId.trim()) {
			emailIdEmpty = "*Email ID can't be empty";
			formIsValid = false;
		}
		if (country === null || !country.trim()) {
			countryEmpty = "*Country can't be empty";
			formIsValid = false;
		}
		if (!password.trim()) {
			passwordEmpty = "*Password can't be empty";
			formIsValid = false;
		}
		if (!comparePassword.trim()) {
			comparePasswordEmpty = "*Confirm password can't be empty";
			formIsValid = false;
		}
		if (password && comparePassword && (password !== comparePassword)) {
			comparePasswordEmpty = "*Confirm password must be same as password";
			formIsValid = false;
		}
		if (password) {
			let valid = checkPassword(password)
			if (!valid) {
				passwordEmpty = "*Your password must contain at least one uppercase, lowercase, number, special character and length must be > 8."
				formIsValid = false
			}
		}
		if (emailId) {
			if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(emailId)) {
				emailIdEmpty = "*Invalid Email id";
				formIsValid = false;
			}
		}
		if (!dateOfBirth.trim()) {
			dateOfBirthError = "*Date of birth  can't be empty";
			formIsValid = false;
		}
		if (dateOfBirth) {
			let thirteenYearsAgo = moment().subtract(13, "years");
			let birthday = moment(dateOfBirth);

			if (!birthday.isValid()) {
				dateOfBirthError = "*Invalid Date";
				formIsValid = false;
			}
			else if (thirteenYearsAgo.isBefore(birthday)) {
				dateOfBirthError = "*Your age must be greater than 13";
				formIsValid = false;
			}
		}
		if (!ageFlag) {
			ageFlagError = "Please Check";
			formIsValid = false;
		}
		updateState({
			...iState, firstNameEmpty, lastNameEmpty,
			emailIdEmpty,
			countryEmpty,
			passwordEmpty,
			comparePasswordEmpty,
			dateOfBirthError,
			ageFlagError
		})
		return formIsValid;
	}

	const handleChecked = (e) => {
		if (e.target.checked) {
			updateState({
				...iState, formData: {
					...iState.formData, ageFlag: true
				}
			})
		} else {
			updateState({
				...iState, formData: {
					...iState.formData, ageFlag: false
				}
			})
		}
	}

	let handleSubmit = (event) => {
		event.preventDefault();
		let formIsValid = handleValidation();
		if (formIsValid) {
			updateState({
				...iState,
				// firstNameEmpty:'',
				// lastNameEmpty:'',
				// emailIdEmpty:'',
				// countryEmpty:'',
				// passwordEmpty:'',
				// comparePasswordEmpty:'',
				errorMsg: ''
			})
			updateSubmitDisable(true)
			dispatch(registerUser(iState.formData)).then(res => {
				if (res.response_code == 200) {
					toast.success(res.message, {
						position: toast.POSITION.TOP_RIGHT
					});

					updateSubmitDisable(false)
					history.push('/otp')
				} else {
					updateSubmitDisable(false)
					updateState({
						...iState, errorMsg: res.message,
						firstNameEmpty: '',
						lastNameEmpty: '',
						emailIdEmpty: '',
						countryEmpty: '',
						passwordEmpty: '',
						comparePasswordEmpty: '',
						dateOfBirthError: '',
						ageFlagError:''
					})
				}
			}).catch(err => {

				updateSubmitDisable(false)
				console.log(err, 'err')
				const message = err && err.response && err.response.data ? err.response.data.message : "Something went wrong."
				toast.error(message, {
					position: toast.POSITION.TOP_RIGHT
				});
			})
		}
	}

	return (
		<div>
			<Header />
			<section>
				<div className="LoginArea">
					<Container>
						<Row>
							<Col sm={6}>
								<div className="LoginLeft">
									<img src={streetTalk} />
								</div>
							</Col>
							<Col sm={6}>
								<div className="LoginRight">
									<h3>Create Account</h3>
									<h2>Please enter your details</h2>
									<span style={{ color: "red" }}>{errorMsg}</span>
									<Form onSubmit={handleSubmit} autoComplete='off'>
										<Form.Row>
											<Col>
												<Form.Group>
													<Form.Control
														placeholder="First name"
														name='firstName'
														value={firstName}
														maxLength="20"
														onChange={handleInputChange}
													/>
													<span style={{ color: "red" }}>{firstNameEmpty}</span>
												</Form.Group>
											</Col>
											<Col>
												<Form.Group>
													<Form.Control
														placeholder="Last name"
														name='lastName'
														value={lastName}
														maxLength="20"
														onChange={handleInputChange}
													/>
													<span style={{ color: "red" }}>{lastNameEmpty}</span>
												</Form.Group>
											</Col>
										</Form.Row>
										<Form.Row>
											<Col>
												<Form.Group>
													<Form.Control
														placeholder="Email Address"
														name='emailId'
														value={emailId}
														onChange={handleInputChange}
													/>
													<span style={{ color: "red" }}>{emailIdEmpty}</span>
												</Form.Group>
											</Col>
										</Form.Row>
										<Form.Row> 
											    <Col>
											    	<Form.Group> 												
                              <Form.Label>Date of Birth</Form.Label>
												      	<Form.Control 
																  type="date"
																  placeholder="Date of Birth"
																	name='dateOfBirth'
																	value={dateOfBirth}
																	onChange={handleInputChange}
																/>
																<span style={{ color: "red" }}>{dateOfBirthError}</span>
												    </Form.Group>
											    </Col>
											</Form.Row>
									
										<Form.Row>
											<Col>
												<Form.Group>
													{/* <CountrySelect
															value={selectedCountry}
															onChange={handleCountry}
															onTextChange={handleCountry}
														/> */}

													<Select options={options} value={selectedCountry} onChange={handleCountry} placeholder='Type or select country' className='customCountry' isClearable />
													<span style={{ color: "red" }}>{countryEmpty}</span>
												</Form.Group>
											</Col>
										</Form.Row>
										<Form.Row>
											<Col>
												<Form.Group>
													<InputGroup>
														<Form.Control
															placeholder="Password"
															name='password'
															value={password}
															type={showpassword ? "password" : "text"}
															onChange={handleInputChange}
														/>
														<InputGroup.Append>
															<InputGroup.Text id="basic-addon2">
																<a onClick={(e) => { setShowPassword(!showpassword) }} style={{ color: "#004AAD" }} className="IconEye">
																	<span>{showpassword ? <i className="fa fa-eye-slash" aria-hidden="true"></i> :
																		<i className="fa fa-eye" aria-hidden="true"></i>
																	}</span></a>
															</InputGroup.Text>
														</InputGroup.Append>
													</InputGroup>
													<span style={{ color: "red" }}>{passwordEmpty}</span>
												</Form.Group>
											</Col>
										</Form.Row>
										<Form.Row>
											<Col>
												<Form.Group>
													<InputGroup>
														<Form.Control
															placeholder="Confirm Password"
															name='comparePassword'
															value={comparePassword}
															type={showConfirmPassword ? "password" : "text"}
															onChange={handleInputChange}
														/>
														<InputGroup.Append>
															<InputGroup.Text id="basic-addon2">
																<a onClick={(e) => { setShowConfirmPassword(!showConfirmPassword) }} style={{ color: "#004AAD" }} className="IconEye">
																	<span>{showConfirmPassword ? <i className="fa fa-eye-slash" aria-hidden="true"></i> :
																		<i className="fa fa-eye" aria-hidden="true"></i>
																	}</span></a>
															</InputGroup.Text>
														</InputGroup.Append>
													</InputGroup>
													<span style={{ color: "red" }}>{comparePasswordEmpty}</span>
												</Form.Group>
											</Col>
										</Form.Row>
										<Form.Row>
											<Col>
												<Form.Group className="mb-3 LabelCheckbox" c>
													<Form.Check name="dateOfBirth" onChange={(e) => handleChecked(e)} value={ageFlag} checked={ageFlag} type="checkbox" label="I am above 13 is redundant" />
													<span style={{ color: "red" }}>{ageFlagError}</span>
												</Form.Group>
											</Col>
										</Form.Row>
										<Button variant="primary" type="submit" disabled={submitDisable}>Create Account</Button>
										<h5>Already have an Account? <Link to="/login">Login</Link></h5>
									</Form>


								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</section>


			<Footer />
		</div>
	)
}

export default Register